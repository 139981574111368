import React from 'react'
import BlogArea from './components/blogArea'

function Blog() {
  return (
    <>
      <div className="page-title-area">
        <div className="container">
          <div className="content text-center">
            <h1>Latest Blog</h1>
            <ul className="list-unstyled">
              <li className="d-inline"><a href="index.html">Home</a></li>
              <li className="d-inline">/</li>
              <li className="d-inline active">Blog</li>
            </ul>
          </div>
        </div>
      </div>
      <BlogArea />
    </>
  )
}

export default Blog