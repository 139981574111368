import { combineReducers } from "redux";
import authLoginReducer from "../../features/authLoginSlice";
import authRegisterReducer from "../../features/authRegisterSlice";
import companyReducer from '../../features/companySlice';
import ticketReducer from '../../features/ticketsSlice';
import employeeReducer from '../../features/employeeSlice';
import projectReducer from '../../features/projectSlice';
import clientReducer from '../../features/clientSlice';
import boardReducer from '../../features/boardSlice';
import attendanceReducer from '../../features/attendanceSlice';
import estimateReducer from '../../features/estimateSlice';
import departmentReducer from "../../features/departmentSlice";
import masterSlice from "../../features/masterSlice";
import taxSlice from "../../features/taxSlice";
import invoiceSlice from "../../features/invoiceSlice";
import expenseSlice from "../../features/expenseSlice";

const rootReducer = combineReducers({
  userLogin: authLoginReducer,
  userRegister: authRegisterReducer,
  company: companyReducer,
  ticket: ticketReducer,
  employee: employeeReducer,
  project: projectReducer,
  client: clientReducer,
  taskBoard: boardReducer,
  attendance: attendanceReducer,
  estimates: estimateReducer,
  department: departmentReducer,
  master: masterSlice,
  tax: taxSlice,
  invoice: invoiceSlice,
  expense: expenseSlice
});

export default rootReducer;
