import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import { toast } from "react-hot-toast";

const boardSlice = createSlice({
    name: 'taskBoard',
    initialState: {
        taskBoardList: null,
        userBoards: [],
        isLoading: true,
        boardDetails: null,
        taskBoardUsers: [],
        taskDetail: null,
        taskUsers: [],
    },
    reducers: {
        getUserBoardsSuccess: (state, action) => {
            state.userBoards = action.payload;
            state.isLoading = false;
        },
        getUserBoardsFailure: (state) => {
            state.userBoards = [];
            state.isLoading = false;
        },
        addBoardSuccess: (state) => {
            state.isLoading = false;
        },
        addBoardFailure: (state) => {
            state.isLoading = false;
        },
        deleteBoardSuccess: (state, action) => {
            const boardID = action.payload.id;
            state.userBoards = state.userBoards.filter(board => board.identifier !== boardID);
            state.isLoading = false;
        },
        deleteBoardFailure: (state) => {
            state.isLoading = false;
        },
        getBoardDetailsSuccess: (state, action) => {
            state.boardDetails = action.payload.data;
        },
        getBoardDetailsFailure: (state) => {
            state.boardDetails = null;
        },
        getAllBoardEmployeesSuccess: (state, action) => {
            state.taskBoardUsers = action.payload.data;
            state.isLoading = false;
        },
        getAllBoardEmployeesFailure: (state) => {
            state.taskBoardUsers = [];
            state.isLoading = false;
        },
        updateBoardUserSelection: (state, action) => {
            const { userIdentifier, isAssigned } = action.payload;
            state.taskBoardUsers = state.taskBoardUsers.map(user => {
                if (user.userIdentifier === userIdentifier) {
                    return { ...user, isAssigned: !isAssigned };
                }
                return user;
            });
        },
        boardAssignmentSuccess: (state) => {
            state.isLoading = false;
        },
        boardAssignmentFailure: (state) => {
            state.isLoading = false;
        },
        addTaskboardListSuccess: (state) => {
            state.isLoading = false;
        },
        addTaskboardListFailure: (state) => {
            state.isLoading = false;
        },
        getTaskboardListSuccess: (state, action) => {
            state.taskBoardList = action.payload.data;
            state.isLoading = false;
        },
        getTaskboardListFailure: (state) => {
            state.taskBoardList = null;
            state.isLoading = false;
        },
        updateTaskboardListSuccess: (state) => {
            state.isLoading = false;
        },
        updateTaskboardListFailure: (state) => {
            state.isLoading = false;
        },
        deleteTaskboardListSuccess: (state) => {
            state.isLoading = false;
        },
        deleteTaskboardListFailure: (state) => {
            state.isLoading = false;
        },
        addTaskSuccess: (state) => {
            state.isLoading = false;
        },
        addTaskFailure: (state) => {
            state.isLoading = false;
        },
        reorderTasksinSameListSuccess: (state, action) => {
            const { listIdentifier, updatedTasks } = action.payload;
            const listIndex = state.boardDetails.lists.findIndex(list => list.listIdentifier === listIdentifier);
            if (listIndex !== -1) {
                state.boardDetails.lists[listIndex].tasks = updatedTasks;
            }
        },
        reorderTasksinDistinctListSuccess: (state, action) => {
            const { sourceTasks, destinationTasks, sourceListIdentifier, destinationListIdentifier } = action.payload;
            const sourceListIndex = state.boardDetails.lists.findIndex(list => list.listIdentifier === sourceListIdentifier);
            if (sourceListIndex !== -1) {
                state.boardDetails.lists[sourceListIndex].tasks = sourceTasks;
            }
            const destinationListIndex = state.boardDetails.lists.findIndex(list => list.listIdentifier === destinationListIdentifier);
            if (destinationListIndex !== -1) {
                state.boardDetails.lists[destinationListIndex].tasks = destinationTasks;
            }
        },
        deleteTaskSuccess: (state, action) => {
            const { listIdentifier, taskIdentifier } = action.payload;
            const listIndex = state.boardDetails.lists.findIndex(list => list.listIdentifier === listIdentifier);
            if (listIndex !== -1) {
                const taskIndex = state.boardDetails.lists[listIndex].tasks.findIndex(task => task.taskIdentifier === taskIdentifier);
                if (taskIndex !== -1) {
                    state.boardDetails.lists[listIndex].tasks.splice(taskIndex, 1);
                }
            }
            state.isLoading = false;
        },
        deleteTaskFailure: (state) => {
            state.isLoading = false;
        },
        getTaskDetailsSuccess: (state, action) => {
            state.taskDetail = action.payload.data;
            console.log(state.taskDetail);
            state.isLoading = false;
        },
        getTaskDetailsFailure: (state, action) => {
            state.isLoading = false;
        },
        updateDueDateSuccess: (state, action) => {
            state.taskDetail.dueDate = action.payload;
        },
        getAllTaskMembersSuccess: (state, action) => {
            state.taskUsers = action.payload.data;
            state.isLoading = false;
        },
        getAllTaskMembersFailure: (state, action) => {
            state.taskUsers = [];
            state.isLoading = false;
        },
        updateTaskUserSelection: (state, action) => {
            const { userIdentifier, isAssigned } = action.payload;
            state.taskUsers = state.taskUsers.map(user => {
                if (user.userIdentifier === userIdentifier) {
                    return { ...user, isAssigned: !isAssigned };
                }
                return user;
            });
        },
        taskAssignmentSuccess: (state, action) => {
            state.isLoading = false;
        },
        taskAssignmentFailure: (state, action) => {
            state.isLoading = false;
        },
        // getBoardListSuccess: (state, action) => {
        //     state.list = action.payload.data.data;
        // },

        // getBoardListsSuccess: (state, action) => {
        //     state.allBoardLists = action.payload.data.data;
        //     state.isLoading = false;
        // },
        // getBoardListsFailure: (state, action) => {
        //     state.isLoading = false;
        // },

        // addBoardListSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // addBoardListFailure: (state, action) => {
        //     state.isLoading = false;
        // },

        // getTaskDetailsSuccess: (state, action) => {
        //     state.isLoading = false;
        //     state.taskDetail = action.payload.data.data;
        // },
        // getTaskDetailsFailure: (state, action) => {
        //     state.isLoading = false;
        //     state.taskDetail = null
        // },
        // updateBoardSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // updateBoardFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // getallCommentsSuccess: (state, action) => {
        //     state.comments = action.payload.data;
        //     state.isLoading = false;
        // },
        // getallCommentsFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // addTaskCommentSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // addTaskCommentFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // updateTaskCommentSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // updateTaskCommentFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // deleteTaskCommentSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // deleteTaskCommentFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // updateDueDateSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // updateDueDateFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // getAllEmployeesForBoardSuccess: (state, action) => {
        //     state.isLoading = false;
        //     state.boardEmployees = action.payload.data;
        // },
        // getAllEmployeesForBoardFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // boardAssignmentSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // boardAssignmentFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // removeAssigneeFromBoardSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // removeAssigneeFromBoardFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // updateSelectedArraySuccess: (state, action) => {
        //     state.boardEmployees = action.payload;
        // },
        // updateTaskSelectedArraySuccess: (state, action) => {
        //     state.boardMembers = action.payload;
        // },

        // getAllBoardMembersSuccess: (state, action) => {
        //     state.isLoading = false;
        //     state.boardMembers = action.payload.data;
        // },
        // getAllBoardMembersFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // taskAssignmentSuccess: (state, action) => {
        //     state.isLoading = false;
        // },
        // taskAssignmentFailure: (state, action) => {
        //     state.isLoading = false;
        // },
        // getTaskDetailsLoading: (state, action) => {
        //     state.taskDetail = null;
        // },
        setLoading: (state) => {
            state.isLoading = true;
        },
    }
});

export const getUserBoards = (userIdentifier) => async (dispatch) => {
    try {
        dispatch(setLoading);
        const response = await httpHandler.get(`/api/taskBoard/userBoards?userIdentifier=${userIdentifier}`);
        console.log(response);
        if (response.data.success) {
            dispatch(getUserBoardsSuccess(response.data.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(getUserBoardsFailure());
        }
    }
    catch (error) {
        toast.error(error.message);
        dispatch(getUserBoardsFailure());
    }
};

export const addBoard = (data, identifier, reset) => async (dispatch) => {
    try {
        dispatch(setLoading);
        const response = await httpHandler.post(`/api/taskBoard/addBoard`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(addBoardSuccess(response.data));
            dispatch(getUserBoards(identifier));
            reset();
        }
        else {
            toast.error(response.data.message);
            dispatch(addBoardFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(addBoardFailure());
    }
};

export const deleteBoard = (id, userIdentifier) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.post(`/api/taskBoard/deleteBoard/${id}`);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(deleteBoardSuccess({ id }));
            dispatch(getUserBoards(userIdentifier));
        }
        else {
            toast.error(response.data.message);
            dispatch(deleteBoardFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(deleteBoardFailure());
    }
};

export const getBoardDetails = (id) => async (dispatch) => {
    try {
        const response = await httpHandler.get(`/api/taskBoard/getBoardDetails/${id}`);
        if (response.data.success) {
            dispatch(getBoardDetailsSuccess(response.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(getBoardDetailsFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(getBoardDetailsFailure());
    }
};

export const getAllBoardEmployees = (search, boardIdentifier) => async (dispatch) => {
    try {
        dispatch(setLoading);
        const response = await httpHandler.get(`/api/taskBoard/unassignedEmployees?search=${search}&boardIdentifier=${boardIdentifier}`);
        if (response.data.success) {
            dispatch(getAllBoardEmployeesSuccess(response.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(getAllBoardEmployeesFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(getAllBoardEmployeesFailure());
    }
};

export const boardAssignment = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/taskBoard/boardAssignment`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(boardAssignmentSuccess());
        }
        else {
            toast.error(response.data.message);
            dispatch(boardAssignmentFailure())
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(boardAssignmentFailure())
    }
};

export const addTaskboardList = (data, boardId) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.post(`/api/taskBoard/addList`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(addTaskboardListSuccess(response.data));
            dispatch(getBoardDetails(boardId));
        }
        else {
            toast.error(response.data.message);
            dispatch(addTaskboardListFailure());
        }
    } catch (error) {
        toast.error(error.response.data.Message);
        dispatch(addTaskboardListFailure());
    }
};

export const getTaskboardList = (id) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.get(`/api/taskBoard/getList/${id}`);
        if (response.data.success) {
            dispatch(getTaskboardListSuccess(response.data));
        }
        else {
            toast.error(response.data.message)
            dispatch(getTaskboardListFailure());
        }
    } catch (error) {
        toast.error(error.message)
        dispatch(getTaskboardListFailure());
    }
};

export const updateTaskboardList = (data, boardId) => async (dispatch) => {
    try {
        const response = await httpHandler.post(`/api/taskBoard/updateList`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(updateTaskboardListSuccess());
            dispatch(getBoardDetails(boardId));
        }
        else {
            toast.error(response.data.message);
            dispatch(updateTaskboardListFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(updateTaskboardListFailure());
    }
};

export const deleteTaskboardList = (id, boardID) => async (dispatch) => {
    try {
        const response = await httpHandler.post(`/api/taskBoard/deleteList/${id}`);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(deleteTaskboardListSuccess());
            dispatch(getBoardDetails(boardID));
        }
        else {
            toast.error(response.data.message);
            dispatch(deleteTaskboardListFailure());
        }
    } catch (error) {
        toast.error(error.message);
    }
};

export const addTask = (data, toggleAddTaskModal, boardId) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.post(`/api/taskBoard/addTask`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(addTaskSuccess(response.data));
            dispatch(getBoardDetails(boardId));
            toggleAddTaskModal();
        }
        else {
            toast.error(response.data.message);
            dispatch(addTaskFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(addTaskFailure());
    }
}

export const reorderTasksinSameList = (data, listIdentifier) => async (dispatch) => {
    try {
        const response = await httpHandler.post(`/api/taskBoard/reorderTasksInSameList`, data);
        if (response.data.success) {
            dispatch(reorderTasksinSameListSuccess({ listIdentifier, updatedTasks: data }));
        }
        else {
            toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export const reorderTasksinDistinctList = (data) => async (dispatch) => {
    try {
        const response = await httpHandler.post(`/api/taskBoard/reorderTasksInDistinctList`, data);
        if (response.data.success) {
            dispatch(reorderTasksinDistinctListSuccess(data));
        }
        else {
            toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export const deleteTask = (taskIdentifier, listIdentifier) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/taskBoard/deleteTask/${taskIdentifier}`);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(deleteTaskSuccess({ taskIdentifier, listIdentifier }));
        }
        else {
            toast.error(response.data.message);
            dispatch(deleteTaskFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(deleteTaskFailure());
    }
}

export const getTaskDetails = (id) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.get(`/api/taskBoard/getTaskDetails/${id}`);
        if (response.data.success) {
            dispatch(getTaskDetailsSuccess(response.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(getTaskDetailsFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(getTaskDetailsFailure());
    }
};

export const updateTaskTitle = (data, boardID) => async (dispatch) => {
    try {
        const response = await httpHandler.post(`/api/taskBoard/task.updateTitle`, data);
        if (response.data.success) {
            dispatch(getBoardDetails(boardID));
        }
        else {
            toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export const updateTaskDescription = (data) => async () => {
    try {
        const response = await httpHandler.post(`/api/taskBoard/task.updateDescription`, data);
        if (!response.data.success) {
            toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export const updateDueDate = (data, dueDate) => async (dispatch) => {
    try {
        const response = await httpHandler.post('/api/taskBoard/task.updateDueDate', data);
        if (response.data.success) {
            dispatch(updateDueDateSuccess(dueDate));
        }
        else {
            toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export const getAllBoardMembers = (boardIdentifier, search) => async (dispatch) => {
    try {
        //dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/taskBoard/task.allMembers?boardIdentifier=${boardIdentifier}&search=${search}`)
        if (response.data.success)
            dispatch(getAllTaskMembersSuccess(response.data));
        else
            dispatch(getAllTaskMembersFailure());
    } catch (error) {
        dispatch(getAllTaskMembersFailure());
    }
}

export const taskAssignment = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/taskBoard/taskAssignment`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(taskAssignmentSuccess());
        }
        else {
            toast.error(response.data.message);
            dispatch(taskAssignmentFailure())
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(taskAssignmentFailure())
    }
};

export const addTaskComment = (data, taskIdentifier) => async (dispatch) => {
    try {
        debugger
        const response = await httpHandler.post(`/api/taskBoard/addComment`, data);
        if (response.data.success) {

        }
        else {

        }
    } catch (error) {

    }
}

export const updateTaskComment = (data, taskIdentifier) => async (dispatch) => {
    try {
        const response = await httpHandler.post(`/api/taskBoard/updateComment`, data);

    } catch (error) {
    }
}

export const deleteTaskComment = (commentId, taskIdentifier) => async (dispatch) => {
    try {
        const response = await httpHandler.post(`/api/taskBoard/deleteComment/${commentId}`)

    } catch (error) {
    }
}

// export const updateBoard = (data, boardIdentifier) => async (dispatch) => {
//     try {
//         dispatch(setLoading());
//         const response = await httpHandler.post(`/api/taskBoard/updateBoard/${boardIdentifier}`, data);
//         if (response.data.success) {
//             dispatch(getBoard(boardIdentifier));
//             dispatch(updateBoardSuccess());
//             toast.success(response.data.message);
//         }
//         else {
//             dispatch(updateBoardFailure());
//             toast.success(response.data.message);
//         }
//     } catch (error) {
//         dispatch(updateBoardFailure());
//         toast.error(error.message);
//     }
// }





// export const getAllBoardLists = (boardIdentifier) => async (dispatch) => {
//     try {
//         dispatch(setLoading);
//         const response = await httpHandler.get(`/api/taskBoard/allBoardLists/${boardIdentifier}`);
//         dispatch(getBoardListsSuccess(response));
//     }
//     catch (error) {
//     }
// }



// export const getAllTasks = (listIdentifier) => async (dispatch) => {
//     try {
//         dispatch(setLoading);
//         const response = await httpHandler.get(`/api/taskBoard/allTasks/${listIdentifier}`);
//         dispatch(getTasksSuccess(response));
//     }
//     catch (error) {
//         //dispatch(getBoardListsFailure(error));
//     }
// }





// export const getallComments = (taskIdentifier, setTaskComments) => async (dispatch) => {
//     try {
//         dispatch(setLoading());
//         const response = await httpHandler.get(`/api/taskBoard/allComments/${taskIdentifier}`);
//         if (response.data.success) {
//             setTaskComments(response.data.data);
//             dispatch(getallCommentsSuccess(response.data));
//         }
//         else {
//             dispatch(getallCommentsFailure(response.data));
//         }
//     } catch (error) {
//         dispatch(getallCommentsFailure(error.message));
//     }
// }





// export const removeAssigneeFromBoard = (data) => async (dispatch) => {
//     try {
//         dispatch(setLoading(true));
//         const response = await httpHandler.post(`/api/taskBoard/removeBoardAssignee`, data);
//         if (response.data.success) {
//             //dispatch(getProject(projectId));
//             dispatch(removeAssigneeFromBoardSuccess(response.data));
//             toast.success(response.data.message);
//         }
//         else {
//             dispatch(removeAssigneeFromBoardFailure());
//             toast.error(response.data.message);
//         }
//     } catch (error) {
//         toast.error(error.response.data.error);
//         dispatch(removeAssigneeFromBoardFailure());
//     }
// }

// export const updateSelectedArray = (data) => async (dispatch) => {
//     dispatch(updateSelectedArraySuccess(data));
// }

// export const updateTaskSelectedArray = (data) => async (dispatch) => {
//     dispatch(updateTaskSelectedArraySuccess(data));
// }



// export const taskAssignment = (data, setTaskDetailModalOpen, setSelectedUsers) => async (dispatch) => {
//     try {
//         dispatch(setLoading(true));
//         const response = await httpHandler.post(`/api/taskBoard/taskAssignment`, data);
//         if (response.data.success) {
//             toast.success(response.data.message)
//             setSelectedUsers([]);
//             dispatch(getAllBoardLists(data.boardId));
//             dispatch(getTaskDetails(data.taskIdentifier, setTaskDetailModalOpen));
//             var search = '';
//             dispatch(getAllBoardMembers(data.boardId, search, setSelectedUsers))
//             dispatch(taskAssignmentSuccess());
//         }
//         else {
//             dispatch(taskAssignmentFailure())
//             toast.error(response.data.message);
//         }
//     } catch (error) {
//         dispatch(taskAssignmentFailure())
//         toast.error(error.message);
//     }
//}

export const { getUserBoardsSuccess, getUserBoardsFailure, addBoardSuccess, addBoardFailure, deleteBoardSuccess, deleteBoardFailure,
    getBoardDetailsSuccess, getBoardDetailsFailure, getAllBoardEmployeesSuccess, getAllBoardEmployeesFailure, updateBoardUserSelection,
    boardAssignmentSuccess, boardAssignmentFailure, addTaskboardListSuccess, addTaskboardListFailure, getTaskboardListSuccess,
    getTaskboardListFailure, updateTaskboardListSuccess, updateTaskboardListFailure, deleteTaskboardListSuccess, deleteTaskboardListFailure,
    addTaskSuccess, addTaskFailure, reorderTasksinSameListSuccess, reorderTasksinDistinctListSuccess, deleteTaskSuccess, deleteTaskFailure,
    getTaskDetailsSuccess, getTaskDetailsFailure, updateDueDateSuccess, getAllTaskMembersSuccess, getAllTaskMembersFailure, updateTaskUserSelection,
    taskAssignmentSuccess, taskAssignmentFailure,
    setLoading } = boardSlice.actions;
export default boardSlice.reducer;