import React, { useEffect } from "react";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Priorities } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { editTicket, getTicket } from "../../features/ticketsSlice";
import Loader from "../loader/Loader";

const schema = yup
    .object({
        title: yup.string().required("Title is required").trim(),
        priority: yup.string().required("You have to set priority").trim(),
        description: yup.string().required("Description is required").trim()
    })
    .required();

function EditTicketForm() {
    const { ticketId } = useParams();
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, ticket } = useSelector(state => state.ticket);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        dispatch(getTicket(ticketId));
    }, [dispatch, ticketId]);

    const onSubmit = async (data) => {
        dispatch(editTicket(data, user, navigate));
    };

    return (
        <>
            {!isLoading ? (
                <>
                    <Helmet>
                        <title>Edit Ticket</title>
                        <meta name="description" content="Edit Ticket page" />
                    </Helmet>
                    <div className="account-content">
                        <div className="container">
                            <div>
                                <div className="account-wrapper">
                                    <h3 className="account-title">Edit Ticket</h3>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Title</label>
                                                        <Controller
                                                            name="title"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.title ? "error-input" : ""}`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue={ticket?.title}
                                                        />
                                                        <small>{errors?.title?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Priority</label>
                                                        <Controller
                                                            name="priority"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <select
                                                                    className={`form-control ${errors?.priority ? "error-input" : ""} select`}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                >
                                                                    {Priorities.map(({ label, value }) => (
                                                                        <option key={value} value={value}>
                                                                            {label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                            defaultValue={ticket?.priority}
                                                        />
                                                        <small>{errors?.priority?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Description</label>
                                                        <Controller
                                                            name="description"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.description ? "error-input" : ""}`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue={ticket?.description}
                                                        />
                                                        <small>{errors?.description?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6" style={{ display: "none" }}>
                                                    <div className="form-group">
                                                        <label>Status</label>
                                                        <Controller
                                                            name="status"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.status ? "error-input" : ""}`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue={ticket?.status}
                                                        />
                                                        <small>{errors?.status?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6" style={{ display: "none" }}>
                                                    <div className="form-group">
                                                        <label>TicketIdentifier</label>
                                                        <Controller
                                                            name="ticketIdentifier"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.ticketIdentifier ? "error-input" : ""}`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue={ticketId}
                                                        />
                                                        <small>{errors?.ticketIdentifier?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6" style={{ display: "none" }}>
                                                    <div className="form-group">
                                                        <label>UserId</label>
                                                        <Controller
                                                            name="userId"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.userId ? "error-input" : ""}`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue={user?.userIdentifier}
                                                        />
                                                        <small>{errors?.userId?.message}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group text-center">
                                                <button
                                                    className="btn btn-primary account-btn"
                                                    type="submit"
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : <Loader />
            }
        </>
    );
}
export default EditTicketForm;
