import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteClient, getAllClientsforCompany, getClient } from '../../../features/clientSlice';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../components/loader/Loader';

function CompanyAllClients() {
    const { companyId } = useParams();
    const dispatch = useDispatch();
    const { companyClients, companyClientsCount, isLoading } = useSelector(state => state.client);
    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 10
    })

    const allClients = async () => {
        dispatch(getAllClientsforCompany(companyId, state.search, state.page, state.pagesize));
    };

    useEffect(() => {
        allClients();
    }, [state.search, state.page, state.pagesize])

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleDelete = (id) => {
        dispatch(deleteClient(id));
    };
    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };
    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };
    const handleEditClick = (id) => {
        dispatch(getClient(id));
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'clientName',
            sorter: (a, b) => a.clientName.length - b.clientName.length,
        },
        {
            title: 'Email',
            dataIndex: 'clientEmail',
            sorter: (a, b) => a.clientEmail.length - b.clientEmail.length,
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            sorter: (a, b) => a.companyName.length - b.companyName.length,
        },
        {
            title: 'Address',
            dataIndex: 'clientAddress',
            sorter: (a, b) => a.clientAddress.length - b.clientAddress.length,
        },
        {
            title: "Action",
            render: (text, record) => (
                <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
                    <Link to={`/company/update-client/${record.clientIdentifier}/${companyId}`}
                        className="dropdown-item px-2 text-success" onClick={() => {
                            handleEditClick(record.clientIdentifier)
                        }}>
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
                        handleDeleteClick(record.clientIdentifier);
                    }}>
                        <i className="fa fa-trash-o m-r-5" />
                    </Link>
                </div>
            ),
        },
    ]

    return (
        <>
            {!isLoading ? (
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title">Company</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/company-dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Clients</li>
                                </ul>
                            </div>
                            <div className="col-auto float-end ml-auto">
                                <Link to={`/company/add-client/${companyId}`} className="btn add-btn">
                                    <i className="fa fa-plus" /> Add Client
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row filter-row justify-content-end">
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group form-focus">
                                <input
                                    type="text"
                                    className="form-control floating"
                                    onChange={(e) => setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' })}
                                />
                                <label className="focus-label">Search....</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <Table
                                    className="table-striped"
                                    pagination={{
                                        current: state.page,
                                        pageSize: state.pagesize,
                                        total: companyClientsCount,
                                        showTotal: (total, range) =>
                                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                        showSizeChanger: true,
                                        onShowSizeChange: onShowSizeChange,
                                        itemRender: itemRender,
                                        onChange: (page, pageSize) =>
                                            setState({ ...state, page, pagesize: pageSize }),
                                    }}
                                    style={{ overflowX: "auto" }}
                                    columns={columns}
                                    dataSource={companyClients}
                                    rowKey={(record) => record.clientIdentifier}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    )
}

export default CompanyAllClients