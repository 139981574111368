import React, { useEffect } from "react";
import { logo_2 } from "../../assets/assets2/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../../features/authRegisterSlice";

function VerifyEmail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("id");
    dispatch(verifyEmail(userId, navigate));
  }, [dispatch, location.search, navigate]);
  const { isLoading } = useSelector((state) => state.userRegister);
  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="account-logo">
        <Link to="#">
          <img
            src={logo_2}
            alt="CHS"
            style={{ position: "fixed", width: "250px", top: "50%", left: "45%" }}
          />
        </Link>
      </div>{" "}
    </>
  );
}

export default VerifyEmail;
