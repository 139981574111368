import React from 'react'
import { Link } from 'react-router-dom';
import { banner_2, banner_bg_2, circle_bg, cta, placeholder, video_bg } from '../../assets/assets2/images';
import FeaturesArea from './components/FeaturesArea';
import OverviewArea from '../../components/OverviewArea';
import ChooseArea from './components/ChooseArea';
import FunfactArea from './components/Fun-factArea';
import TestimonialArea from './components/TestimonialArea';
import PricingArea from '../../components/PricingArea';
import BlogArea from '../../components/BlogArea';
import FaqArea from './components/FaqArea';
import ContactArea from './components/ContactArea';
import { FaPlay } from 'react-icons/fa';

function Home() {
  return (
    <>
      <div className="home-banner-area banner-bg banner-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-content mb-30">
                <h1 className="title">Customize your Experience</h1>
                <p className="text">Track Tick provides you best Office-Management. Just click on get started and create your company account.</p>
                <Link to="/register" className="btn primary-btn primary-btn-2" style={{ color: 'white' }}>Get Started</Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-image image-right mb-30">
                <div className="lazy-container aspect-ratio aspect-ratio-16-11">
                  <img className="lazyload lazy-image" src={placeholder} data-src={banner_2} alt="Banner Background" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-shape bg-shape-1">
          <img src={banner_bg_2} alt="banner-bg-2" />
        </div>
        <div className="bg-shape bg-shape-2">
          <img src={circle_bg} alt="banner-bg-3" />
        </div>
      </div>
      <FeaturesArea />
      <OverviewArea />
      {/* Start Video Area */}
      <div id="video" className="video-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="image">
                <div className="lazy-container aspect-ratio aspect-ratio-16-9">
                  <img className="lazyload lazy-image" src={placeholder} data-src={video_bg} alt="video" />
                </div>
                <Link to="#" className="youtube-popup video-btn">
                  <i><FaPlay /></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Video Area */}

      <ChooseArea />
      <FunfactArea />
      <TestimonialArea />
      <PricingArea />
      {/* Start Cta Area */}
      <section id="ctaArea" className="cta-area cta-2 pt-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="section-title">
                <h2 className="title">07 days free trial for
                  first 200 users.</h2>
                <a href="contact.html" className="btn primary-btn primary-btn-2" style={{ color: 'white' }}>Try it Free for 7 Days</a>
              </div>
              <div className="image">
                <div className="lazy-container aspect-ratio aspect-ratio-5-3">
                  <img className="lazyload lazy-image" src={placeholder} data-src={cta} alt="cta" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Cta Area */}

      <BlogArea />
      <FaqArea />
      <ContactArea />
    </>
  )
}

export default Home