import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { updateCompanyBasicInfo } from '../../../features/companySlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { emailrgx } from '../../../constant';
import Loader from '../../../components/loader/Loader';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';

const schema = yup
    .object({
        email: yup.string().required("Email is required").matches(emailrgx, "Invalid Email").trim(),
        name: yup.string().required("Name is required").trim(),
        address: yup.string().required("Address is required").trim(),
        country: yup.string().required("Company is required").trim(),
        contact: yup.string().required("Contact Number is required"),
    })
    .required();

function EditCompanyModal(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.company);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        dispatch(updateCompanyBasicInfo(props.id, data, navigate, props.toggleEditCompanyModal));
    };
    return (
        <>
            {isLoading ? <Loader /> : ""}
            <Modal
                size='lg'
                isOpen={props.editCompanyModal}
                toggle={() => props.toggleEditCompanyModal()}
            >
                <ModalHeader id="edit-profile-box" toggle={() => props.toggleEditCompanyModal()}>
                    <i className="fa fa-pencil m-r-5 text-success me-5"></i> Update Company Details
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Name</label>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0${errors?.name ? "error-input mb-0" : ""
                                                    }`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={props.company.name}
                                    />
                                    {errors.name && <small>{errors?.name?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0 ${errors?.email ? "error-input mb-0" : ""
                                                    }`}
                                                type="text"
                                                value={value ?? ""}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={props.company.email}
                                    />
                                    {errors.email && <small>{errors?.email?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Contact Number</label>
                                    <Controller
                                        name="contact"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <PhoneInput
                                                className={`form-control mb-0 ${errors?.contact ? "error-input" : ""}`}
                                                international
                                                countryCallingCodeEditable={false}
                                                defaultCountry="IN"
                                                value={value}
                                                initialValueFormat="national"
                                                onChange={onChange}
                                            />
                                        )}
                                        defaultValue={props.company.contact}
                                    />
                                    {errors.contact && <small>{errors?.contact?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <Controller
                                        name="address"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0${errors?.address ? "error-input mb-0" : ""
                                                    }`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={props.company.address}
                                    />
                                    {errors.address && <small>{errors?.address?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Country</label>
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0${errors?.country ? "error-input mb-0" : ""
                                                    }`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={props.company.country}
                                    />
                                    {errors.country && <small>{errors?.country?.message}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <button
                                className="btn btn-primary account-btn mt-2"
                                type="submit"
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default EditCompanyModal