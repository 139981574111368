import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Loader from '../../../components/loader/Loader';
import { useSelector } from 'react-redux';
import { updateCompanyPersonalInfo } from '../../../features/companySlice';
import ReactSelect from 'react-select';
import { CompanySize, CompanyType } from '../../../constant';

const schema = yup
    .object({
        ceo: yup.string().required("CEO is required").trim(),
        founder: yup.string().required("Founder is required").trim(),
        businessType: yup.string().required("Select Business Type").trim(),
        companySize: yup.string().required("Select Company Size").trim(),
        taxIdentificationNumber: yup.string().required("Tax Identification Number is required").trim(),
        website: yup.string().required("Provide valid company website url").trim(),
    })
    .required();

function EditCompanyPersonalInformationModel(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.company);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        dispatch(updateCompanyPersonalInfo(props.id, data, navigate, props.toggleEditCompanyPersonlInfoModal));
    };
    return (
        <>
            {isLoading ? <Loader /> : ""}
            <Modal
                size='lg'
                isOpen={props.editCompanyPersonlInfoModal}
                toggle={() => props.toggleEditCompanyPersonlInfoModal()}
            >
                <ModalHeader id="edit-profile-box" toggle={() => props.toggleEditCompanyPersonlInfoModal()}>
                    <i className="fa fa-pencil m-r-5 text-success me-5"></i> Update Company Personal Details
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>CEO</label>
                                    <Controller
                                        name="ceo"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0${errors?.ceo ? "error-input mb-0" : ""
                                                    }`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={props.company.ceo}
                                    />
                                    {errors.ceo && <small>{errors?.ceo?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Founder</label>
                                    <Controller
                                        name="founder"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0 ${errors?.founder ? "error-input mb-0" : ""
                                                    }`}
                                                type="text"
                                                value={value ?? ""}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={props.company.founder}
                                    />
                                    {errors.founder && <small>{errors?.founder?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Business Type</label>
                                    <Controller
                                        name="businessType"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <ReactSelect
                                                options={CompanyType}
                                                value={CompanyType.find(option => option.value === value)}
                                                onChange={selected => onChange(selected?.value)}
                                                isClearable
                                                isSearchable
                                            />
                                        )}
                                        defaultValue={props.company.businessType}
                                    />
                                    {errors.businessType && <small>{errors?.businessType?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Company Size</label>
                                    <Controller
                                        name="companySize"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <ReactSelect
                                                options={CompanySize}
                                                value={CompanySize.find(option => option.value === value)}
                                                onChange={selected => onChange(selected?.value)}
                                                isClearable
                                                isSearchable
                                            />
                                        )}
                                        defaultValue={props.company.companySize}
                                    />
                                    {errors.companySize && <small>{errors?.companySize?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Tax Identification Number</label>
                                    <Controller
                                        name="taxIdentificationNumber"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0${errors?.taxIdentificationNumber ? "error-input mb-0" : ""
                                                    }`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={props.company.taxIdentificationNumber}
                                    />
                                    {errors.taxIdentificationNumber && <small>{errors?.taxIdentificationNumber?.message}</small>}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Website</label>
                                    <Controller
                                        name="website"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control mb-0${errors?.website ? "error-input mb-0" : ""
                                                    }`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={props.company.website}
                                    />
                                    {errors.website && <small>{errors?.website?.message}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <button
                                className="btn btn-primary account-btn"
                                type="submit"
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default EditCompanyPersonalInformationModel