import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployee } from '../../features/employeeSlice';
import { getCompany } from '../../features/companySlice';
import { toast } from 'react-hot-toast';
import { genericImg } from '../../assets/img';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Modal, ModalBody } from "reactstrap";
import { updateProfilePicture } from '../../features/authLoginSlice';
import Loader from '../loader/Loader';
import UpdatePasswordModal from '../auth/UpdatePasswordModal';

function MyProfile() {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem("user"));
    const { isLoading } = useSelector((state) => state.userLogin);
    const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);
    const toggleUpdatePasswordModal = () => setUpdatePasswordModalOpen(!updatePasswordModalOpen);
    const { employee } = useSelector(state => state.employee);
    const { company } = useSelector(state => state.company);

    const [src, selectFile] = useState(null);
    const [image, setImage] = useState(null);
    const [updatePhotoModalOpen, setUpdatePhotoModalOpen] = useState(false);
    const toggleUpdatePhotoModal = () => setUpdatePhotoModalOpen(!updatePhotoModalOpen);
    const [crop, setCrop] = useState({
        unit: '%',
        x: 25,
        y: 25,
        width: 50,
        height: 50
    });

    function getCroppedImage() {
        if (image) {
            const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            const base64Image = canvas.toDataURL('image/jpeg');
            const blob = dataURItoBlob(base64Image);
            var formData = new FormData();
            formData.append('userId', user.userIdentifier);
            formData.append('profilePhoto', blob, `${user.name}.jpg`);
            dispatch(updateProfilePicture(formData));
            toggleUpdatePhotoModal();
        }
    }

    function dataURItoBlob(dataURI) {
        // Convert base64 to raw binary data
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // Write the bytes of the string to an ArrayBuffer
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        // Create Blob object from ArrayBuffer
        const blob = new Blob([arrayBuffer], { type: mimeString });
        return blob;
    }

    // const handleUpdateProfile = (id) => {
    //     confirmAlert({
    //         title: "Change Profile Photo",
    //         buttons: [
    //             {
    //                 label: "Upload Photo",
    //                 onClick: () => {
    //                     document.getElementById("photoUploadInput").click();
    //                 },
    //             },
    //             {
    //                 label: "Cancel",
    //             },
    //         ],
    //     });
    // };

    const handlePhotoUpload = e => {
        const file = e.target.files[0];
        const imageUrl = URL.createObjectURL(file);

        const imageElement = new Image();
        imageElement.onload = () => {
            setImage(imageElement);
            selectFile(imageUrl);
        };
        imageElement.src = imageUrl;
        setUpdatePhotoModalOpen(true);
    };

    useEffect(() => {
        if (user.employeeIdentifier) {
            dispatch(getEmployee(user.employeeIdentifier));
        }
        else if (user.companyIdentifier) {
            dispatch(getCompany(user.companyIdentifier));
        }
        else {
            toast.error("Something went wrong. Please restart your session!")
        }
    }, [user.employeeIdentifier, user.companyIdentifier, dispatch])

    return (
        <>
            {
                isLoading ?
                    <Loader /> :
                    <>
                        <Helmet>
                            <title>Profile - CHS</title>
                            <meta name="description" content="Reactify Blank Page" />
                        </Helmet>
                        <div className="content container-fluid">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h3 className="page-title">My Profile</h3>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="#" style={{ textTransform: 'capitalize' }}>{user.role}</Link>
                                            </li>
                                            <li className="breadcrumb-item active">{company ? company.name : employee ? employee.name : "N/A"}</li>
                                        </ul>
                                    </div>
                                    <div className="col-auto float-end ml-auto">
                                        <Link
                                            to={`#`}
                                            onClick={() => setUpdatePasswordModalOpen(true)}
                                            className="btn add-btn"
                                        >
                                            UpdatePassword
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* Update Password Modal */}
                            <UpdatePasswordModal
                                updatePasswordModalOpen={updatePasswordModalOpen}
                                toggleUpdatePasswordModal={toggleUpdatePasswordModal}
                                user={user}
                            />
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="profile-view">
                                                <div className="profile-img-wrap">
                                                    <div className="profile-img">
                                                        <Link
                                                            to="#"
                                                        // onClick={() => {
                                                        //     handleUpdateProfile(user.userIdentifier);
                                                        // }}
                                                        >
                                                            <img src={genericImg} alt="" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <input
                                                    id="photoUploadInput"
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    onChange={handlePhotoUpload}
                                                />
                                                <div className="profile-basic">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <div className="profile-info-left">
                                                                <h3 className="user-name m-t-0 mb-0">
                                                                    {user.name}
                                                                </h3>
                                                                <div className="staff-id">
                                                                    {user.role.replace(
                                                                        user.role[0],
                                                                        user.role.charAt(0).toUpperCase()
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <ul className="personal-info">
                                                                <li>
                                                                    <div className="title">Phone:</div>
                                                                    <div className="text">{"N/A"}</div>
                                                                </li>
                                                                <li>
                                                                    <div className="title">Email:</div>
                                                                    <div className="text">{company ? company.email : employee ? employee.email : "N/A"}</div>
                                                                </li>
                                                                <li>
                                                                    <div className="title">Address:</div>
                                                                    <div className="text">{company ? company.address : employee ? employee.address : "N/A"}</div>
                                                                </li>
                                                                <li>
                                                                    <div className="title">Country:</div>
                                                                    <div className="text">{company ? company.country : employee ? employee.country : "N/A"}</div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {src && (
                                <Modal
                                    className='profile-modal'
                                    size="md"
                                    isOpen={updatePhotoModalOpen}
                                >
                                    <div className='modal-content2'>
                                        <ModalBody>
                                            <div className="image-prev">
                                                <ReactCrop crop={crop} aspect={1 / 1} onChange={newCrop => setCrop(newCrop)}>
                                                    <img src={src} onLoad={e => setImage(e.target)} alt='' />
                                                </ReactCrop>
                                                <button className="btn btn-danger" onClick={getCroppedImage}>Crop Image</button>
                                            </div>
                                        </ModalBody>
                                    </div>
                                </Modal>
                            )}
                        </div>
                    </>
            }
        </>
    )
}

export default MyProfile;