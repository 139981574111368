import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactSelect from 'react-select';
import { useDispatch } from 'react-redux';
import { addExpense } from '../../../features/expenseSlice';
import { DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import { allCompaniesList } from '../../../features/companySlice';
import { useSelector } from 'react-redux';
import { getAllEmployeesforCompany } from '../../../features/employeeSlice';
import Loader from '../../../components/loader/Loader';

const expenseSchema = yup.object({
        ItemName: yup.string().required('Name is required'),
        PurchaseFrom: yup.string().required('Purchase From is required'),
        PurchaseDate: yup.string().required('Purchase Date is required'),
        PurchaseBy: yup.string().required('Purchased By is required'),
        Amount: yup.string().required('Amount is required'),
        PaidBy: yup.string().required('Paid By is required'),
        Status: yup.string().required('Status is required'),
        Description: yup.string().required('Description is required'),
        Files: yup
                .mixed()
                .test('fileType', 'Only jpg, jpeg, and png files are allowed', (value) => {
                        if (value && value.length > 0) {
                                return value.every(file => ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type));

                        }

                        return true;
                })
                .nullable(),
        Receipt: yup
                .mixed()
                .test('fileTypes', 'Only jpg, jpeg, and png files are allowed', (value) => {
                        console.log('value', value);
                        if (value) {
                                return ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type);
                        }
                        return true;
                })
                .nullable(),

});

const paidBy = [
        { value: 'cash', label: 'Cash' },
        { value: 'check', label: 'Check' },
        { value: 'gpay', label: 'GPay' },
        { value: 'upi', label: 'UPI' }
];

const statusOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Approved', label: 'Approved' }
];

export default function CreateExpense() {
        const dispatch = useDispatch();
        const navigate = useNavigate()
        const [selectedImage, setSelectImage] = useState([]);
        const [selectedDate, setSelectedDate] = useState(null);
        const { allCompanies } = useSelector((state) => state.company);
        const { companyEmployees } = useSelector((state) => state.employee);
        const { isLoading } = useSelector((state) => state.expense);
        const [selectedCompanyId, setSelectedCompanyId] = useState("");
        const [previewUrl, setPreviewUrl] = useState("");

        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10
        })

        const { formState: { errors }, control, handleSubmit, setValue } = useForm({
                defaultValues: {
                        ItemName: '',
                        PurchaseFrom: '',
                        PurchaseDate: '',
                        PurchaseBy: '',
                        Amount: '',
                        PaidBy: '',
                        Status: '',
                        Description: '',
                        Files: [],
                        Receipt: []
                },
                resolver: yupResolver(expenseSchema),
        });

        useEffect(() => {
                dispatch(allCompaniesList())
        }, [dispatch]);

        useEffect(() => {
                if (selectedCompanyId) {
                        dispatch(getAllEmployeesforCompany(state.search, selectedCompanyId, state.page, state.pagesize));
                }
        }, [selectedCompanyId, dispatch, state.search, state.page, state.pagesize]);

        const companyOptions = allCompanies?.map(company => ({
                value: company?.companyIdentifier,
                label: company?.name
        }));

        const emloyeeOptions = companyEmployees?.map(employee => ({
                value: employee.employeeIdentifier,
                label: employee.name
        }));

        const handleDateChange = (date) => {
                setSelectedDate(date);
        };

        const handleFileChange = (e, onChange) => {
                const files = Array.from(e.target.files);
                setSelectImage(prevImages => [...prevImages, ...files]);
                onChange(files); // Set value in the form state using onChange from Controller
        };

        const onSubmit = (data) => {
                const formData = new FormData();
                formData.append('ItemName', data?.ItemName);
                formData.append('CompanyIdentifier', data?.companyIdentifier);
                formData.append('PurchaseFrom', data?.PurchaseFrom);
                formData.append('PurchaseDate', data?.PurchaseDate);
                formData.append('PurchaseBy', data?.PurchaseBy);
                formData.append('Amount', data?.Amount);
                formData.append('PaidBy', data?.PaidBy);
                formData.append('Status', data?.Status);
                formData.append('Description', data?.Description);
                data.Files.forEach(file => formData.append('Files', file));
                formData.append('Receipt', data?.Receipt);
                dispatch(addExpense(formData));
                navigate('/admin/all-expense')
                console.log('dataaaaaa', data);
        }

        return (
                <div id="add_expense" className="content container-fluid" >
                        <div>
                                <div className="modal-content">
                                        <div className="page-header">
                                                <h5 className="modal-title">Add Expense</h5>
                                                <ul className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="/admin-dashboard">Dashboard</a></li>
                                                        <li className="breadcrumb-item active">Add Expense</li>
                                                </ul>
                                        </div>
                                        {
                                                isLoading
                                                        ?
                                                        <Loader />
                                                        :
                                                        <div className="row">
                                                                <form onSubmit={handleSubmit(onsubmit)} >
                                                                        <div className="row mb-3">
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="ItemName">Item Name</label>
                                                                                                <Controller
                                                                                                        name="ItemName"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <input
                                                                                                                        className={`form-control mb-0`}
                                                                                                                        type="text"
                                                                                                                        value={value}
                                                                                                                        onChange={onChange}
                                                                                                                        autoComplete="false"
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.ItemName?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="purchaseFrom">Purchase From</label>
                                                                                                <Controller
                                                                                                        name="PurchaseFrom"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <input
                                                                                                                        className={`form-control mb-0`}
                                                                                                                        type="text"
                                                                                                                        value={value}
                                                                                                                        onChange={onChange}
                                                                                                                        autoComplete="false"
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.PurchaseFrom?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="purchaseDate">Purchase Date</label>
                                                                                                <Controller
                                                                                                        name="PurchaseDate"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <DatePicker
                                                                                                                        format="MM/DD/YYYY"
                                                                                                                        placeholder="Select Date"
                                                                                                                        onChange={(date) => {
                                                                                                                                onChange(date);
                                                                                                                                handleDateChange(date);
                                                                                                                        }}
                                                                                                                        value={value ? value : selectedDate}
                                                                                                                        className={`form-control mb-0`}
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.PurchaseDate?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="Company">Company</label>
                                                                                                <Controller
                                                                                                        name="companyIdentifier"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <ReactSelect
                                                                                                                        options={companyOptions}
                                                                                                                        value={companyOptions.find(option => option.value === value)}
                                                                                                                        isClearable
                                                                                                                        isSearchable
                                                                                                                        className="company-box"
                                                                                                                        onChange={(selected) => {
                                                                                                                                const selectedValue = selected ? selected.value : "";
                                                                                                                                onChange(selectedValue);
                                                                                                                                setSelectedCompanyId(selectedValue);
                                                                                                                        }}
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.PaidBy?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="amount">Amount</label>
                                                                                                <Controller
                                                                                                        name="Amount"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <input
                                                                                                                        className={`form-control mb-0`}
                                                                                                                        type="text"
                                                                                                                        value={value}
                                                                                                                        onChange={onChange}
                                                                                                                        autoComplete="false"
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.Amount?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="PurchaseBy">Purchased By</label>
                                                                                                <Controller
                                                                                                        name="PurchaseBy"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <ReactSelect
                                                                                                                        options={emloyeeOptions}
                                                                                                                        value={emloyeeOptions.find(option => option.value === value)}
                                                                                                                        onChange={selected => onChange(selected?.value)}
                                                                                                                        isClearable
                                                                                                                        isSearchable
                                                                                                                        className="company-box"
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.PurchaseBy?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="status">Status</label>
                                                                                                <Controller
                                                                                                        name="Status"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <ReactSelect
                                                                                                                        options={statusOptions}
                                                                                                                        value={statusOptions.find(option => option.value === value)}
                                                                                                                        onChange={selected => onChange(selected?.value)}
                                                                                                                        isClearable
                                                                                                                        isSearchable
                                                                                                                        className="company-box"
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.Status?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                        <label htmlFor="Description">Description</label>
                                                                                        <div className="form-group">
                                                                                                <Controller
                                                                                                        name="Description"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <textarea className="form-control" rows={3} value={value} onChange={onChange} />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.Description?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="attachments">Attachments</label>
                                                                                                <Controller
                                                                                                        name="Files"
                                                                                                        control={control}
                                                                                                        render={({ field: { onChange, value } }) => (
                                                                                                                <input
                                                                                                                        className={`form-control mb-0`}
                                                                                                                        type="file"
                                                                                                                        multiple
                                                                                                                        onChange={(e) => handleFileChange(e, onChange)}
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.Files?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="paidBy">Paid By</label>
                                                                                                <Controller
                                                                                                        name="PaidBy"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <ReactSelect
                                                                                                                        options={paidBy}
                                                                                                                        value={paidBy.find(option => option.value === value)}
                                                                                                                        onChange={selected => onChange(selected?.value)}
                                                                                                                        isClearable
                                                                                                                        isSearchable
                                                                                                                        className="company-box"
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.PaidBy?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                                <ul className="list-unstyled">
                                                                                        {selectedImage.map((image, index) => (
                                                                                                <li className="d-flex align-items-center mb-2 position-relative" key={index}>
                                                                                                        <img src={URL.createObjectURL(image)} alt="attachment" className="me-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                                                                        <span style={{ position: 'absolute', top: 0, }} className="btn btn-danger btn-sm" onClick={() => {
                                                                                                                setSelectImage(prevImages => prevImages.filter((_, i) => i !== index));
                                                                                                        }}><i className="fa fa-close"></i></span>
                                                                                                </li>
                                                                                        ))}
                                                                                </ul>
                                                                        </div>
                                                                        <div className='row'>
                                                                                <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                                <label htmlFor="Receipt">Receipt</label>
                                                                                                <Controller
                                                                                                        name="Receipt"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <input
                                                                                                                        className={`form-control mb-0`}
                                                                                                                        type="file"
                                                                                                                        onChange={(e) => {
                                                                                                                                const file = e.target.files[0];
                                                                                                                                if (file && file.type.startsWith("image/")) {
                                                                                                                                        const previewUrl = URL.createObjectURL(file);
                                                                                                                                        setPreviewUrl(previewUrl);
                                                                                                                                        onChange(file);
                                                                                                                                }
                                                                                                                        }}
                                                                                                                        accept="image/*"
                                                                                                                />
                                                                                                        )}
                                                                                                />
                                                                                                <span className='text-danger'>{errors?.Receipt?.message}</span>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        {previewUrl && (
                                                                                <div className="preview-image-container">
                                                                                        <img className="preview-image" src={previewUrl} alt="Preview" />
                                                                                </div>
                                                                        )}
                                                                        <div className="text-center mt-4">
                                                                                <button type="submit" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>Submit</button>
                                                                        </div>
                                                                </form>
                                                        </div>
                                        }
                                </div>
                        </div>
                </div>
        )
}
