import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Priorities } from "../../../constant";
import { addTicket } from "../../../features/ticketsSlice";
import Loader from "../../../components/loader/Loader";
import { useSelector } from "react-redux";
    
const schema = yup.object({
    title: yup.string().required("Title is required").trim(),
    priority: yup.string().required("You have to set priority").trim(),
    description: yup.string().required("Description is required").trim(),
    files: yup.array().of(
        yup
            .mixed()
            .test(
                "fileType",
                "Invalid file format, only images are allowed",
                (value) => value && value.type.startsWith("image/")
            )
            .test(
                "fileSize",
                "File size is too large",
                (value) => value && value.size <= 1024 * 1024 * 5
            )
    ),
});

function AddTicket() {
    const { companyId } = useParams();
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [previewUrls, setPreviewUrls] = useState([]);
    const { isLoading } = useSelector(state => state.ticket);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleDeselectImage = (index) => {
        setPreviewUrls((prevImages) => {
            const newImages = [...prevImages];
            newImages.splice(index, 1);
            setPreviewUrls(newImages);
            return newImages;
        });
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("priority", data.priority);
        formData.append("description", data.description);
        formData.append("employeeId", user.employeeIdentifier ? user.employeeIdentifier : "");
        formData.append("companyId", user.companyIdentifier ? user.companyIdentifier : companyId ? companyId : "");

        if (data.files) {
            for (let i = 0; i < data.files.length; i++) {
                formData.append("files", data.files[i]);
            }
        }
        dispatch(addTicket(formData, navigate, user, companyId ? companyId : ""));
    }

    return (
        <>
            <Helmet>
                <title>Add Ticket - CHS</title>
                <meta name="description" content="Login page" />
            </Helmet>
            {isLoading ? <Loader /> : ""}
            <div className="account-content">
                <div className="container">
                    <div>
                        <div className="account-wrapper">
                            <h3 className="account-title">Add Ticket</h3>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <Controller
                                                    name="title"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control  ${errors?.title ? "error-input" : ""}`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue=""
                                                />
                                                <small>{errors?.title?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Priority</label>
                                                <Controller
                                                    name="priority"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <select
                                                            className={`form-control ${errors?.priority ? "error-input" : ""} select`}
                                                            value={value}
                                                            onChange={onChange}
                                                        > <option value={""}>
                                                                {"--Select--"}
                                                            </option>
                                                            {Priorities.map(({ label, value }) => (
                                                                <option key={value} value={value}>
                                                                    {label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                <small>{errors?.priority?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <Controller
                                                    name="description"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control  ${errors?.description ? "error-input" : ""}`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue=""
                                                />
                                                <small>{errors?.description?.message}</small>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Image</label>
                                                <Controller
                                                    name="files"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, onBlur } }) => (
                                                        <>
                                                            <input
                                                                type="file"
                                                                onChange={(e) => {
                                                                    onChange([...e.target.files]);
                                                                }}
                                                                onBlur={onBlur}
                                                                multiple
                                                                accept="image/*"
                                                                className="form-control"
                                                            />
                                                            {previewUrls.map((url, index) => (
                                                                <div className="preview-image-container" key={index}>
                                                                    <img className="preview-image" src={url} alt={`Preview ${index}`} />
                                                                    <span className="deselect-icon" onClick={() => handleDeselectImage(index)}>x</span>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                />
                                                {errors.files && (
                                                    <span className="error-message">{errors.files.message}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group text-center">
                                        <button
                                            className="btn btn-primary account-btn"
                                            type="submit"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddTicket;
