import { createBrowserRouter } from "react-router-dom";
import Error404 from "./components/Error404";
import LoginForm from "./components/auth/LoginForm";
import ForgotPassword from "./components/auth/forgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import RegisterForm from "./components/auth/RegisterForm";
import VerifyEmail from "./components/auth/VerifyEmail";
import UnAuthorized from "./components/UnAuthorized";
import RequireAuth from "./components/RequireAuth";
import { ROLES } from "./constant";
import UnApprovedCompanies from "./Areas/Admin/Company/UnApprovedCompanies";
import UserProfile from "./components/user/UserProfile";
import AllCompanies from "./Areas/Admin/Company/AllCompanies";
import HomeLayout from "./components/layouts/HomeLayout";
import Home from "./pages/home/Home";
import WhoWeAre from "./pages/whoweare/WhoWeAre";
import HowItWorks from "./pages/howItWorks/HowItWorks";
import Services from "./pages/services/Services";
import Blog from "./pages/blog/Blog";
import BlogDetail from "./pages/blog/BlogDetail";
import Contact from './pages/contact/Contact';
import AdminDashboard from "./Areas/Admin/Dashboard/AdminDashboard";
import AddCompany from "./Areas/Admin/Company/AddCompany";
import UpdateCompany from "./Areas/Admin/Company/UpdateCompany";
import AllTickets from "./Areas/Admin/Tickets/AllTickets";
import CompanyProfile from "./Areas/Admin/Company/CompanyProfile";
import AddEmployee from "./Areas/Admin/Employee/AddEmployee";
import AllEmployees from "./Areas/Admin/Employee/AllEmployees";
import AddDepartment from './components/department/AddDepartment';
import AllDepartments from "./components/department/AllDepartments";
import DashboardLayout from "./components/layouts/DashboardLayout";
import EmployeeDashboard from "./Areas/Employee/Dashboard/EmployeeDashboard";
import MyProfile from "./components/user/MyProfile";
import EmployeeTickets from "./Areas/Employee/Ticket/Alltickets";
import AddTicket from "./Areas/Employee/Ticket/AddTicket";
import EditTicketForm from "./components/Tickets/EditTicketForm";
import TicketView from "./components/Tickets/Ticketview";
import AttendanceEmployee from "./Areas/Employee/Attendance/AttendanceEmployee";
import AllBoards from "./Areas/Employee/TaskBoard/AllBoards";
import Taskboard from "./Areas/Employee/TaskBoard/Taskboard";
import AllClients from "./Areas/Admin/Client/allClients";
import AddClient from "./Areas/Admin/Client/addClient";
import UpdateClient from "./Areas/Admin/Client/updateClient";
import CompanyDashboard from "./Areas/Company/Dashboard/companyDashboard";
import CompanyAllClients from "./Areas/Company/Client/companyAllClients";
import CompanyAddClient from "./Areas/Company/Client/companyAddClient";
import CompanyUpdateClient from "./Areas/Company/Client/companyUpdateClient";
import CompanyTickets from "./Areas/Company/Ticket/companyTickets";
import CompanyAddTicket from "./Areas/Company/Ticket/CompanyAddTicket";
import CompanyEmployees from "./Areas/Company/Employee/companyEmployees";
import CompanyAddEmployee from "./Areas/Company/Employee/companyAddEmployee";
import UpdateEmployeeForm from "./components/employee/UpdateEmployeeForm";
import EmployeeProfile from "./components/employee/employeeProfile";
import CompanyDepartments from "./Areas/Company/Department/CompanyDepartments";
import CompanyUpdateDepartment from "./Areas/Company/Department/CompanyUpdateDepartment";
import CompanyAddDepartment from "./Areas/Company/Department/CompanyAddDepartment";
import AllProjectsList from "./Areas/Admin/Project/allProjectsList";
import EditProject from "./Areas/Admin/Project/EditProject";
import CreateProject from "./Areas/Admin/Project/createProject";
import Estimates from "./Areas/Sales/Estimates/Estimates";
import CreateEstimate from "./Areas/Sales/Estimates/CreateEstimate";
import EditEstimate from "./Areas/Sales/Estimates/EditEstimate";
import EstimateView from "./Areas/Sales/Estimates/EstimateView";
import AllTax from "./Areas/Sales/Tax/AllTax";
import CreateTax from "./Areas/Sales/Tax/CreateTax";
import EditTax from "./Areas/Sales/Tax/EditTax";
import CreateInvoice from "./Areas/Sales/Invoice/CreateInvoice";
import EditInvoice from "./Areas/Sales/Invoice/EditInvoice";
import Invoices from "./Areas/Sales/Invoice/Invoices";
import CreateExpense from "./Areas/Sales/Expense/CreateExpense";
import AllExpense from "./Areas/Sales/Expense/AllExpense";
import EditExpense from "./Areas/Sales/Expense/EditExpense";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout />,
        errorElement: <Error404 />,
        children: [
            { path: "", element: <Home /> },
            { path: "home", element: <Home /> },
            { path: "who-we-are", element: <WhoWeAre /> },
            { path: "how-it-work", element: <HowItWorks /> },
            { path: "services", element: <Services /> },
            { path: "blog", element: <Blog /> },
            { path: "blog-details", element: <BlogDetail /> },
            { path: "contact", element: <Contact /> },
        ]
    },
    { path: "login", element: <LoginForm /> },
    { path: "forgot-password", element: <ForgotPassword /> },
    { path: "reset-password/:userId", element: <ResetPassword /> },
    { path: "register", element: <RegisterForm /> },
    { path: "verifyEmail", element: <VerifyEmail /> },
    { path: "unauthorized", element: <UnAuthorized /> },
    {
        path: 'admin',
        element: (
            <RequireAuth allowedRoles={[ROLES.Admin]} element={<DashboardLayout />}></RequireAuth>
        ),
        errorElement: <UnAuthorized />,
        children: [
            { path: "dashboard", element: <AdminDashboard /> },
            { path: "profile", element: <UserProfile /> },
            { path: "all-companies", element: <AllCompanies /> },
            { path: "add-company", element: <AddCompany /> },
            { path: "unap-companies", element: <UnApprovedCompanies /> },
            { path: "update-company/:id", element: <UpdateCompany /> },
            { path: "all-employees", element: <AllEmployees /> },
            { path: "add-employee", element: <AddEmployee /> },
            { path: "update-employee/:employeeId", element: <UpdateEmployeeForm /> },
            { path: "employee-profile/:employeeId", element: <EmployeeProfile /> },
            { path: "all-tickets", element: <AllTickets /> },
            { path: "ticket-view/:ticketId", element: <TicketView /> },
            { path: "edit-ticket/:ticketId", element: <EditTicketForm /> },
            { path: "comp-profile/:companyId", element: <CompanyProfile /> },
            { path: "add-department", element: <AddDepartment /> },
            { path: "all-departments", element: <AllDepartments /> },
            { path: "clients", element: <AllClients /> },
            { path: "add-client", element: <AddClient /> },
            { path: "update-client/:clientId", element: <UpdateClient /> },
            { path: "app/profile/client-profile", },
            { path: "projects-list", element: <AllProjectsList /> },
            { path: "update-project/:projectId", element: <EditProject /> },
            { path: "create-project", element: <CreateProject /> },
            { path: "all-estimates", element: <Estimates /> },
            { path: "create-estimates", element: <CreateEstimate /> },
            { path: "edit-estimates/:estimateIdentifier", element: <EditEstimate /> },
            { path: "estimate-veiw", element: <EstimateView /> },
            { path: "all-employees/:companyId", element: <CompanyEmployees /> },
            { path: "company-tickets/:companyId", element: <CompanyTickets /> },
            { path: "user-profile", element: <MyProfile /> },
            { path: "company-addemployee/:companyId", element: <CompanyAddEmployee /> },
            { path: "add-ticket", element: <AddTicket /> },
            { path: "all-tax", element: <AllTax /> },
            { path: "create-tax", element: <CreateTax /> },
            { path: "edit-tax/:taxIdentifier", element: <EditTax /> },
            { path: "all-invoice", element: <Invoices /> },
            { path: "edit-invoice", element: <EditInvoice /> },
            { path: "create-invoice", element: <CreateInvoice /> },
            { path: "create-expense", element: <CreateExpense /> },
            { path: "all-expense", element: <AllExpense /> },
            { path: "edit-expense/:expenseIdentifier", element: <EditExpense /> },
        ]
    },
    {
        path: 'employee',
        element: (
            <RequireAuth allowedRoles={[ROLES.Employee]} element={<DashboardLayout />}></RequireAuth>
        ),
        errorElement: <UnAuthorized />,
        children: [
            { path: "dashboard", element: <EmployeeDashboard /> },
            { path: "user-profile", element: <MyProfile /> },
            { path: "attendance", element: <AttendanceEmployee /> },
            { path: "boards/:employeeID", element: <AllBoards /> },
            { path: "taskboard/:boardID", element: <Taskboard /> },
            { path: "all-tickets/:employeeId", element: <EmployeeTickets /> },
            { path: "add-ticket", element: <AddTicket /> },
            { path: "edit-ticket/:ticketId", element: <EditTicketForm /> },
            { path: "ticket-view/:ticketId", element: <TicketView /> },


        ]
    },
    {
        path: 'company',
        element: (
            <RequireAuth allowedRoles={[ROLES.Company]} element={<DashboardLayout />}></RequireAuth>
        ),
        errorElement: <UnAuthorized />,
        children: [
            { path: "dashboard", element: <CompanyDashboard /> },
            { path: "all-clients/:companyId", element: <CompanyAllClients /> },
            { path: "add-client/:companyId", element: <CompanyAddClient /> },
            { path: "update-client/:clientId/:companyId", element: <CompanyUpdateClient /> },
            { path: "company-tickets/:companyId", element: <CompanyTickets /> },
            { path: "add-ticket/:companyId", element: <CompanyAddTicket /> },
            { path: "add-ticket/:companyId", element: <CompanyAddTicket /> },
            { path: "company-employees/:companyId", element: <CompanyEmployees /> },
            { path: "company-addemployee/:companyId", element: <CompanyAddEmployee /> },
            { path: "update-employee/:employeeId", element: <UpdateEmployeeForm /> },
            { path: "edit-ticket/:ticketId", element: <EditTicketForm /> },
            { path: "employee-profile/:employeeId", element: <EmployeeProfile /> },
            { path: "ticket-view/:ticketId", element: <TicketView /> },
            { path: "company-departments/:companyID", element: <CompanyDepartments /> },
            { path: "comp-update_departments/:departmentID", element: <CompanyUpdateDepartment /> },
            { path: "comp-add_department/:companyID", element: <CompanyAddDepartment /> },
            { path: "all-estimates", element: <Estimates /> },
        ]
    },
]);