import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import { toast } from "react-hot-toast";

const clientSlice = createSlice({
    name: 'client',
    initialState: {
        clients: [],
        clientsCount: null,
        isLoading: false,
        client: null,
        companyClients: [],
        companyClientsCount: null
    },
    reducers: {
        getClientsSuccess: (state, action) => {
            state.clients = action.payload.data.data;
            state.clientsCount = action.payload.data.count;
            state.isLoading = false;
        },
        getClientsFailure: (state, action) => {
            state.clients = [];
            state.isLoading = false;
        },
        addClientSuccess: (state, action) => {
            state.isLoading = false;
        },
        addClientFailure: (state, action) => {
            state.isLoading = false;
        },
        getClientSuccess: (state, action) => {
            state.client = action.payload.data.data;
            state.isLoading = false;
        },
        getClientFailure: (state, action) => {
            state.client = action.payload.data.data;
            state.isLoading = false;
        },
        deleteClientSuccess: (state, action) => {
            const clientId = action.payload.id;
            state.clients = state.clients.filter(client => client.clientIdentifier !== clientId);
            state.isLoading = false;
            state.clientsCount = state.clientsCount - 1;
        },
        updateCompanyClientSuccess: (state, action) => {
            state.isLoading = false;
        },
        updateCompanyClientFailure: (state, action) => {
            state.isLoading = false;
        },
        getAllClientsforCompanySuccess: (state, action) => {
            state.companyClients = action.payload.data;
            state.companyClientsCount = action.payload.count;
            state.isLoading = false;
        },
        getAllClientsforCompanyFailure: (state, action) => {
            state.companyClients = [];
            state.companyClientsCount = null;
            state.isLoading = false;
        },
        setLoading: (state, action) => {
            state.isLoading = true;
        },
    }
});

export const getAllClients = (search, page, pagesize) => async (dispatch) => {
    try {
        dispatch(setLoading);
        const response = await httpHandler.get(`/api/client/allclients?search=${search}&page=${page}&pagesize=${pagesize}`);
        if (response.data.success) {
            dispatch(getClientsSuccess(response));
        }
        else {
            dispatch(getClientsFailure());
        }
    }
    catch (error) {
        dispatch(getClientsFailure());
    }
}

export const addClient = (data, navigate, isCompany, companyId) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.post(`/api/client/addClient`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(addClientSuccess(response.data));
            if (isCompany) {
                navigate(`/company/all-clients/${companyId}`)
            }
            else {
                navigate('/admin/clients')
            }
        }
        else {
            toast.error(response.data.message);
            dispatch(addClientFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(addClientFailure());
    }
}

export const updateClient = (data, clientIdentifier, navigate, isCompany, companyId) => async (dispatch) => {
    try {
        const response = await httpHandler.post(`/api/client/editClient/${clientIdentifier}`, data);
        if (response.data.success) {
            toast.success(response.data.message); if (isCompany) {
                navigate(`/company/all-clients/${companyId}`)
            }
            else {
                navigate('/admin/clients')
            }
        }
        else {
            toast.error(response.data.message);
            dispatch(addClientFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(addClientFailure());
    }
}

export const updateCompanyClient = (data, clientIdentifier, navigate, companyId) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.put(`/api/client/editClient/${clientIdentifier}`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(updateCompanyClientSuccess());
            navigate(`/company/all-clients/${companyId}`)
        }
        else {
            toast.error(response.data.message);
            dispatch(updateCompanyClientFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(updateCompanyClientFailure());
    }
}

export const getClient = (id) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.get(`/api/client/getClient/${id}`);
        if (response.data.success) {
            dispatch(getClientSuccess(response));
        }
        else {
            dispatch(getClientFailure());
        }
    } catch (error) {
        dispatch(getClientFailure());
    }
};

export const deleteClient = (id) => async (dispatch) => {
    try {
        //dispatch(setLoading(true));
        //const response = 
        await httpHandler.get(`/api/client/deleteClient/${id}`);
        //toast.success("Deleted Successfully");
        dispatch(deleteClientSuccess({ id }));
    } catch (error) {
        toast.error(error.message);
        //dispatch(deleteCompanyFailure(error));
    }
};

export const getAllClientsforCompany = (companyId, search, page, pagesize) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.get(`/api/client/allClients?companyIdentifier=${companyId}&search=${search}&page=${page}&pagesize=${pagesize}`);
        if (response.data.success) {
            dispatch(getAllClientsforCompanySuccess(response.data));
        }
        else {
            dispatch(getAllClientsforCompanyFailure(response.data));
        }
    }
    catch (error) {
        dispatch(getAllClientsforCompanyFailure());
    }
}

export const { getClientsSuccess, getClientsFailure, addClientSuccess, addClientFailure, getClientSuccess,
    updateCompanyClientSuccess, updateCompanyClientFailure, getClientFailure, deleteClientSuccess, getAllClientsforCompanySuccess, 
    getAllClientsforCompanyFailure, setLoading } = clientSlice.actions;
export default clientSlice.reducer;