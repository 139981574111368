import React from 'react'
import { about_2 } from '../../../assets/assets2/images'

function AboutArea() {
    return (
        <>
            <section id="about" className="about-area about-2 pt-90">
                <div className="container-fluid p-0">
                    <div className="row align-items-center justify-content-lg-end">
                        <div className="col-lg-auto left-content">
                            <div className="section-title">
                                <h2 className="title">Get notified about
                                    important mail
                                </h2>
                            </div>
                            <div className="item-single mb-30">
                                <div className="icon">
                                    <i className="fal fa-webcam"></i>
                                </div>
                                <div className="content">
                                    <h3>Attendance Manage</h3>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout the point</p>
                                </div>
                            </div>
                            <div className="item-single mb-30">
                                <div className="icon">
                                    <i className="fal fa-images"></i>
                                </div>
                                <div className="content">
                                    <h3>Employee Record System</h3>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout the point</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 right-content">
                            <div className="image mb-30">
                                <img src={about_2} alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutArea