import React from 'react'
import { blog_4, blog_5, blog_6, placeholder } from '../assets/assets2/images'
import { FaCalendarAlt, FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function BlogArea() {
    return (
        <section id="blog" className="blog-area blog-2 pt-120 pb-90">
            <div className="container">
                <div className="section-title">
                    <span className="tag">Latest Blog</span>
                    <h2 className="title">Take a look whats going on</h2>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <article className="item-single mb-30">
                            <div className="image">
                                <Link to="#" className="lazy-container aspect-ratio">
                                    <img className="lazyload lazy-image" src={placeholder} data-src={blog_4} alt="Blog" />
                                </Link>
                            </div>
                            <div className="content">
                                <ul className="info-list">
                                    <li><i><FaCalendarAlt /></i>08 Dec 2022</li>
                                    <li><i><FaUser /></i>Admin</li>
                                </ul>
                                <h3 className="title">
                                    <Link to="#">
                                        Most Suited For You to Get a personalized Quotation.
                                    </Link>
                                </h3>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <article className="item-single mb-30">
                            <div className="image">
                                <Link to="#" className="lazy-container aspect-ratio">
                                    <img className="lazyload lazy-image" src={placeholder} data-src={blog_5} alt="Blog" />
                                </Link>
                            </div>
                            <div className="content">
                                <ul className="info-list">
                                    <li><i><FaCalendarAlt /></i>01 May 2022</li>
                                    <li><i><FaUser /></i>Harry</li>
                                </ul>
                                <h3 className="title">
                                    <Link to="#">
                                        How is Technology Working With New Things?
                                    </Link>
                                </h3>
                            </div>
                        </article>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <article className="item-single mb-30">
                            <div className="image">
                                <Link to="#" className="lazy-container aspect-ratio">
                                    <img className="lazyload lazy-image" src={placeholder} data-src={blog_6} alt="Blog" />
                                </Link>
                            </div>
                            <div className="content">
                                <ul className="info-list">
                                    <li><i><FaCalendarAlt /></i>13 Jun 2022</li>
                                    <li><i><FaUser /></i>Emma</li>
                                </ul>
                                <h3 className="title">
                                    <Link to="#">
                                        Top 10 Important Tips on IT Services & Design
                                    </Link>
                                </h3>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogArea