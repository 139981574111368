import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactSelect from 'react-select';
import { addBoard } from '../../../../features/boardSlice';
import { TaskboardVisibility } from '../../../../constant';
import { useSelector } from 'react-redux';

const schema = yup
    .object({
        name: yup.string().required("Title is required").trim(),
        visibility: yup.string().required("Visibility is required").trim(),
    })
    .required();

function AddBoardModal(props) {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const { isLoading } = useSelector(state => state.taskBoard);

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('name', data?.name);
        formData.append('visibility', data?.visibility);
        formData.append('createdBy', user?.userIdentifier);
        dispatch(addBoard(formData, user?.userIdentifier, reset));
        props.toggleAddBoardModal();
    };

    return (
        <>
            <Modal
                isOpen={props.addBoardModalOpen}
                toggle={() => props.toggleAddBoardModal()}>
                <ModalHeader
                    toggle={() => props.toggleAddBoardModal()}>
                    Create Board
                </ModalHeader>
                <ModalBody>
                    {!isLoading ? (
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label>Board Title</label>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`form-control  ${errors?.name ? "error-input" : ""}`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={""}
                                    />
                                    {errors?.name?.message ? <small>{errors?.name?.message}</small> : ""}
                                </div>
                                <div className="form-group">
                                    <label>Visibility</label>
                                    <Controller
                                        name="visibility"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <ReactSelect
                                                options={TaskboardVisibility}
                                                value={TaskboardVisibility.find(option => option.value === value)}
                                                onChange={selected => onChange(selected?.value)}
                                                isClearable
                                            />
                                        )}
                                        defaultValue={""}
                                    />
                                    {errors?.visibility?.message ? <small>{errors?.visibility?.message}</small> : ""}
                                </div>
                                <div className="form-group text-center mt-3">
                                    <button
                                        className="btn btn-primary account-btn btn-lg"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </>
                    ) : (
                        "Loading"
                    )}
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddBoardModal