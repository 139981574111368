import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CompanySize, CompanyType, emailrgx } from '../../../constant';
import ReactSelect from 'react-select';
import PhoneInput from 'react-phone-number-input';
import { Helmet } from 'react-helmet';
import Loader from '../../../components/loader/Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompany, updateCompany } from '../../../features/companySlice';
import { formatDate } from '../../../_helpers/common';

const schema = yup
    .object({
        email: yup.string().required("Email is required").matches(emailrgx, "Invalid Email").trim(),
        name: yup.string().required("Name is required").trim(),
        password: yup.string().min(6, "Password must be at least 6 characters").required("Password is required").trim(),
        address: yup.string(),
        country: yup.string(),
        contact: yup.string(),
        website: yup.string(),
        registrationNumber: yup.string().required(),
        taxIdentificationNumber: yup.string().required(),
        foundingDate: yup.date().transform((value, originalValue) => {
            if (!originalValue) return undefined;
            return value;
        })
            .typeError("Invalid Date")
            .required("Founding date is required"),
        businessType: yup.string(),
        companySize: yup.string(),
        ceo: yup.string(),
        founder: yup.string()
    })
    .required();

function UpdateCompany() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, company } = useSelector(state => state.company);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        dispatch(getCompany(id));
    }, [dispatch, id]);

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("email", data.email);
        formData.append("name", data.name);
        formData.append("address", data.address);
        formData.append("country", data.country);
        formData.append("contact", data.contact);
        formData.append("website", data.website);
        formData.append("businessType", data.businessType);
        formData.append("registrationNumber", data.registrationNumber);
        formData.append("taxIdentificationNumber", data.taxIdentificationNumber);
        formData.append("foundingDate", data.foundingDate.toISOString());
        formData.append("companySize", data.companySize);
        formData.append("ceo", data.ceo);
        formData.append("founder", data.founder);
        dispatch(updateCompany(id, formData, navigate));
    };
    return (
        <>
            {isLoading ? <Loader /> : (
                <>
                    <Helmet>
                        <title>Edit Company</title>
                        <meta name="description" content="Edit Company page" />
                    </Helmet>
                    <div className="account-content">
                        <div className="container">
                            <h3 className="account-title mb-4">Edit Company</h3>
                            <div className="modal-body">
                                {company ? (
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="fields-type mt-2">
                                                <h4>Basic Information:</h4>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.email ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="email"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.email}
                                                    />
                                                    {errors?.email?.message ? <small>{errors?.email?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <Controller
                                                        name="name"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.name ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.name}
                                                    />
                                                    {errors?.name?.message ? <small>{errors?.name?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="fields-type mt-2">
                                                <h4>Contact Information:</h4>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <Controller
                                                        name="address"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.address ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.address}
                                                    />
                                                    {errors?.address?.message ? <small>{errors?.address?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <Controller
                                                        name="country"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.country ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.country}
                                                    />
                                                    {errors?.country?.message ? <small>{errors?.country?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Contact Number</label>
                                                    <Controller
                                                        name="contact"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <PhoneInput
                                                                className={`form-control ${errors?.contact ? "error-input" : ""}`}
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                defaultCountry="IN"
                                                                value={value}
                                                                initialValueFormat="national"
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                        defaultValue={company?.contact}
                                                    />
                                                    {errors?.contact?.message ? <small>{errors?.contact?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Website</label>
                                                    <Controller
                                                        name="website"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.website ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.website}
                                                    />
                                                    {errors?.website?.message ? <small>{errors?.website?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="fields-type">
                                                <h4>Company Details:</h4>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Registration Number</label>
                                                    <Controller
                                                        name="registrationNumber"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.registrationNumber ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.registrationNumber}
                                                    />
                                                    {errors?.registrationNumber?.message ? <small>{errors?.registrationNumber?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Tax Identification Number</label>
                                                    <Controller
                                                        name="taxIdentificationNumber"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.taxIdentificationNumber ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.taxIdentificationNumber}
                                                    />
                                                    {errors?.taxIdentificationNumber?.message ? <small>{errors?.taxIdentificationNumber?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Founding Date</label>
                                                    <Controller
                                                        name="foundingDate"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.foundingDate ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="date"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={formatDate(company?.foundingDate)}
                                                    />
                                                    {errors?.foundingDate?.message ? <small>{errors?.foundingDate?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Business Type</label>
                                                    <Controller
                                                        name="businessType"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <ReactSelect
                                                                options={CompanyType}
                                                                value={CompanyType.find(option => option.value === value)}
                                                                onChange={selected => onChange(selected?.value)}
                                                                isClearable
                                                                isSearchable
                                                            />
                                                        )}
                                                        defaultValue={company?.businessType}
                                                    />
                                                    {errors?.businessType?.message ? <small>{errors?.businessType?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label>Company Size</label>
                                                    <Controller
                                                        name="companySize"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <ReactSelect
                                                                options={CompanySize}
                                                                value={CompanySize.find(option => option.value === value)}
                                                                onChange={selected => onChange(selected?.value)}
                                                                isClearable
                                                                isSearchable
                                                            />
                                                        )}
                                                        defaultValue={company?.companySize}
                                                    />
                                                    {errors?.companySize?.message ? <small>{errors?.companySize?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="fields-type mt-2">
                                                <h4>Key Personnel:</h4>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>CEO</label>
                                                    <Controller
                                                        name="ceo"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.ceo ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.ceo}
                                                    />
                                                    {errors?.ceo?.message ? <small>{errors?.ceo?.message}</small> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Founder</label>
                                                    <Controller
                                                        name="founder"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className={`form-control mb-0 ${errors?.founder ? "error-input mb-0" : ""
                                                                    }`}
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                        defaultValue={company?.founder}
                                                    />
                                                    {errors?.founder?.message ? <small>{errors?.founder?.message}</small> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group text-center mt-2">
                                            <button
                                                className="btn btn-primary account-btn"
                                                type="submit"
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </form>
                                ) : (
                                    <p>No company data found.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default UpdateCompany