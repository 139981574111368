import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import $ from "jquery";
import { useSelector } from 'react-redux';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getAllTaxTypes, getEstimate, updateEstimate } from '../../../features/estimateSlice';
import ReactSelect from 'react-select';
import { emailrgx } from '../../../constant';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllClients, getAllClientsforCompany } from '../../../features/clientSlice';
import { getAllProjects } from '../../../features/projectSlice';
import Loader from '../../../components/loader/Loader';
import { getAllCompanies } from '../../../features/companySlice';
import moment from 'moment/moment';

const schema = yup.object({
    billingAddress: yup.string().required('Billing Address is required').trim(),
    // clientId: yup.string(),
    createDate: yup.date().required('Estimate Date is required'),
    email: yup.string().required('Email is required').matches(emailrgx, 'Invalid Email').trim(),
    expirationDate: yup.date().required('Expiration Date is required'),
    otherInfo: yup.string(),
    tax: yup.string().required('Tax is required').trim(),
    title: yup.string().required('Title is required').trim(),
    discount: yup.number().required('Discount is required')
}).required();

function EditEstimate() {
    const id = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { estimate } = useSelector(state => state.estimates);
    const [overallTotal, setOverallTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [taxPercentage, setTaxPercentage] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const { companyClients } = useSelector(state => state.client);
    const { taxTypes } = useSelector(state => state.estimates);
    const { projects } = useSelector(state => state.project);
    const { companiesList } = useSelector(state => state.company);
    const { isLoading } = useSelector((state) => state.estimates);

    const state = {
        search: '',
        page: 1,
        pagesize: 10
    };

    console.log('estimate', estimate?.createDate);

    useEffect(() => {
        dispatch(getEstimate(id.estimateIdentifier));
    }, [dispatch, id.estimateIdentifier]);

    useEffect(() => {
        dispatch(getAllCompanies(state.search, state.page, state.pagesize))
    }, [dispatch, state.search, state.page, state.pagesize])

    useEffect(() => {
        if (estimate) {
            dispatch(getAllClientsforCompany(estimate?.company[0]?.companyIdentifier, state.search, state.page, state.pagesize));
        }
    }, [dispatch, estimate?.company[0].companyIdentifier, state.search, state.page, state.pagesize]);

    useEffect(() => {
        if (estimate) {
            dispatch(getAllProjects(state.search, state.page, state.pagesize, true, estimate?.company[0].companyIdentifier))
        }
    }, [dispatch, state.search, state.page, state.pagesize, true, estimate?.company[0].companyIdentifier]);


    const clientOptions = companyClients.map(client => ({
        value: client.clientIdentifier,
        label: client.clientName
    }));

    const taxOptions = taxTypes.map(type => ({
        value: type.id,
        label: type.taxName
    }));

    const projectOptions = projects.map(project => ({
        value: project.projectIdentifier,
        label: project.projectName
    }));

    const companyOptions = companiesList.map(company => ({
        value: company.companyIdentifier,
        label: company.name
    }));

    useEffect(() => {
        dispatch(getAllTaxTypes())
    }, [])

    const { register, formState: { errors }, handleSubmit, control, getValues, setValue, reset } = useForm({
        // defaultValues: {
        //     clientIdentifier: estimate?.clientIdentifier || '',
        //     createDate: estimate?.createDate ? moment(estimate.createDate).toDate() : '',
        //     expirationDate: estimate?.expirationDate ? moment(estimate.expirationDate).toDate() : '',
        //     billingAddress: estimate?.billingAddress || '',
        //     email: estimate?.email || '',
        //     tax: estimate?.tax || '',
        //     title: estimate?.title || '',
        //     discount: estimate?.discount || 0,
        //     items: estimate?.items || [{ title: '', description: '', unitPrice: 0, quantity: 0, subTotal: 0, itemIdentifier: '' }]
        // },
        resolver: yupResolver(schema),
    });

    const { fields, append, remove } = useFieldArray({ name: 'items', control });

    const selectedCompanyIdentifier = estimate?.company?.[0]?.companyIdentifier;
    // useEffect(() => {
    //     if (estimate) {
    //         setValue('clientIdentifier', estimate?.clientIdentifier);
    //         setValue('projectIdentifier', estimate?.projectIdentifier)
    //         setValue('companyIdentifier', selectedCompanyIdentifier);
    //         setValue('createDate', moment(estimate?.createDate).format('MMM DD, YYYY'));
    //         setValue('expirationDate', moment(estimate?.expirationDate).format('MMM DD, YYYY'));
    //         setValue('billingAddress', estimate?.billingAddress);
    //         setValue('clientAddress', estimate?.clientAddress);
    //         setValue('email', estimate?.email);
    //         setValue('tax', estimate?.tax);
    //         // setValue('title', estimate?.title);
    //         // setValue('discount', estimate?.discount);
    //         setValue('otherInfo', estimate?.otherInformation);
    //         setValue('items', estimate?.items || [{ title: '', description: '', unitPrice: 0, quantity: 0, subTotal: 0, itemIdentifier: '' }]);
    //     }
    // }, [estimate, setValue])

    useEffect(() => {
        calculateInitialTotals();
    }, [fields]);

    const calculateInitialTotals = () => {

        let initialOverallTotal = 0;
        fields.forEach((field, i) => {
            initialOverallTotal += parseFloat(getValues(`items[${i}].subTotal`)) || 0;
        });
        const initialTaxAmount = (initialOverallTotal * taxPercentage) / 100;
        const discountAmount = initialOverallTotal + initialTaxAmount;
        const actualDiscount = (discountAmount * discount) / 100;
        setOverallTotal(initialOverallTotal);
        setGrandTotal(initialOverallTotal + initialTaxAmount - actualDiscount);
    };

    const amountCalculation = (index, unitPrice, quantity) => {
        const subTotal = unitPrice * quantity || 0;

        setValue(`items[${index}].subTotal`, subTotal);
        let newOverallTotal = 0;

        // Calculate new overall total
        fields.forEach((field, i) => {
            newOverallTotal += parseFloat(getValues(`items[${i}].subTotal`)) || 0;
        });

        // Calculate tax amount based on the new overall total
        const taxAmount = (newOverallTotal * taxPercentage) / 100;
        setOverallTotal(newOverallTotal);

        // Update the total including tax
        const totalWithTax = newOverallTotal + taxAmount;
        setTotal(totalWithTax);

        // Recalculate and apply the discount
        const discountedAmount = (totalWithTax * discount) / 100;
        setGrandTotal(totalWithTax - discountedAmount);
    };


    const applyDiscount = (value) => {
        const discountValue = value !== null && value !== '' ? parseInt(value, 10) : 0;
        const discountedAmount = (total * discountValue) / 100;
        setGrandTotal(total - discountedAmount);
    };

    useEffect(() => {
        if ($('.select').length > 0) {
            $('.select').select2({
                minimumResultsForSearch: -1,
                width: '100%'
            });
        }
    });

    useEffect(() => {
        if (estimate) {
            const selectedTax = taxTypes.find(type => type.id === estimate.tax);
            setTaxPercentage(selectedTax?.taxRate || 0);
        }
    }, [estimate, taxTypes]);

    useEffect(() => {
        setDiscount(estimate?.discount);
    });



    const onsubmit = (data) => {
        data.grandTotal = grandTotal;
        data.overallTotal = overallTotal;
        const formData = {
            title: data.title,
            clientId: data.clientId,
            project: data.project,
            email: data.email,
            createDate: data.createDate.toISOString(),
            expirationDate: data.expirationDate.toISOString(),
            tax: data.tax,
            billingAddress: data.billingAddress,
            items: data.items,
            otherInfo: data?.otherInfo,
            discount: data?.discount,
            overAllTotal: overallTotal,
            grandTotal: grandTotal,
            companyIdentifier: data.companyIdentifier,
            estimateIdentifier: estimate?.estimateIdentifier,
            clientIdentifier: estimate?.ClientIdentifier
        }
        console.log('data', data);

        dispatch(updateEstimate(formData))
        navigate('/admin/all-estimates')
    };

    return (
        <>
            <Helmet>
                <title>Edit Estimate - HRMS Admin Template</title>
                <meta name="description" content="Login page" />
            </Helmet>
            {isLoading ? <Loader />
                :
                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="page-title">Edit Estimate</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/app/main/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Edit Estimate {estimate?.title}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-md-12">
                            <form>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <Controller
                                                name="title"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className={`form-control mb-0 ${errors?.title ? "error-input mb-0" : ""
                                                            }`}
                                                        type="text"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue={estimate?.title}
                                            />
                                            <small>{errors?.title?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Company</label>
                                            <Controller
                                                name='companyIdentifier'
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactSelect
                                                        options={companyOptions}
                                                        value={companyOptions.find(option => option.value === value)}
                                                        onChange={selected => {
                                                            onChange(selected?.value);
                                                        }}
                                                        isClearable
                                                        isSearchable
                                                        className="company-box"
                                                    />
                                                )}
                                                defaultValue={selectedCompanyIdentifier}
                                            />
                                            <small>{errors?.companyIdentifier?.message}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <label>Client</label>
                                        <Controller
                                            name="clientIdentifier"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <ReactSelect
                                                    options={clientOptions}
                                                    value={clientOptions.find(option => option.value === value)}
                                                    onChange={selected => onChange(selected?.value)}
                                                    isClearable
                                                    isSearchable
                                                    className="company-box"
                                                />
                                            )}
                                            defaultValue={estimate?.clientIdentifier}
                                        />
                                        <small>{errors?.clientId?.message}</small>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Project <span className="text-danger">*</span></label>
                                            <Controller
                                                name="projectIdentifier"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactSelect
                                                        options={projectOptions}
                                                        value={projectOptions.find(option => option.value === value)}
                                                        isClearable
                                                        isSearchable
                                                        className="company-box"
                                                        onChange={selected => {
                                                            onChange(selected?.value);
                                                        }}
                                                    />
                                                )}
                                                defaultValue={estimate?.projectIdentifier}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className={`form-control mb-0 ${errors?.title ? "error-input mb-0" : ""
                                                            }`}
                                                        type="text"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue={estimate?.email}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Tax</label>
                                            <Controller
                                                name='tax'
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactSelect
                                                        options={taxOptions}
                                                        value={taxOptions.find(option => option.value === value)}
                                                        onChange={selected => {
                                                            const selectedTax = taxTypes.find(type => type.id === selected?.value);
                                                            setTaxPercentage(selectedTax?.taxRate || 0); // Update tax percentage state
                                                            onChange(selected?.value); // Update the form control value
                                                        }}
                                                        isClearable
                                                        isSearchable
                                                        className="company-box"
                                                    />
                                                )}
                                                defaultValue={estimate?.tax}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Billing Address</label>
                                            <Controller
                                                name="billingAddress"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <textarea
                                                        className={`form-control ${errors?.otherInfo ? "error-input mb-0" : ""
                                                            }`}
                                                        rows={4}
                                                        type="date"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue={estimate?.billingAddress}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Client Address</label>
                                            <Controller
                                                name="clientAddress"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <textarea
                                                        className={`form-control ${errors?.otherInfo ? "error-input mb-0" : ""
                                                            }`}
                                                        rows={4}
                                                        type="date"
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                )}
                                                defaultValue={estimate?.clientAddress}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Estimate Date <span className="text-danger">*</span></label>
                                            <div>
                                                <Controller
                                                    name="createDate"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control mb-0 datetimepicker ${errors?.title ? "error-input mb-0" : ""
                                                                }`}
                                                            type="date"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue={estimate?.createDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label>Expiry Date <span className="text-danger">*</span></label>
                                            <div>
                                                <Controller
                                                    name="expirationDate"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control mb-0 datetimepicker ${errors?.title ? "error-input mb-0" : ""
                                                                }`}
                                                            type="date"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                    defaultValue={estimate?.expirationDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-white">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '20px' }}>#</th>
                                                        <th className="col-sm-2">Item</th>
                                                        <th className="col-md-6">Description</th>
                                                        <th style={{ width: '100px' }}>Unit Cost</th>
                                                        <th style={{ width: '80px' }}>Qty</th>
                                                        <th>Amount</th>
                                                        <th />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fields?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <input
                                                                    name={`title-${index}`}
                                                                    className="form-control"
                                                                    type="text"
                                                                    style={{ minWidth: '150px' }}
                                                                    {...register(`items[${index}].title`)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    style={{ minWidth: '150px' }}
                                                                    {...register(`items[${index}].description`)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    style={{ width: '100px' }}
                                                                    {...register(`items[${index}].unitPrice`)}
                                                                    onChange={(e) => {
                                                                        const unitPrice = parseFloat(e.target.value) || 0;
                                                                        const quantity = parseFloat(getValues(`items[${index}].quantity`)) || 0;
                                                                        amountCalculation(index, unitPrice, quantity);
                                                                    }}
                                                                    min="0"
                                                                    required
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    style={{ width: '80px' }}
                                                                    {...register(`items[${index}].quantity`)}
                                                                    onChange={(e) => {
                                                                        const quantity = parseFloat(e.target.value) || 0;
                                                                        const unitPrice = parseFloat(getValues(`items[${index}].unitPrice`)) || 0;
                                                                        amountCalculation(index, unitPrice, quantity);
                                                                    }}
                                                                    min="0"
                                                                    required
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    style={{ width: '120px' }}
                                                                    {...register(`items[${index}].subTotal`)}
                                                                    readOnly
                                                                />
                                                            </td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link text-danger font-18"
                                                                    title="Remove"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    <i className="fa fa-trash-o" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary"
                                                onClick={() => {
                                                    append({
                                                        name: "",
                                                        description: "",
                                                        unitPrice: 0,
                                                        quantity: 0,
                                                        subTotal: 0
                                                    });
                                                }}
                                            >
                                                Add
                                            </button>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-white">
                                                <tbody>
                                                    <tr>
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td className="text-end">Total</td>
                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>
                                                            {overallTotal}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5} className="text-end">Tax</td>
                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{taxPercentage.toFixed(2)} %</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5} className="text-end">
                                                            Discount %
                                                        </td>
                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>
                                                            <Controller
                                                                name="discount"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <input
                                                                        className={`form-control text-end`}
                                                                        type="number"
                                                                        value={value}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            applyDiscount(e.target.value);
                                                                        }}
                                                                        min="0"
                                                                        max="90"
                                                                        autoComplete="false"
                                                                    />
                                                                )}
                                                                defaultValue=""
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                            Grand Total
                                                        </td>
                                                        <td style={{ textAlign: 'right', paddingRight: '30px', fontWeight: 'bold', fontSize: '16px', width: '230px' }}>
                                                            $
                                                            {grandTotal.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Other Information</label>
                                                    <Controller
                                                        name="otherInfo"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <textarea
                                                                className={`form-control`}
                                                                rows={4}
                                                                type="date"
                                                                value={value}
                                                                onChange={onChange}
                                                                autoComplete="false"
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn m-r-10">Save &amp; Send</button>
                                    <button onClick={handleSubmit(onsubmit)} type='submit' className="btn btn-primary submit-btn">Save</button>
                                </div>
                            </form>
                        </div>
                    </div >
                </div >
            }
        </>
    )
}

export default EditEstimate