import React from 'react'
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { addTaskboardList } from '../../../../features/boardSlice';
import { useSelector } from 'react-redux';

function AddListModal(props) {

    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const { isLoading } = useSelector(state => state.taskBoard);

    const handleSubmit = async (event) => {
        event.preventDefault(event);
        const formData = new FormData(event.target);
        formData.append("boardIdentifier", props.boardId);
        formData.append("createdBy", user.userIdentifier);
        dispatch(addTaskboardList(formData, props.boardId));
        props.toggleAddListModal();
    };

    return (
        <>
            <Modal
                isOpen={props.addListModalOpen}
                toggle={() => props.toggleAddListModal()}
            >
                <ModalHeader toggle={() => props.toggleAddListModal()}>
                    Create New List
                </ModalHeader>
                <ModalBody>
                    {!isLoading && (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label> Name</label>
                                    <input name="listName" type="text" className="form-control" />
                                </div>
                                <div className="form-group task-board-color">
                                    <label> Color</label>
                                    <div className="board-color-list">
                                        <label className="board-control board-primary">
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="primary"
                                                defaultChecked
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label className="board-control board-success">
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="success"
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label className="board-control board-info">
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="info"
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label className="board-control board-purple">
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="purple"
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label className="board-control board-warning">
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="warning"
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label className="board-control board-danger">
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="danger"
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary btn-lg" type="Submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddListModal