import React, { useEffect, useState } from 'react'
import NavMenu from '../../../components/NavBars/NavMenu'
import Sidebar from '../../../components/SideBar/Sidebar'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { confirmAlert } from 'react-confirm-alert'
import { useDispatch, useSelector } from 'react-redux'
import { changeProjectPriority, changeProjectStatus, deleteProject, getAllProjects } from '../../../features/projectSlice'
import { Priorities, Status } from '../../../constant'
import moment from 'moment'

function AllProjectsList() {
    const dispatch = useDispatch();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const { projects, projectsCount } = useSelector(state => state.project);
    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 10
    })

    const allProjects = async () => {
        dispatch(getAllProjects(state.search, state.page, state.pagesize));
    };
    useEffect(() => {
        allProjects();
    }, [state.search, state.page, state.pagesize])

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleDelete = (id) => {
        dispatch(deleteProject(id));
    };

    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };
    
    const handlePriorityChange = (record, newPriority) => {
       dispatch(changeProjectPriority(record.projectIdentifier, newPriority, allProjects));
    }

    const handleStatusChange = (record, newStatus) => {
       dispatch(changeProjectStatus(record.projectIdentifier, newStatus, allProjects));
    }

    const formatDate = (date) => {
        return moment(date ? date : new Date()).format("yyyy-MM-DD");
    };
    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            sorter: (a, b) => a.projectName.length - b.projectName.length,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            sorter: (a, b) => a.clientName.length - b.clientName.length,
        },
        {
            title: 'Project Leader',
            dataIndex: 'ownerName',
            sorter: (a, b) => a.ownerName.length - b.ownerName.length,
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            sorter: (a, b) => a.companyName.length - b.companyName.length,
        },
        {
            title: 'DeadLine',
            dataIndex: 'endDate',
            render: (text, record) => (
                formatDate(text)
            ),
            sorter: (a, b) => a.endDate.length - b.endDate.length,
        },
        {
            title: "Priority",
            dataIndex: "priority",
            render: (text, record) => (
                <div className="dropdown action-label">
                    <Link
                        className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        to="#"
                    >
                        <i className={`fa fa-dot-circle-o text-${text === "High" ? "danger" : text === "Medium" ? "warning" : "success"}`} /> {text}
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                        {Priorities.map(({ label, value, faIcon }) => (
                            <Link
                                className="dropdown-item"
                                to="#"
                                key={value}
                                onClick={() => handlePriorityChange(record, value)}
                            >
                                <i className={faIcon} /> {label}
                            </Link>
                        ))}
                    </div>
                </div>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => (
                <div className="dropdown action-label text-center">
                    <Link
                        className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        to="#"
                    >
                        <i className={`fa fa-dot-circle-o text-${text === "Open" ? "info" : text === "Re-Opened" ? "info" : text === "On-Hold" ?
                            "danger" : text === "Closed" ? "success" : text === "InProgress" ? "success" : "danger"}`} /> {text}
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                        {Status.map(({ label, value, faIcon }) => (
                            <Link
                                className="dropdown-item"
                                to="#"
                                key={value}
                                onClick={() => handleStatusChange(record, value)}
                            >
                                <i className={faIcon} /> {label}
                            </Link>
                        ))}
                    </div>
                </div>
            ),
        },
        {
            title: "Action",
            render: (text, record) => (
                <div className="dropdown profile-action">
                    <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                        <Link
                            to={`/admin/update-project/${record.projectIdentifier}`}
                            className="dropdown-item"
                        >
                            <i className="fa fa-pencil m-r-5" /> Edit
                        </Link>
                        <Link
                            className="dropdown-item"
                            to={"#"}
                            onClick={() => {
                                handleDeleteClick(record.projectIdentifier);
                            }}
                        >
                            <i className="fa fa-trash-o m-r-5" /> Delete
                        </Link>
                    </div>
                </div>
            ),
        },
    ]
    return (
        <>
            <Helmet>
                <title>Projects List</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Project</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Projects</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <Link to="/admin
                                    
                                    
                                    /create-project" className="btn add-btn">
                                <i className="fa fa-plus" /> Add Project
                            </Link>
                            <div className="view-icons">
                                <Link to={`/projects`} className="grid-view btn btn-link active">
                                    <i className="fa fa-th" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row filter-row">
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus">
                            <input
                                type="text"
                                className="form-control floating"
                                onChange={(e) => setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' })}
                            />
                            <label className="focus-label">Search....</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <Table
                                className="table-striped"
                                pagination={{
                                    current: state.page,
                                    pageSize: state.pagesize,
                                    total: projectsCount,
                                    showTotal: (total, range) =>
                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                    onChange: (page, pageSize) =>
                                        setState({ ...state, page, pagesize: pageSize }),
                                }}
                                style={{ overflowX: "auto" }}
                                columns={columns}
                                dataSource={projects}
                                rowKey={(record) => record.projectIdentifier}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllProjectsList