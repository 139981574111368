import React from 'react'
import { choose, choose_bg, placeholder } from '../../../assets/assets2/images'
import { FaColumns, FaComments, FaSyncAlt } from 'react-icons/fa';

function ChooseArea() {
    return (
        <section id="choose" className="choose-area choose-2">
            <div className="container">
                <div className="row align-items-center gx-xl-5">
                    <div className="col-lg-5">
                        <div className="section-title">
                            <span className="tag">Why Choose This</span>
                            <h2 className="title">Optimized for speed</h2>
                            <p>We create custom design software for businesses of all sizes. Our software will help you take your business.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-sm-6">
                                <div className="item-single mb-30">
                                    <div className="icon blue bg-blue">
                                        <i><FaColumns /></i>
                                    </div>
                                    <div className="content">
                                        <h3>List & Board</h3>
                                        <p>To help improve your personal and your productivity Samu ties into your tools.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="item-single mb-30">
                                    <div className="icon green bg-green">
                                        <i><FaComments /></i>
                                    </div>
                                    <div className="content">
                                        <h3>Multiple Teams</h3>
                                        <p>To help improve your personal and your productivity Samu ties into your tools.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="item-single mb-30">
                                    <div className="icon red bg-red">
                                        <i><FaSyncAlt /></i>
                                    </div>
                                    <div className="content">
                                        <h3>No Page Loads</h3>
                                        <p>To help improve your personal and your productivity Samu ties into your tools.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="image image-right mb-30">
                            <div className="lazy-container aspect-ratio aspect-ratio-4-3">
                                <img className="lazyload lazy-image" src={placeholder} data-src={choose} alt="Choose" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Bg Shape */}
            <div className="bg-image">
                <img src={choose_bg} alt="wave shape" />
            </div>
        </section>
    )
}

export default ChooseArea