import React from 'react'
import { choose_bg, integration_33 } from '../../../assets/assets2/images'

function ChooseArea() {
    return (
        <>
            <section id="choose" className="choose-area choose-area-service choose-2">
                <div className="container-fluid p-0">
                    <div className="row align-items-center gx-xl-5">
                        <div className="col-lg-7">
                            <div className="image mb-30">
                                <div className="container aspect-ratio aspect-ratio-4-3 p-0">
                                    <img className="lazyload lazy-image" src={integration_33} data-src={integration_33} alt="Choose" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="section-title">
                                <span className="tag">Why Choose This</span>
                                <h2 className="title">Optimized for speed</h2>
                                <p>We create custom design software for businesses of all sizes. Our software will help you take your business.</p>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-12 col-sm-6">
                                    <div className="item-single mb-30">
                                        <div className="icon blue bg-blue">
                                            <i className="fal fa-columns"></i>
                                        </div>
                                        <div className="content">
                                            <h3>List & Board</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-6">
                                    <div className="item-single mb-30">
                                        <div className="icon green bg-green">
                                            <i className="fal fa-comments"></i>
                                        </div>
                                        <div className="content">
                                            <h3>Multiple Teams</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-6">
                                    <div className="item-single mb-30">
                                        <div className="icon red bg-red">
                                            <i className="fal fa-sync-alt"></i>
                                        </div>
                                        <div className="content">
                                            <h3>No Page Loads</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Bg Shape */}
                <div className="bg-image">
                    <img src={choose_bg} alt="wave shape" />
                </div>
            </section>
        </>
    )
}

export default ChooseArea