import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { getCompany } from '../../../features/companySlice'
import { useDispatch, useSelector } from 'react-redux'
import { companygenImg } from '../../../assets/img'
import EditCompanyModal from './EditCompanyModal'
import Loader from '../../../components/loader/Loader'
import EditCompanyPersonalInformationModel from './EditCompanyPersonalInformationModel'
import { ROLES } from '../../../constant';

function CompanyProfile() {
    const { companyId } = useParams();
    const dispatch = useDispatch();
    const { isLoading, company } = useSelector(state => state.company);
    const [editCompanyModal, setEditCompanyModal] = useState(false);
    const toggleEditCompanyModal = () => setEditCompanyModal(!editCompanyModal);
    const [editCompanyPersonlInfoModal, setEditCompanyPersonlInfoModal] = useState(false);
    const toggleEditCompanyPersonlInfoModal = () => setEditCompanyPersonlInfoModal(!editCompanyPersonlInfoModal);

    useEffect(() => {
        const reloadData = () => {
            dispatch(getCompany(companyId));
        };
        reloadData();
    }, [dispatch, companyId]);

    const user = JSON.parse(localStorage.getItem('user'));
    const role = user?.role;

    return (
        <>
            {company ? (
                <>
                    <Helmet>
                        <title>Company Profile - CHS Admin Template</title>
                        <meta name="description" content="Reactify Blank Page" />
                    </Helmet>
                    {isLoading ? <Loader /> : ""}
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h3 className="page-title">Profile</h3>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">Admin</Link>
                                        </li>
                                        <li className="breadcrumb-item active">Company</li>
                                    </ul>
                                </div>
                                <div className="col-auto float-end ml-auto">
                                    <Link to={ROLES.Admin ? `/admin/all-employees/${companyId}` : ROLES.Company ? `/company-employees/${companyId}` : null} className="btn add-btn">
                                        Employees
                                    </Link>
                                </div>
                                <div className="col-auto float-end ml-auto">
                                    <Link to={`/${role}/company-tickets/${companyId}`} className="btn add-btn">
                                        Tickets
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-18">
                                        <div className="profile-view">
                                            <div className="profile-img-wrap">
                                                <div className="profile-img">
                                                    <Link to="#">
                                                        <img style={{ objectFit: 'cover' }} alt="" src={company.userProfile ? company.userProfile : companygenImg} />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="profile-basic">
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="profile-info-left">
                                                            <h3 className="user-name m-t-0 mb-0">{company.name}</h3>
                                                            <h6 className="text-muted">WE SHAPE THE PERFECT SOLUTIONS</h6>
                                                            <div className="staff-id">Registration Number: {company.registrationNumber}</div>
                                                            <div className="small doj text-muted">Founding Date: {company.foundingDate}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <ul className="personal-info">
                                                            <li>
                                                                <div className="title">Phone:</div>
                                                                <div className="text">{company.contact ? company.contact : "N/A"}</div>
                                                            </li>
                                                            <li>
                                                                <div className="title">Email:</div>
                                                                <div className="text">{company.email}</div>
                                                            </li>
                                                            <li>
                                                                <div className="title">Address:</div>
                                                                <div className="text">{company.address ? company.address : "N/A"} </div>
                                                            </li>
                                                            <li>
                                                                <div className="title">Country:</div>
                                                                <div className="text">{company.country ? company.country : "N/A"} </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pro-edit"><Link to={"#"} className="edit-icon" onClick={() => setEditCompanyModal(true)}><i className="fa fa-pencil" /></Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <EditCompanyModal
                            company={company}
                            editCompanyModal={editCompanyModal}
                            toggleEditCompanyModal={toggleEditCompanyModal}
                            id={companyId}
                        />
                        <EditCompanyPersonalInformationModel
                            company={company}
                            editCompanyPersonlInfoModal={editCompanyPersonlInfoModal}
                            toggleEditCompanyPersonlInfoModal={toggleEditCompanyPersonlInfoModal}
                            id={companyId}
                        />

                        <div className="card tab-box">
                            <div className="row user-tabs">
                                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                                    <ul className="nav nav-tabs nav-tabs-bottom">
                                        <li className="nav-item">
                                            <a
                                                href="#emp_profile"
                                                data-bs-toggle="tab"
                                                className="nav-link active"
                                            >
                                                Profile
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div
                                id="emp_profile"
                                className="pro-overview tab-pane fade show active"
                            >
                                <div className="row">
                                    <div className="col d-flex">
                                        <div className="card profile-box flex-fill">
                                            <div className="card-body">
                                                <h3 className="card-title">Personal Informations <Link to={"#"} className="edit-icon" onClick={() => setEditCompanyPersonlInfoModal(true)}><i className="fa fa-pencil" /></Link></h3>
                                                <ul className="personal-info">
                                                    <li>
                                                        <div className="title">CEO</div>
                                                        <div className="text">{company.ceo}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Founder</div>
                                                        <div className="text">{company.founder}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Business Type</div>
                                                        <div className="text">{company.businessType}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Company Size</div>
                                                        <div className="text">{company.companySize}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">TaxIdentification Number</div>
                                                        <div className="text">{company.taxIdentificationNumber}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Website</div>
                                                        <div className="text">{company.website}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col d-flex">
                                        <div className="card profile-box flex-fill">
                                            <div className="card-body">
                                                <h3 className="card-title">Emergency Contact <Link to={"#"} className="edit-icon" onClick={() => setEditCompanyPersonlInfoModal(true)}><i className="fa fa-pencil" /></Link></h3>
                                                <ul className="personal-info">
                                                    <li>
                                                        <div className="title">Primary
                                                        </div>
                                                        <div className="text">{company.ceo}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Name
                                                        </div>
                                                        <div className="text">{company.founder}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Relationship</div>
                                                        <div className="text">{company.businessType}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Phone</div>
                                                        <div className="text">{company.companySize}</div>
                                                    </li>
                                                </ul>
                                                <ul className='personal-info'>
                                                    <li>
                                                        <div className="title">Secondry
                                                        </div>
                                                        <div className="title">Relationship</div>
                                                        <div className="text">{company.taxIdentificationNumber}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Name</div>
                                                        <div className="text">{company.website}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Phone</div>
                                                        <div className="text">{company.website}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col- d-flex">
                                        <div className="card profile-box flex-fill">
                                            <div className="card-body">
                                                <h3 className="card-title">Bank Information <Link to={"#"} className="edit-icon" onClick={() => setEditCompanyPersonlInfoModal(true)}><i className="fa fa-pencil" /></Link></h3>
                                                <ul className="personal-info">
                                                    <li>
                                                        <div className="title">Bank Name
                                                        </div>
                                                        <div className="text">{company.ceo}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Bank account No.
                                                        </div>
                                                        <div className="text">{company.founder}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">IFSC Code
                                                        </div>
                                                        <div className="text">{company.founder}</div>
                                                    </li>
                                                    <li>
                                                        <div className="title">PAN No.</div>
                                                        <div className="text">{company.businessType}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 d-flex">
                                        <div className="card profile-box flex-fill">
                                            <div className="card-body">
                                                <h3 className="card-title">Family Information <Link to={"#"} className="edit-icon" onClick={() => setEditCompanyPersonlInfoModal(true)}><i className="fa fa-pencil" /></Link></h3>
                                                <table>
                                                    <thead style={{ verticalAlign: 'bottom' }}>
                                                        <tr>
                                                            <th className='table_design'>Name</th>
                                                            <th className='table_design'>Relationship</th>
                                                            <th className='table_design'>Date of Birth</th>
                                                            <th className='table_design'>Phone</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ verticalAlign: 'bottom' }}>
                                                        <tr>
                                                            <td className='table_design'>John Doe</td>
                                                            <td className='table_design'>Brother</td>
                                                            <td className='table_design'>Feb 16th, 2019	</td>
                                                            <td className='table_design'>78787878899</td>
                                                            <td className='table_design'><BsThreeDotsVertical style={{ color: 'black' }} /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : ("")
            }
        </>
    )
}

export default CompanyProfile