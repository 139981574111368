import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/assets2/fonts/fontawesome/css/all.min.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "select2/dist/css/select2.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./assets/assets2/css/responsive.css";
import "./assets/assets2/css/style.css";
import "jquery/dist/jquery.min.js";
import "select2/dist/js/select2.min.js";
import "lazysizes/lazysizes.min.js";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./assets/css/style.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { store } from "./redux/store";
import { AuthProvider } from "./context/AuthProvider";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { router } from "./routing";
import { Toaster } from "react-hot-toast";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
    <Toaster position="top-right" />
  </Provider>
);
