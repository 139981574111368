import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import "../../../index.css";
import { Link } from "react-router-dom";
import { genericImg } from "../../../assets/img";
import { getAdminDashboardInfo } from "../../../features/masterSlice";
import Loader from '../../../components/loader/Loader';
import { confirmAlert } from "react-confirm-alert";
import { deleteClient, getClient } from "../../../features/clientSlice";
import { getProject } from "../../../features/projectSlice";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { projectsCount, employeesCount, clientsCount, companiesCount, clients, projects, isLoading } = useSelector(state => state.master);

  useEffect(() => {
    dispatch(getAdminDashboardInfo());
  }, [dispatch])

  const handleClientDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleClientDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleClientDelete = (id) => {
    dispatch(deleteClient(id));
  };
  const handleClientUpdateClick = (id) => {
    dispatch(getClient(id));
  }
  const handleProjectDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleProjectDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleProjectDelete = (id) => {
    dispatch(deleteClient(id));
  };
  const handleProjectUpdateClick = (id) => {
    dispatch(getProject(id));
  }
  return (
    <>
      {isLoading ? <Loader /> : <>
        <Helmet>
          <title>Admin - CHS</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Welcome Admin!</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <i className="fa fa-diamond" />
                  </span>
                  <div className="dash-widget-info">
                    <h3>{companiesCount}</h3>
                    <span>Companies</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <i className="fa fa-usd" />
                  </span>
                  <div className="dash-widget-info">
                    <h3>{employeesCount}</h3>
                    <span>Employees</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <i className="fa fa-user" />
                  </span>
                  <div className="dash-widget-info">
                    <h3>{clientsCount}</h3>
                    <span>Clients</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <i className="fa fa-cubes" />
                  </span>
                  <div className="dash-widget-info">
                    <h3>{projectsCount}</h3>
                    <span>Projects</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card card-table flex-fill">
                <div className="card-header">
                  <h3 className="card-title mb-0">Clients</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Country</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clients.map((client) => (
                          <tr>
                            <td>
                              <h2 className="table-avatar">
                                <Link to={`#`} className="avatar"><img alt="" src={genericImg} /></Link>
                                <Link to={`#`}>{client.clientName} <span>{client.country}</span></Link>
                              </h2>
                            </td>
                            <td>{client.clientEmail}</td>
                            <td>{client.country}</td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link onClick={() => handleClientUpdateClick(client.clientIdentifier)} className="dropdown-item" to={`/admin/update-client/${client.clientIdentifier}`}><i className="fa fa-pencil m-r-5" /> Edit</Link>
                                  <Link onClick={() => handleClientDeleteClick(client.clientIdentifier)} className="dropdown-item" to="#"><i className="fa fa-trash-o m-r-5" /> Delete</Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/admin/clients">View all clients</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card card-table flex-fill">
                <div className="card-header">
                  <h3 className="card-title mb-0">Recent Projects</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table custom-table mb-0">
                      <thead>
                        <tr>
                          <th>Project Name </th>
                          <th>Status</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects.map((project) => (
                          <tr>
                            <td>
                              <h2><Link to="#">{project.projectName}</Link></h2>
                              <small className="block text-ellipsis">
                                <span>1</span> <span className="text-muted">open tasks, </span>
                                <span>9</span> <span className="text-muted">tasks completed</span>
                              </small>
                            </td>
                            <td>{project.status}</td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link onClick={() => handleProjectUpdateClick(project.projectIdentifier)} className="dropdown-item" to={`/admin/update-project/${project.projectIdentifier}`}><i className="fa fa-pencil m-r-5" /> Edit</Link>
                                  <Link onClick={() => handleProjectDeleteClick(project.projectIdentifier)} className="dropdown-item" to="#"><i className="fa fa-trash-o m-r-5" /> Delete</Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/admin/projects-list">View all projects</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      }
    </>
  );
};

export default AdminDashboard;
