import React from 'react'
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { addTask } from '../../../../features/boardSlice';

function AddTaskModal(props) {

    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));

    const handleSubmit = async (event) => {
        event.preventDefault(event);
        const formData = new FormData(event.target);
        formData.append('listIdentifier', props.listID);
        formData.append('createdBy', user?.userIdentifier);
        dispatch(addTask(formData, props.toggleAddTaskModal, props.boardId));
    }
    return (
        <>
            <Modal isOpen={props.addTaskModalOpen}
                toggle={() => props.toggleAddTaskModal()}
            >
                <ModalHeader toggle={() => props.toggleAddTaskModal()}>
                    Create New Task
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Task Name</label>
                            <input
                                name="title"
                                type="text"
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Task Description</label>
                            <input name="description" type="text" className="form-control" />
                        </div>
                        <div className="submit-section text-center">
                            <button className="btn btn-primary submit-btn" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddTaskModal