import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteTax, getAllTax, statusUpdate } from '../../../features/taxSlice';
import { Table } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../components/loader/Loader';

export default function AllTax() {
        const dispatch = useDispatch();
        const { allTax, count } = useSelector((state) => state.tax);
        const [index, setIndex] = useState(1);
        const { isLoading } = useSelector((state) => state.tax);

        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10
        })

        useEffect(() => {
                dispatch(getAllTax());
        }, [dispatch]);

        useEffect(() => {
                setIndex((prev) => prev + 1)
        }, [])

        const handleDeleteClick = id => {
                confirmAlert({
                        title: 'Confirm to delete',
                        message: 'Are you sure to do this.',
                        buttons: [
                                {
                                        label: 'Yes',
                                        onClick: () => handleDelete(id),
                                },
                                {
                                        label: 'No',
                                },
                        ],
                });
        };

        const handleDelete = id => {
                dispatch(deleteTax(id));
        }

        const handleStatusChange = (newStatus, id) => {
                dispatch(statusUpdate(newStatus, id));
        };

        const onShowSizeChange = (current, pageSize) => {
                setState({ ...state, page: 1, pagesize: pageSize });
        };

        const itemRender = (current, type, originalElement) => {
                if (type === 'prev') {
                        return <button className="btn btn-sm btn-primary">Previous</button>;
                }
                if (type === 'next') {
                        return <button className="btn btn-sm btn-primary">Next</button>;
                }
                return originalElement;
        };

        const columns = [
                {
                        title: '#',
                        render: (text, record, index) => (
                                <span>{index + 1}</span>
                        ),
                        sorter: (a, b) => a.id - b.id
                },
                {
                        title: 'Tax Name',
                        dataIndex: 'taxName',
                        render: (text, record) => <span>{record.taxName}</span>,
                        sorter: (a, b) => a.taxName.length - b.taxName.length
                },
                {
                        title: 'Tax Percentage',
                        dataIndex: 'taxRate',
                        render: (text, record) => <span>{record.taxRate}</span>,
                        sorter: (a, b) => a.taxRate - b.taxRate
                },
                {
                        title: 'Status',
                        dataIndex: 'isActive',
                        render: (text, record) => (
                                <div className="dropdown action-label">
                                        <a
                                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                href="#"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="true"
                                        >
                                                <i className={`fa fa-dot-circle-o ${record.isActive ? 'text-success' : 'text-danger'}`}></i>
                                                {record.isActive ? 'Active' : 'Inactive'}
                                        </a>
                                        <div className="dropdown-menu">
                                                <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleStatusChange(true, record.taxIdentifier)}

                                                >
                                                        <i className="fa fa-dot-circle-o text-success"></i> Active
                                                </span>
                                                <span onClick={() => handleStatusChange(false, record.taxIdentifier)}
                                                        className="dropdown-item" style={{ cursor: 'pointer' }} >
                                                        <i className="fa fa-dot-circle-o text-danger"></i> Inactive
                                                </span>
                                        </div>
                                </div>
                        ),
                },
                {
                        title: "Action",
                        render: (text, record) => (
                                <div
                                        className="d-flex justify-content-around"
                                        data-popper-placement="bottom-end"
                                >
                                        <Link
                                                className="dropdown-item px-2 text-success"
                                                to={`/admin/edit-tax/${record.taxIdentifier}`}
                                        >
                                                <i className="fa fa-pencil mr-2" />
                                        </Link>
                                        <Link
                                                className="dropdown-item px-2 text-danger"
                                                to={"#"}
                                                onClick={() => {
                                                        handleDeleteClick(record.taxIdentifier);
                                                }}
                                        >
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];

        return (
                <div className="content container-fluid">
                        <div className="page-header">
                                <div className="row align-items-center">
                                        <div className="col">
                                                <h3 className="page-title">Taxes</h3>
                                                <ul className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="admin-dashboard.html">Dashboard</a></li>
                                                        <li className="breadcrumb-item active">Taxes</li>
                                                </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                                <Link to="/admin/create-tax" className="btn add-btn">
                                                        <i className="fa fa-plus"></i> Add Tax
                                                </Link>
                                        </div>
                                </div>
                        </div>
                        {isLoading ? <Loader /> :
                                <div className="row">
                                        <div className="col-md-12">
                                                <div className="table-responsive">
                                                        <Table
                                                                columns={columns}
                                                                dataSource={Array.isArray(allTax?.data) ? allTax.data : []}
                                                                rowKey={(record) => record?.taxIdentifier}
                                                                pagination={{
                                                                        current: state.page,
                                                                        pageSize: state.pagesize,
                                                                        total: count,
                                                                        showTotal: (total, range) =>
                                                                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                        showSizeChanger: true,
                                                                        onShowSizeChange: onShowSizeChange,
                                                                        itemRender: itemRender,
                                                                        onChange: (page, pageSize) =>
                                                                                setState({ ...state, page, pagesize: pageSize }),
                                                                }}
                                                        />
                                                </div>
                                        </div>
                                </div>
                        }
                </div>
        );
}
