import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../../constant";
import { registerUser } from "../../features/authRegisterSlice";
import { logo_2 } from "../../assets/assets2/images";
import { useSelector } from "react-redux";
import Loader from "../loader/Loader";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .matches(emailrgx, "Invalid Email")
      .trim(),
    name: yup.string().required("Name is required").trim(),
    password: yup.string().min(6).required("Password is required").trim(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Password must match")
      .required("ConfirmPassword is required")
      .trim(),
  })
  .required();

const RegisterForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.userRegister);
  const [eye, seteye] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onEyeClick = () => {
    seteye(!eye);
  };

  const onSubmit = async (data) => {
    dispatch(registerUser(data, navigate));
  };

  return (
    <>
      <Helmet>
        <title>Register - CHS</title>
        <meta name="description" content="Login page" />
      </Helmet>
      {isLoading ? <Loader /> : ""}
      <div className="account-content">
        <div className="container">
          <div className="account-logo">
            <Link to="#">
              <img src={logo_2} alt="CHS" style={{ width: "250px" }} />
            </Link>
          </div>
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">Register</h3>
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label>Email</label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.email ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.email?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>Name</label>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.name ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.name?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="pass-group">
                          <input
                            type={eye ? "password" : "text"}
                            className={`form-control  ${
                              errors?.password ? "error-input" : ""
                            }`}
                            onChange={onChange}
                            autoComplete="false"
                          />
                          <span
                            onClick={onEyeClick}
                            className={`fa toggle-password" ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.password?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="pass-group">
                          <input
                            type={"password"}
                            className={`form-control  ${
                              errors?.confirmPassword ? "error-input" : ""
                            }`}
                            onChange={onChange}
                            autoComplete="false"
                          />
                        </div>
                      )}
                      defaultValue=""
                    />
                    <small>{errors?.confirmPassword?.message}</small>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Register
                    </button>
                  </div>
                </form>
                <div className="account-footer" style={{ marginTop: "20px" }}>
                  <p>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RegisterForm;
