import React from 'react'
import { banner_1, banner_bg_2, circle, rectengle, triangle } from '../../assets/assets2/images'
import { Link } from 'react-router-dom'
import { FaPlayCircle } from 'react-icons/fa'
import FeaturesArea from './components/featuresArea'
import IntegrationArea from './components/integrationArea'
import OverviewArea from '../../components/OverviewArea'
import PricingArea from '../../components/PricingArea'
import BlogArea from '../../components/BlogArea'

function WhoWeAre() {
    return (
        <>
            <div className="home-banner-area banner-bg banner-2 overflow-visible">
                <div className="bg-shape bg-shape-1">
                    <img src={banner_bg_2} alt="banner-bg-2" />
                </div>
                <div className="container">
                    <div className="banner-content text-center mb-50">
                        <h1 className="title">Awesome Landing for Your Software &amp; App</h1>
                        <p className="text text-black mx-auto">Samu lets you manage software development and track bugs. Samu's streamlined design is built for speed and efficiency helping high performing team</p>
                        <div className="cta-btns">
                            <Link to="/contact" className="btn primary-btn" style={{ color: 'white' }}>Try it Free for 7 Days</Link>
                            <Link to="#" className="btn secondary-btn youtube-popup"><i><FaPlayCircle /></i> Watch Video</Link>
                        </div>
                        <div className="banner-image image-bottom">
                            <img src={banner_1} alt="Banner Background" />
                        </div>
                    </div>
                </div>
                <div className="shape">
                    <img className="shape-1" src={triangle} alt="Shape" />
                    <img className="shape-2" src={circle} alt="Shape" />
                    <img className="shape-3" src={rectengle} alt="Shape" />
                    <img className="shape-4" src={rectengle} alt="Shape" />
                    <img className="shape-5" src={circle} alt="Shape" />
                    <img className="shape-6" src={triangle} alt="Shape" />
                </div>
            </div>

            <FeaturesArea />
            <IntegrationArea />
            <OverviewArea />
            <PricingArea />
            <BlogArea />
        </>
    )
}

export default WhoWeAre