import { createSlice } from '@reduxjs/toolkit';
import httpHandler from '../utils/httpHandler';
import { toast } from 'react-hot-toast';
import { ROLES } from '../constant';

const employeeSlice = createSlice({
    name: 'employee',
    initialState: {
        employees: [],
        companyEmployees: [],
        isLoading: false,
        isAdded: false,
        employee: null,
        assignee: [],
        allEmployees: [],
        companyEmployeesCount : null,
    },
    reducers: {
        getEmployeesSuccess: (state, action) => {
            state.employees = action.payload.data;
            state.isLoading = false;
        },
        getEmployeesFailure: (state, action) => {
            state.employees = [];
            state.isLoading = false;
        },
        getCompanyEmployeesSuccess: (state, action) => {
            state.companyEmployees = action.payload.data;
            state.companyEmployeesCount = action.payload.count;
            state.isLoading = false;
        },
        getCompanyEmployeesFailure: (state, action) => {
            state.companyEmployees = [];
            state.isLoading = false;
        },
        getAllEmployeesforAssigneeSuccess: (state, action) => {
            state.assignee = action.payload.data;
            state.isLoading = false;
        },
        getAllEmployeesforAssigneeFailure: (state, action) => {
            state.assignee = [];
            state.isLoading = false;
        },
        addEmployeeSuccess: (state, action) => {
            state.isLoading = false;
        },
        addEmployeeFailure: (state, action) => {
            state.isLoading = false;
        },
        getEmployeeSuccess: (state, action) => {
            state.employee = action.payload;
            state.isLoading = false;
        },
        getEmployeeFailure: (state, action) => {
            state.employee = null;
            state.isLoading = false;
        },
        updateEmployeeSuccess: (state, action) => {
            state.isLoading = false;
        },
        updateEmployeeFailure: (state, action) => {
            state.isLoading = false;
        },
        deleteEmployeeSuccess: (state, action) => {
            const employeeId = action.payload.id;
            state.employees = state.employees.filter(employee => employee.employeeIdentifier !== employeeId);
            state.isLoading = false;
        },
        deleteEmployeeFailure: (state, action) => {
            state.isLoading = false;
        },
        allEmployeesListSuccess: (state, action) => {
            state.allEmployees = action.payload.data;
            state.isLoading = false;
        },
        allEmployeesListFailure: (state, action) => {
            state.allEmployees = [];
            state.isLoading = false;
        },
        setLoading: (state, action) => {
            state.isLoading = true;
        },
    },
});

export const getAllEmployees = (search, page, pagesize) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/employee/getAllEmployees?search=${search}&page=${page}&pagesize=${pagesize}`);
        if (response.data.data.length > 0) {
            dispatch(getEmployeesSuccess(response.data));
        }
        else {
            dispatch(getEmployeesFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(getEmployeesFailure());
    }
};

export const getAllEmployeesforCompany = (search, companyId, page, pagesize) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/employee/getAllEmployees?search=${search}&companyId=${companyId}&page=${page}&pagesize=${pagesize}`);
        if (response.data.success) {
            dispatch(getCompanyEmployeesSuccess(response.data));
        }
        else {
            dispatch(getCompanyEmployeesFailure());
            toast.error(response.data.message);
        }
    } catch (error) {
        dispatch(getCompanyEmployeesFailure(error));
        toast.error(error.message);
    }
};

export const addEmployee = (data, companyId, user, navigate) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/employee/createEmployee/${companyId}`, data);
        if (response.data.success && user.role === ROLES.Company) {
            toast.success(response.data.message);
            navigate(`/company/company-employees/${companyId}`);
            dispatch(addEmployeeSuccess(response.data));
        }
        else if (response.data.success && user.role === ROLES.Admin) {
            toast.success(response.data.message);
            navigate(`/admin/all-employees`);
            dispatch(addEmployeeSuccess(response.data));
        }
        else {
            dispatch(addEmployeeFailure());
            toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(addEmployeeFailure());
    }
};

export const getEmployee = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/employee/getEmployee/${id}`);
        dispatch(getEmployeeSuccess(response.data));
    } catch (error) {
        dispatch(getEmployeeFailure(error));
    }
};

export const updateEmployee = (id, data, navigate) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        await httpHandler.post(`/api/employee/updateEmployee/${id}`, data);
        toast.success("Updated Successfully");
        navigate(`/company/employee-profile/${id}`)
        dispatch(updateEmployeeSuccess());
    } catch (error) {
        toast.error(error.message);
        dispatch(updateEmployeeFailure(error));
    }
};

export const deleteEmployee = (id, companyId, company) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        await httpHandler.post(`/api/employee/deleteEmployee/${id}`);
        toast.success("Deleted Successfully");
        dispatch(deleteEmployeeSuccess({ id }));
        var search = "";
        if (company === true) {
            dispatch(getAllEmployeesforCompany(search, companyId, 1, 10));
        }
        else {
            dispatch(getAllEmployees(search, 1, 10));
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(deleteEmployeeFailure(error));
    }
};

export const allEmployeesList = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        var result = await httpHandler.get(`/api/employee/allEmployeesList/${id}`);
        if (result.data.success)
            dispatch(allEmployeesListSuccess(result.data));
        else
            dispatch(allEmployeesListFailure());
    } catch (error) {
        dispatch(allEmployeesListFailure());
    }
}
export const { getEmployeesSuccess, getEmployeesFailure, getCompanyEmployeesSuccess, getCompanyEmployeesFailure, addEmployeeSuccess,
    addEmployeeFailure, getEmployeeSuccess, getEmployeeFailure, updateEmployeeSuccess, updateEmployeeFailure, deleteEmployeeSuccess,
    deleteEmployeeFailure, getAllEmployeesforAssigneeSuccess, getAllEmployeesforAssigneeFailure, allEmployeesListSuccess, allEmployeesListFailure, setLoading } = employeeSlice.actions;
export default employeeSlice.reducer;