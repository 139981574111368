import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, } from "react-redux";
import NavMenu from "../NavBars/NavMenu";
import Sidebar from "../SideBar/Sidebar";
import Loader from "../loader/Loader";
import { useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import ReactSelect from "react-select";
import { allCompaniesList } from "../../features/companySlice";
import { addDepartment } from "../../features/departmentSlice";
import { allEmployeesList } from "../../features/employeeSlice";

const schema = yup
    .object({
        departmentName: yup.string().required("Name is required").trim(),
        departmentHead: yup.string().required("Department Head is required"),
        departmentDescription: yup.string().required("Discription is required").trim(),
        company: yup.string().required("Company is required"),
    })
    .required();

function AddDepartmentForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);
    const [departmentHeadEnabled, setDepartmentHeadEnabled] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const { isLoading } = useSelector(state => state.department);
    const { allCompanies } = useSelector(state => state.company);
    const { allEmployees } = useSelector(state => state.employee);

    useEffect(() => {
        dispatch(allCompaniesList());
    }, [dispatch]);

    const companyOptions = allCompanies.map((company) => {
        return {
            value: company.companyIdentifier,
            label: company.name,
        };
    });
    const employeeOptions = allEmployees.map((employee) => {
        return {
            value: employee.employeeIdentifier,
            label: employee.name,
        };
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const requestData = {
            departmentName: data.departmentName,
            departmentHead: data.departmentHead,
            departmentDescription: data.departmentDescription,
            company: data.company,
        };
        dispatch(addDepartment(requestData, navigate));
    }

    const handleCompanyChange = (selectedCompany) => {
        if (selectedCompany) {
            dispatch(allEmployeesList(selectedCompany.value));
            setDepartmentHeadEnabled(true);
        } else {
            setDepartmentHeadEnabled(false);
            reset({ departmentHead: '' });
        };
    }

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <NavMenu onMenuClick={(value) => toggleMobileMenu()} />
                <Sidebar />
                <div className="page-wrapper" style={{ display: "block" }}>
                    <Helmet>
                        <title>Add Department</title>
                        <meta name="description" content="Add department page" />
                    </Helmet>
                    {isLoading ? <Loader /> : ("")}
                    <div className="account-content">
                        <div className="container">
                            <div>
                                <div className="account-wrapper">
                                    <h3 className="account-title">Add Department</h3>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="fields-type">
                                                    <h4>Basic Information:</h4>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Company</label>
                                                        <Controller
                                                            name="company"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <ReactSelect
                                                                    options={companyOptions}
                                                                    value={companyOptions.find(option => option.value === value)}
                                                                    onChange={(selected) => {
                                                                        onChange(selected?.value);
                                                                        handleCompanyChange(selected);
                                                                    }}
                                                                    isClearable
                                                                    isSearchable
                                                                    className="company-box"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.company?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Department Name</label>
                                                        <Controller
                                                            name="departmentName"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control mb-0 ${errors?.departmentName ? "error-input mb-0" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.departmentName?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Department Head</label>
                                                        <Controller
                                                            name="departmentHead"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <ReactSelect
                                                                    options={employeeOptions}
                                                                    value={employeeOptions.find(option => option.value === value)}
                                                                    onChange={selected => onChange(selected?.value)}
                                                                    isClearable
                                                                    isSearchable
                                                                    isDisabled={!departmentHeadEnabled}
                                                                    className="company-box"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.company?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Discription</label>
                                                        <Controller
                                                            name="departmentDescription"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control mb-0 ${errors?.departmentDescription ? "error-input mb-0" : ""
                                                                        }`}
                                                                    type="textarea"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.departmentDescription?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="form-group text-center">
                                                    <button
                                                        className="btn btn-primary account-btn"
                                                        type="submit"
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddDepartmentForm;