import React from 'react'
import HomeNav from '../NavBars/HomeNav'
import { Outlet } from 'react-router-dom'
import FooterArea from '../FooterArea'

function HomeLayout() {
    return (
        <>
            <HomeNav />
            <Outlet />
            <FooterArea />
        </>
    )
}

export default HomeLayout