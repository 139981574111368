import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllInvoice } from '../../../features/invoiceSlice';
import $ from 'jquery';

function Invoices() {
    const dispatch = useDispatch();
    const { allInvoice, isLoading } = useSelector(state => state.invoice);
    const [state, setState] = useState({
        search: '',
        page: 1,
        pagesize: 10,
        status: '',
    });

    useEffect(() => {
        dispatch(getAllInvoice(state.search, state.page, state.pagesize));
    }, [dispatch, state.search, state.page, state.pagesize]);

    console.log('allInvoice', allInvoice);
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    // Handle delete confirmation
    const handleDeleteClick = id => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id),
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    // Handle actual delete action
    const handleDelete = id => {
        // dispatch(deleteEstimate(id));
    };

    // Handle filter input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    // Filter estimate
    const filterEstimate = () => {
        dispatch(getAllInvoice(state.search, state.page, state.pagesize, state.fromDate, state.toDate, state.status, '2be1932c-2345-43'));
    };


    // Handle pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    // Custom pagination item renderer
    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };

    // Table columns
    const columns = [
        {
            title: '#',
            render: (text, record, index) => <Link to="/admin/estimate-veiw">{index}</Link>,
        },
        {
            title: 'Client',
            dataIndex: 'title',
            render: (text, record) => <Link to="/admin/estimate-veiw">{text}</Link>,
        },
        {
            title: 'Created Date',
            dataIndex: 'createDate',
            render: (text) => formatDate(text),
        },
        {
            title: 'Due Date',
            dataIndex: 'expirationDate',
            render: (text) => formatDate(text),

        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (text, record) => <span>$ {text}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
                <span className={text === 'Accepted' ? 'badge bg-inverse-success' : 'badge bg-inverse-info'}>
                    {text}
                </span>
            ),
        },
        {
            title: 'Action',
            render: (text, record) => (
                <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
                    <Link className="dropdown-item px-2 text-success" to={`/admin/edit-invoice/${record.invoiceIdentifier}`}>
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => handleDeleteClick(record.invoiceIdentifier)}>
                        <i className="fa fa-trash-o m-r-5" />
                    </Link>
                </div>
            ),
        },
    ];

    // Initialize select2 on mount
    useEffect(() => {
        if ($('.select').length > 0) {
            $('.select').select2({
                minimumResultsForSearch: -1,
                width: '100%',
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Invoices</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Invoices</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/app/main/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Invoices</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <Link to="/admin/create-invoice" className="btn add-btn"><i className="fa fa-plus" /> Create Invoice</Link>
                        </div>
                    </div>
                </div>
                <div className="row filter-row">
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus">
                            <div>
                                <input className="form-control floating datetimepicker" type="date" />
                            </div>
                            <label className="focus-label">From</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus">
                            <div>
                                <input className="form-control floating datetimepicker" type="date" />
                            </div>
                            <label className="focus-label">To</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus">
                            <select className="select floating">
                                <option>Select Status</option>
                                <option>Pending</option>
                                <option>Paid</option>
                                <option>Partially Paid</option>
                            </select>
                            <label className="focus-label">Status</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a href="#" className="btn btn-success btn-block w-100"> Search </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <Table className="table-striped"
                                // pagination={{
                                //     total: data.length,
                                //     showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                //     showSizeChanger: true, onShowSizeChange: onShowSizeChange, itemRender: itemRender
                                // }}
                                style={{ overflowX: 'auto' }}
                                columns={columns}
                                dataSource={allInvoice}
                                rowKey={record => record.id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invoices;