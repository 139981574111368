import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader/Loader';
import { deleteBoard, getUserBoards } from '../../../features/boardSlice';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import AddBoardModal from './components/AddBoardModal';

const AllBoards = () => {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const { isLoading, userBoards } = useSelector(state => state.taskBoard);
    const [addBoardModalOpen, setAddBoardModalOpen] = useState(false);
    const toggleAddBoardModal = () => setAddBoardModalOpen(!addBoardModalOpen);

    useEffect(() => {
        dispatch(getUserBoards(user.userIdentifier));
    }, [dispatch, user.userIdentifier]);

    const handleDeleteClick = (board) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(board),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleDelete = (identifier) => {
        dispatch(deleteBoard(identifier, user.userIdentifier));
    };

    return (
        <>
            <Helmet>
                <title>TaskBoards - TrackTick</title>
                <meta name="description" content="Login page" />
            </Helmet>

            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Task Boards</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/employee/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Employee</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <Link to="#" onClick={() => setAddBoardModalOpen(true)} className="btn add-btn">
                                <i className="fa fa-plus" /> Add Board
                            </Link>
                        </div>
                    </div>
                </div>
                {!isLoading ? (
                    <div className="row staff-grid-row">
                        {userBoards.length > 0 ? (
                            userBoards.map((board) => (
                                <div
                                    className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                                    key={board.identifier}
                                >
                                    <div className={`profile-widget`}>
                                        <Link to={`/task-board/${board.identifier}`}>
                                            <h3>{board.boardName}</h3><br /></Link>
                                        <div className="dropdown profile-action">
                                            <Link
                                                to="#"
                                                className="action-icon dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i className="material-icons">more_vert</i>
                                            </Link>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <Link
                                                    className="dropdown-item"
                                                    to={"#"}
                                                    onClick={() => {
                                                        handleDeleteClick(board.identifier);
                                                    }}
                                                >
                                                    <i className="fa fa-trash-o m-r-5" /> Delete
                                                </Link>
                                            </div>
                                        </div>
                                        <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                                            <Link
                                                to={`/employee/taskboard/${board.identifier}`}
                                            >
                                                {board.name}
                                            </Link>
                                        </h4>
                                        <div className="small text-muted">
                                           CreatedBy: {board.createdBy}
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>Nothing Here!</p>
                        )}
                    </div>
                ) : (
                    <Loader />
                )}
                <AddBoardModal
                    addBoardModalOpen={addBoardModalOpen}
                    toggleAddBoardModal={toggleAddBoardModal}
                />
            </div>

        </>
    )
}
export default AllBoards;