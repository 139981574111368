import React from 'react'
import { logo_1, logo_2 } from '../assets/assets2/images'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaTwitter, FaGooglePlusG, FaLinkedinIn, FaAngleRight } from 'react-icons/fa'
function FooterArea() {
    return (
        <>
            <footer id="footer" className="footer-area pt-90">
                <div className="container">
                    <div className="row gx-xl-5">
                        <div className="col-lg-4 col-md-12">
                            <div className="footer-widget">
                                <div className="navbar-brand">
                                    <Link to={"/home"}>
                                        <img src={logo_2} alt="logo" />
                                        <img src={logo_1} alt="logo" />
                                    </Link>
                                </div>
                                <div className="social-link">
                                    <p>To help improve your personal and your team's productivity,
                                        Samu ties into your existing tools.</p>
                                    <Link to="https://www.facebook.com/" target="_blank"
                                    ><i><FaFacebookF /></i
                                    ></Link>
                                    <Link to="https://www.twitter.com/" target="_blank"
                                    ><i><FaTwitter /></i
                                    ></Link>
                                    <Link to="https://www.google.com/" target="_blank"
                                    ><i><FaGooglePlusG /></i
                                    ></Link>
                                    <Link to="https://www.linkedin.com/" target="_blank"
                                    ><i><FaLinkedinIn /></i
                                    ></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                            <div className="footer-widget">
                                <h3>Company</h3>
                                <ul className="footer-links">
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">About Us</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Our Team</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Products</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                            <div className="footer-widget">
                                <h3>Product</h3>
                                <ul className="footer-links">
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Feature</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Pricing</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Credit</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">News</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                            <div className="footer-widget">
                                <h3>Download</h3>
                                <ul className="footer-links">
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">iOS</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Android</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Microsoft</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Desktop</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                            <div className="footer-widget">
                                <h3>Support</h3>
                                <ul className="footer-links">
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Privacy</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Help</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">Terms</Link>
                                    </li>
                                    <li>
                                        <i><FaAngleRight /></i>
                                        <Link to="#">FAQ</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right-area text-center">
                    <div className="container">
                        <span>
                            @2023 Copyright Crown hill solutions. All Right Reserved
                        </span>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterArea