import React from "react";
import { Link, useLocation } from "react-router-dom";
import { genericImg } from "../../assets/img";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../features/authLoginSlice";
import { ROLES } from "../../constant";
import { logo_1 } from "../../assets/assets2/images";

function NavMenu(props) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    const subdropMenus = document.querySelectorAll(".subdrop-menu");
    if (document.body.classList.contains("mini-sidebar")) {
      subdropMenus.forEach((menu) => {
        menu.style.display = "none";
      });
    } else {
      subdropMenus.forEach((menu) => {
        if (menu.parentNode.querySelector("a").classList.contains("subdrop")) {
          menu.style.display = "block";
        }
      });
    }
  };

  const handleLogOut = () => {
    dispatch(logoutUser());
  };

  const pathname = location.pathname;

  const onMenuClik = () => {
    props.onMenuClick()
  }
  return (
    <>
      <div className="header" style={{ right: "0px" }}>
        <div className="header-left">
          <Link to={user.role === ROLES.Admin ? '/admin/dashboard' : user.role === ROLES.Company ? '/company-dashboard' : '/employee/dashboard'} className="logo">
            <img src={logo_1} width="150" height="40" alt="logo" />
          </Link>
        </div>
        <Link to="#" id="toggle_btn"
          style={{
            display: pathname.includes("tasks")
              ? "none"
              : pathname.includes("compose")
                ? "none"
                : "",
          }}
          onClick={handlesidebar}
        >
          <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </Link>
        <div className="page-title-box">
          <h3>The Best Way To Track Your Work</h3>
        </div>
        <Link id="mobile_btn" className="mobile_btn" to="#" onClick={() => onMenuClik()}><i className="fa fa-bars" /></Link>
        <ul className="nav user-menu">
          <li className="nav-item dropdown has-arrow main-drop">
            <Link to="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
              <span className="user-img me-1">
                <img src={genericImg} alt="" />
                <span className="status online" />
              </span>
              <span>{user.name}</span>
            </Link>
            <div className="dropdown-menu">
              <Link className="dropdown-item" to={user.role === ROLES.Admin ? '/admin/profile' : user.role === ROLES.Company ?
                '/company/user-profile' : user.role === ROLES.Employee ? '/employee/user-profile' : 'not-found'}>
                My Profile
              </Link>
              <Link className="dropdown-item" to="#" onClick={handleLogOut}>
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
export default NavMenu;