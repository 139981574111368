import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";

function Sidebar() {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user.role ? user.role : "";
  const [employeeId, setEmployeeId] = useState();
  const [companyId, setCompanyId] = useState();
  const [isSideMenu, setSideMenu] = useState("");

  let location = useLocation();

  useEffect(() => {
    if (user.employeeIdentifier) {
      setEmployeeId(user.employeeIdentifier);
    }
    if (user.companyIdentifier) {
      setCompanyId(user.companyIdentifier);
    }
  }, [user])

  let menuItems = [
    {
      title: "Admin-DashBoard",
      icon: "las la-tachometer-alt",
      path: `/admin/dashboard`,
      roles: ["admin"],
    },
    {
      title: "Employee-Dashboard",
      icon: "las la-tachometer-alt",
      path: `/employee/dashboard`,
      roles: ["employee"],
    },
    {
      title: "Company-Dashboard",
      icon: "las la-tachometer-alt",
      path: `/company/dashboard`,
      roles: ["company"],
    },
    {
      title: "Attendance",
      icon: "las la-user-clock",
      path: "/employee/attendance",
      roles: ["employee"],
    },
    {
      title: "Task-Board",
      icon: "las la-tasks",
      path: `/employee/boards/${employeeId}`,
      roles: ["employee"],
    },
    {
      title: "Companies",
      icon: "la la-building",
      roles: ["admin"],
      children: [
        {
          title: "All Companies",
          path: "/admin/all-companies",
          activePaths: ["/admin/all-companies"],
        },
        {
          title: "Add Company",
          path: "/admin/add-company",
          activePaths: ["/admin/addCompany"],
        },
        {
          title: "UnApproved Companies",
          path: "/admin/unap-companies",
          activePaths: ["/admin/unap-companies"],
        },
      ],
    },
    {
      title: "Employees",
      icon: "la la-user",
      roles: ["admin"],
      children: [
        {
          title: "All Employees",
          path: `/admin/all-employees`,
          activePaths: ["/admin/all-employees"],
        },
        {
          title: "Add Employee",
          path: "/admin/add-employee",
          activePaths: ["/admin/addEmployee"],
        },
      ],
    },
    {
      title: "Tickets",
      icon: "la la-ticket",
      path: `/admin/all-tickets`,
      roles: ["admin"],
    },
    {
      title: "Employees",
      icon: "la la-user",
      roles: ["company"],
      children: [
        {
          title: "All Employees",
          path: `/company/company-employees/${companyId}`,
          activePaths: [`/company/company-employees/${companyId}`],
        },
        {
          title: "Add Employee",
          path: `/company/company-addemployee/${companyId}`,
          activePaths: [`/company/company-addemployee/${companyId}}`],
        },
      ],
    },
    {
      title: "Client",
      icon: "la la-user",
      roles: ["company"],
      children: [
        {
          title: "All Clients",
          path: `/company/all-clients/${companyId}`,
          activePaths: [`/company/all-clients/${companyId}`],
        },
        {
          title: "Add Client",
          path: `/company/add-client/${companyId}`,
          activePaths: [`/company/add-client/${companyId}`],
        },
      ],
    },
    {
      title: "Client",
      icon: "la la-user",
      roles: ["admin"],
      children: [
        {
          title: "All Clients",
          path: `/admin/clients`,
          activePaths: [`/clients`],
        },
        {
          title: "Add Client",
          path: `/admin/add-client`,
          activePaths: [`/add-client`],
        },
      ],
    },
    {
      title: "Sales",
      icon: "la la-user",
      roles: ["admin"],
      children: [
        {
          title: "Taxes",
          path: `/admin/all-tax`,
          activePaths: [`/admin/all-tax`],
        },
        {
          title: "Expense",
          path: `/admin/all-expense`,
          activePaths: [`/admin/all-expense`],
        },
        {
          title: "Invoices",
          path: `/admin/all-invoice`,
          activePaths: [`/admin/all-invoice`],
        },
        {
          title: "All Estimate",
          path: `/admin/all-estimates`,
          activePaths: [`/admin/all-estimates`],
        },
      ],
    },
    {
      title: "Tickets",
      icon: "la la-ticket",
      roles: ["company"],
      children: [
        {
          title: "All Tickets",
          path: `/company/company-tickets/${companyId}`,
          activePaths: [`/company/company-tickets/${companyId}`],
        },
        {
          title: "Add Ticket",
          path: `/company/add-ticket/${companyId}`,
          activePaths: [`/company/add-ticket/${companyId}`],
        },
      ],
    },
    {
      title: "Tickets",
      icon: "la la-ticket",
      path: `/employee/all-tickets/${employeeId}`,
      roles: ["employee"],
    },
    // {
    //   title: "Projects",
    //   icon: "la la-rocket",
    //   roles: ["company"],
    //   children: [
    //     {
    //       title: "All Projects",
    //       path: `/company-projects/${companyId}`,
    //       activePaths: [`/company-projects/${companyId}`],
    //     },
    //     {
    //       title: "Add Project",
    //       path: `/create-project/${companyId}`,
    //       activePaths: [`/create-project/${companyId}`],
    //     },
    //   ],
    // },
    // {
    //   title: "Sales",
    //   icon: "la la-files-o",
    //   children: [
    //     {
    //       title: "Estimates",
    //       path: "/estimates",
    //       activePaths: ["/estimates"],
    //     },
    //   ],
    //   roles: ["company"],
    // },
    // {
    //   title: "Departments",
    //   icon: "la la-sitemap",
    //   children: [
    //     {
    //       title: "Add Departments",
    //       path: "/add-department",
    //       activePaths: ["/add-department"],
    //     },
    //     {
    //       title: "All Departments",
    //       path: "/departments",
    //       activePaths: ["/departments"],
    //     },
    //   ],
    //   roles: ["admin"],
    // },
    {
      title: "Departments",
      icon: "la la-sitemap",
      children: [
        {
          title: "All Departments",
          path: `/company/company-departments/${companyId}`,
          activePaths: [`/company/company-departments/${companyId}`],
        },
        {
          title: "Add Departments",
          path: `/company/comp-add_department/${companyId}`,
          activePaths: [`/company/comp-add_department/${companyId}`],
        },
      ],
      roles: ["company"],
    },
  ];

  const userMenuItems = menuItems.filter(
    (item) => !item.roles || item.roles.includes(role)
  );

  const toggleSidebar = (value) => {
    if (isSideMenu === value) {
      setSideMenu("");
    } else {
      setSideMenu(value);
    }
  };

  return (
    <div className="sidebar" id="sidebar">
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMin={0}
        autoHeightMax="95vh"
        thumbMinSize={30}
        universal={false}
        hideTracksWhenNotNeeded={true}
      >
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="menu-title">
                <span style={{ textTransform: 'capitalize' }}>Main - {user.role} </span>
              </li>
              {userMenuItems.map((item, index) => (
                <li className={item.children ? "submenu" : ""} key={index}>
                  {item.children ? (
                    <Link
                      to="#"
                      className={
                        isSideMenu === item.title.toLowerCase() ? "subdrop" : ""
                      }
                      onClick={() =>
                        toggleSidebar(
                          isSideMenu === item.title.toLowerCase()
                            ? ""
                            : item.title.toLowerCase()
                        )
                      }
                    >
                      <i className={item.icon} /> <span>{item.title}</span>{" "}
                      <span className="menu-arrow" />
                    </Link>
                  ) : (
                    <Link
                      to={item.path}
                      className={
                        item.activePaths &&
                          item.activePaths.some((path) =>
                            location.pathname.includes(path)
                          )
                          ? "active"
                          : ""
                      }
                    >
                      <i className={item.icon} /> <span>{item.title}</span>
                    </Link>
                  )}

                  {item.children ? (
                    <ul
                      className="subdrop-menu"
                      style={{
                        display:
                          isSideMenu === item.title.toLowerCase()
                            ? "block"
                            : "none",
                      }}
                    >
                      {item.children.map((child, index) => (
                        <li key={index}>
                          <Link
                            to={child.path}
                            className={
                              child.activePaths &&
                                child.activePaths.some((path) =>
                                  location.pathname.includes(path)
                                )
                                ? "active"
                                : ""
                            }
                          >
                            {child.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

export default Sidebar;
