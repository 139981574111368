import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import httpHandler from '../utils/httpHandler';
import { toast } from 'react-hot-toast';

const url = "/api/company/";
const companySlice = createSlice({
    name: 'company',
    initialState: {
        companyLoading: false,
        companiesList: [],
        count: null,

        unapprovedCompanies: [],
        companies: [],
        isLoading: false,
        company: null,
        allCompanies: [],
    },
    reducers: {
        getAllCompaniesSuccess: (state, action) => {
            state.companiesList = action.payload.data;
            state.count = action.payload.count;
            state.companyLoading = false;
        },
        getAllCompaniesFailure: (state, action) => {
            state.companiesList = [];
            state.count = null;
            state.companyLoading = false;
        },
        emptyCompaniesSuccess: (state, action) => {
            state.companies = [];
            state.isLoading = false;
        },
        getUnapprovedCompaniesSuccess: (state, action) => {
            state.unapprovedCompanies = action.payload.data;
            state.isLoading = false;
            state.count = action.payload.count;
        },
        getUnapprovedCompaniesFailure: (state, action) => {
            state.unapprovedCompanies = [];
            state.isLoading = false;
            state.count = null;
        },
        addCompanySuccess: (state, action) => {
            state.isLoading = false;
        },
        addCompanyFailure: (state, action) => {
            state.isLoading = false;
        },
        // getCompanySuccess: (state, action) => {
        //     state.company = action.payload;
        //     state.isLoading = false;
        // },
        // getCompanyFailure: (state, action) => {
        //     state.company = null;
        //     state.isLoading = false;
        // },
        updateCompanySuccess: (state, action) => {
            state.isLoading = false;
        },
        updateCompanyFailure: (state, action) => {
            state.isLoading = false;
        },
        deleteCompanySuccess: (state, action) => {
            const companyId = action.payload.id;
            state.companies = state.companies.filter(company => company.companyIdentifier !== companyId);
            state.companiesList = state.companiesList.filter(company => company.companyIdentifier !== companyId);
            state.isLoading = false;
            state.count = state.count - 1;
        },
        deleteCompanyFailure: (state, action) => {
            state.isLoading = false;
        },
        approveCompanySuccess: (state, action) => {
            state.isLoading = false;
        },
        approveCompanyFailure: (state, action) => {
            state.isLoading = false;
        },
        nullCompanySuccess: (state, action) => {
            state.company = null;
            state.isLoading = false;
        },
        updateCompanyBasicInfoSuccess: (state, action) => {
            state.isLoading = false;
        },
        updateCompanyBasicInfoFailure: (state, action) => {
            state.isLoading = false;
        },
        allCompaniesListSuccess: (state, action) => {
            state.allCompanies = action.payload.data;
            state.isLoading = false;
        },
        allCompaniesListFailure: (state, action) => {
            state.allCompanies = [];
            state.isLoading = false;
        },
        setLoading: (state) => {
            state.isLoading = true;
            state.companyLoading = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompany.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCompany.fulfilled, (state, action) => {
                state.company = action.payload;
                state.isLoading = false;
            })
            .addCase(getCompany.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export const getAllCompanies = (search, page, pagesize) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`${url}getAllCompanies?search=${search}&page=${page}&pagesize=${pagesize}`);
        if (response.data.success)
            dispatch(getAllCompaniesSuccess(response.data));
        else {
            toast.error(response.data.message);
            dispatch(getAllCompaniesFailure(response.data.message));
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(getAllCompaniesFailure(error.message));
    }
};

export const updateCompanyBasicInfo = (id, data, navigate, toggle) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        var response = await httpHandler.post(`${url}updateCompanyBasicInfo/${id}`, data);
        if (response.data.success) {
            toast.success("Details Updated Successfully");
            dispatch(getCompany(id));
            navigate(`/admin/comp-profile/${id}`)
            dispatch(updateCompanyBasicInfoSuccess());
            toggle();
        }
        else {
            toast.success("Something went wrong.");
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(updateCompanyBasicInfoFailure(error));
    }
};

export const getAllUnApprovedCompanies = (search, page, pagesize) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`${url}getAllUnApprovedCompanies?search=${search}&page=${page}&pagesize=${pagesize}`);
        dispatch(getUnapprovedCompaniesSuccess(response.data));
    } catch (error) {
        dispatch(getUnapprovedCompaniesFailure(error));
    }
};

export const addCompany = (data, navigate) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`${url}createCompany`, data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(addCompanySuccess(response.data));
            navigate('/admin/all-companies');
        }
        else {
            dispatch(addCompanyFailure(response.data.message));
            toast.error()
        }
    } catch (error) {
        toast.error(error.message)
        dispatch(addCompanyFailure());
    }
};

// export const getCompanyById = (id) => async (dispatch) => {
//     try {
//         dispatch(setLoading(true));
//         const response = await httpHandler.get(`${url}getCompany/${id}`);
//         if (response)
//             dispatch(getCompanySuccess(response.data));
//     } catch (error) {
//         dispatch(getCompanyFailure(error));
//     }
// };

export const getCompany = createAsyncThunk(
    'company/getCompany',
    async (companyId, { rejectWithValue }) => {
        try {
            const response = await httpHandler.get(`${url}getCompany/${companyId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateCompany = (id, data, navigate) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        await httpHandler.post(`${url}updateCompany/${id}`, data);
        toast.success("Updated Successfully");
        navigate('/admin/all-companies')
        dispatch(updateCompanySuccess());
    } catch (error) {
        toast.error(error.message);
        dispatch(updateCompanyFailure(error));
    }
};

export const deleteCompany = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        await httpHandler.post(`${url}deleteCompany/${id}`);
        toast.success("Deleted Successfully");
        dispatch(deleteCompanySuccess({ id }));
        var search = "";
        dispatch(emptyCompaniesSuccess());
        dispatch(getAllUnApprovedCompanies(search, 1, 10));
        dispatch(getAllCompanies(search, 1, 10));
    } catch (error) {
        toast.error(error.message);
        dispatch(deleteCompanyFailure(error));
    }
};

export const approveCompany = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        await httpHandler.post(`${url}approveCompany/${id}`);
        toast.success("Approved Successfully");
        dispatch(approveCompanySuccess({ id }));
        var search = "";
        dispatch(getAllUnApprovedCompanies(search, 1, 10));
    } catch (error) {
        toast.error(error.message);
        dispatch(approveCompanyFailure(error));
    }
};

export const updateCompanyPersonalInfo = (id, data, navigate, toggle) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        await httpHandler.post(`${url}updateCompanyPersonalInfo/${id}`, data);
        toast.success("Details Updated Successfully");
        dispatch(getCompany(id));
        navigate(`/admin/comp-profile/${id}`)
        dispatch(updateCompanySuccess());
        toggle();
    } catch (error) {
        toast.error(error.message);
        dispatch(updateCompanyFailure(error));
    }
};

export const allCompaniesList = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        var result = await httpHandler.get(`${url}allCompaniesList`);
        if (result.data.success)
            dispatch(allCompaniesListSuccess(result.data));
        else
            dispatch(allCompaniesListFailure());
    } catch (error) {
        dispatch(allCompaniesListFailure());
    }
}

export const emptyCompanies = () => async (dispatch) => {
    dispatch(emptyCompaniesSuccess());
}
export const nullCompany = () => async (dispatch) => {
    dispatch(nullCompanySuccess());
}
export const { getAllCompaniesSuccess, getAllCompaniesFailure, addCompanySuccess, addCompanyFailure, getCompanySuccess,
    getCompanyFailure, updateCompanySuccess, updateCompanyFailure, deleteCompanySuccess, deleteCompanyFailure,
    getUnapprovedCompaniesSuccess, getUnapprovedCompaniesFailure, emptyCompaniesSuccess, approveCompanySuccess,
    approveCompanyFailure, nullCompanySuccess, updateCompanyBasicInfoSuccess, updateCompanyBasicInfoFailure,
    allCompaniesListSuccess, allCompaniesListFailure, setLoading } = companySlice.actions;
export default companySlice.reducer;