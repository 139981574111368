import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { updateTaskboardList } from '../../../../features/boardSlice';

function EditListModal(props) {
    const dispatch = useDispatch();
    const { isLoading, taskBoardList } = useSelector(state => state.taskBoard);

    const handleSubmit = async (event) => {
        event.preventDefault(event);
        const formData = new FormData(event.target);
        formData.append("listIdentifier", taskBoardList?.listIdentifier);
        dispatch(updateTaskboardList(formData, props.boardId));
        props.toggleEditListModal();
    }

    return (
        <>
            <Modal
                isOpen={props.editListModalOpen}
                toggle={() => props.toggleEditListModal()}
            >
                <ModalHeader toggle={() => props.toggleEditListModal()}>
                    Update List
                </ModalHeader>
                <ModalBody>
                    {!isLoading && (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Task Board Name</label>
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        defaultValue={taskBoardList?.name}
                                    />
                                </div>
                                <div className="form-group task-board-color">
                                    <label>Task Board Color</label>
                                    <div className="board-color-list">
                                        <label
                                            className={`board-control board-primary ${taskBoardList?.colour === "primary"
                                                ? "checked"
                                                : ""
                                                }`}
                                        >
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="primary"
                                                defaultChecked={
                                                    taskBoardList?.colour === "primary"
                                                }
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label
                                            className={`board-control board-success ${taskBoardList?.colour === "success"
                                                ? "checked"
                                                : ""
                                                }`}
                                        >
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="success"
                                                defaultChecked={
                                                    taskBoardList?.colour === "success"
                                                }
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label
                                            className={`board-control board-info ${taskBoardList?.colour === "info"
                                                ? "checked"
                                                : ""
                                                }`}
                                        >
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="info"
                                                defaultChecked={
                                                    taskBoardList?.colour === "info"
                                                }
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label
                                            className={`board-control board-purple ${taskBoardList?.colour === "purple"
                                                ? "checked"
                                                : ""
                                                }`}
                                        >
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="purple"
                                                defaultChecked={
                                                    taskBoardList?.colour === "purple"
                                                }
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label
                                            className={`board-control board-warning ${taskBoardList?.colour === "warning"
                                                ? "checked"
                                                : ""
                                                }`}
                                        >
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="warning"
                                                defaultChecked={
                                                    taskBoardList?.colour === "warning"
                                                }
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                        <label
                                            className={`board-control board-danger ${taskBoardList?.colour === "danger"
                                                ? "checked"
                                                : ""
                                                }`}
                                        >
                                            <input
                                                name="colour"
                                                type="radio"
                                                className="board-control-input"
                                                defaultValue="danger"
                                                defaultChecked={
                                                    taskBoardList?.colour === "danger"
                                                }
                                            />
                                            <span className="board-indicator" />
                                        </label>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary btn-lg" type="Submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    )
}

export default EditListModal