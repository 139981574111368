import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import { toast } from "react-hot-toast";
import { ROLES } from "../constant";

const ticketSlice = createSlice({
    name: 'ticket',
    initialState: {
        tickets: [],
        companyTickets: [],
        isLoading: false,
        error: null,
        message: null,
        isAdded: false,
        isUpdated: false,
        ticket: null,
        isPriorityChanged: false,
        isStatusChanged: false,
        comments: [],
        ticketCountObject: null,
        images: [],
        files: [],
        unAssignedEmployees: [],
    },
    reducers: {
        getTicketsSuccess: (state, action) => {
            state.tickets = action.payload.data;
            state.isLoading = false;
            state.ticketsCount = action.payload.totalCount;
            state.ticketCountObject = action.payload.ticketCountObject;
        },
        getTicketsFailure: (state, action) => {
            state.tickets = [];
            state.isLoading = false;
            state.ticketsCount = null;
            state.ticketCountObject = null;
        },
        getCompanyTicketsSuccess: (state, action) => {
            state.companyTickets = action.payload.data;
            state.isLoading = false;
            state.ticketsCount = action.payload.totalCount;
            state.ticketCountObject = action.payload.ticketCountObject;
        },
        getCompanyTicketsFailure: (state, action) => {
            state.companyTickets = [];
            state.isLoading = false;
            state.error = action.payload;
            state.ticketsCount = null;
            state.ticketCountObject = null;
        },
        getTicketSuccess: (state, action) => {
            state.ticket = action.payload.data;
            const attachments = action.payload.data.filepaths;
            const images = attachments.filter(attachment => {
                const ext = attachment.fileName && attachment.fileName.split('.').pop().toLowerCase();
                return (ext === 'jpg' || ext === 'jpeg' || ext === 'png');
            });
            const files = attachments.filter(attachment => {
                const ext = attachment.fileName && attachment.fileName.split('.').pop().toLowerCase();
                return (ext === 'pdf' || ext === 'doc' || ext === 'docx');
            });
            state.images = images;
            state.files = files;
            state.isLoading = false;
        },
        getTicketFailure: (state, action) => {
            state.ticket = null;
            state.isLoading = false;
        },
        addTicketSuccess: (state, action) => {
            state.isLoading = false;
        },
        addTicketFailure: (state, action) => {
            state.isLoading = false;
        },
        editTicketSuccess: (state, action) => {
            state.isLoading = false;
        },
        editTicketFailure: (state, action) => {
            state.isLoading = false;
        },
        changePrioritySuccess: (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
            state.error = null;
            state.isPriorityChanged = true;
        },
        changePriorityFailure: (state, action) => {
            state.isLoading = false;
            state.message = null;
            state.error = action.payload;
            state.isPriorityChanged = false;
        },
        changeStatusSuccess: (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
            state.error = null;
            state.isStatusChanged = true;
        },
        changeStatusFailure: (state, action) => {
            state.isLoading = false;
            state.message = null;
            state.error = action.payload;
            state.isStatusChanged = false;
        },
        deleteTicketSuccess: (state, action) => {
            const ticketId = action.payload.id;
            state.tickets = state.tickets.filter(ticket => ticket.ticketIdentifier !== ticketId);
            state.companyTickets = state.companyTickets.filter(ticket => ticket.ticketIdentifier !== ticketId);
            state.isLoading = false;
        },
        deleteTicketFailure: (state, action) => {
            state.isLoading = false;
        },
        addAssigneeSuccess: (state, action) => {
            state.isLoading = false;
        },
        addAssigneeFailure: (state, action) => {
            state.isLoading = false;
        },
        getCommentsSuccess: (state, action) => {
            state.comments = action.payload;
            state.isLoading = false;
        },
        getCommentsFailure: (state, action) => {
            state.comments = [];
            state.isLoading = false;
        },
        deleteAttachmentSuccess: (state, action) => {
            const attachmentId = action.payload;
            state.images = state.images.filter(image => image.attachment_Identifier !== attachmentId);
            state.files = state.files.filter(file => file.attachment_Identifier !== attachmentId);
            state.isLoading = false;
        },
        addAttachmentSuccess: (state, action) => {
            state.isLoading = false;
        },
        addAttachmentFailure: (state, action) => {
            state.isLoading = false;
        },
        deleteAttachmentFailure: (state, action) => {
            state.isLoading = false;
        },
        getAllEmployeesforTicketSuccess: (state, action) => {
            state.unAssignedEmployees = action.payload.data;
            state.isLoading = false;
        },
        getAllEmployeesforTicketFailure: (state, action) => {
            state.unAssignedEmployees = [];
            state.isLoading = false;
        },
        removeAssigneeSuccess: (state, action) => {
            state.isLoading = false;
        },
        removeAssigneeFailure: (state, action) => {
            state.isLoading = false;
        },
        getAllTicketsForEmployeeSuccess: (state, action) => {
            state.isLoading = false;
            state.tickets = action.payload.data;
            state.ticketsCount = action.payload.totalCount;
            state.ticketCountObject = action.payload.ticketCountObject;
        },
        getAllTicketsForEmployeeFailure: (state, action) => {
            state.isLoading = false;
            state.tickets = [];
            state.ticketsCount = 0;
            state.ticketCountObject = [];
        },
        updateCommentsArraySuccess: (state, action) => {
            state.comments = state.comments.push(action.payload);
        },
        setLoading: (state, action) => {
            state.isLoading = true;
        },
    },
});

export const getAllTickets = (search, page, pagesize, status, priority) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/ticket/getAllTickets?search=${search}&page=${page}&pagesize=${pagesize}&status=${status}&priority=${priority}`);

        dispatch(getTicketsSuccess(response.data));
    } catch (error) {
        dispatch(getTicketsFailure(error));
    }
};

export const getAllTicketsForCompany = (id, search, page, pagesize, status, priority) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/ticket/getAllTicketsForCompany/${id}?search=${search}&page=${page}&pagesize=${pagesize}&status=${status}&priority=${priority}`);
        if (response.data.success) {
            if (response.data.data.length > 0) {
                //toast.success(response.data.message);
            }
            else {
                //toast.error("No ticket available.");
            }
            dispatch(getCompanyTicketsSuccess(response.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(getCompanyTicketsFailure());
        }

    } catch (error) {
        toast.error(error.message);
        dispatch(getCompanyTicketsFailure(error));
    }
};

export const getAllTicketsForEmployee = (search, page, pageSize, employeeId, status, priority) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/ticket/getAllTicketsForEmployee?search=${search}&page=${page}&pagesize=${pageSize}&id=${employeeId}&status=${status}&priority=${priority}`)
        if (response.data.success) {
            dispatch(getAllTicketsForEmployeeSuccess(response.data));
        }
        else {
            dispatch(getAllTicketsForEmployeeFailure());
        }
    } catch (error) {
        dispatch(getAllTicketsForEmployeeFailure());
    }
}

export const getTicket = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/ticket/getTicket/${id}`);
        dispatch(getTicketSuccess(response.data));
    } catch (error) {
        dispatch(getTicketFailure(error));
    }
};

export const changePriority = (id, newPriority, reloadData) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/ticket/changePriority`, { id, newPriority });
        if (response.data.success) {
            reloadData();
            toast.success(response.data.message);
            dispatch(changePrioritySuccess(response.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(changePriorityFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(changePriorityFailure());
    }
}

export const changeStatus = (id, newStatus, reloadData) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/ticket/changeStatus`, { id, newStatus });
        if (response.data.success) {
            reloadData();
            toast.success(response.data.message);
            dispatch(changeStatusSuccess(response.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(changeStatusFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(changeStatusFailure());
    }
}

export const addTicket = (data, navigate, user, companyId) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post('/api/ticket/addTicket', data);
        if (response.data.success) {
            if (user.companyIdentifier) {
                navigate(`/company-tickets/${user.companyIdentifier}`)
                toast.success(response.data.message);
            }
            else if (user.employeeIdentifier) {
                navigate(`/employee/all-tickets/${user.employeeIdentifier}`)
                toast.success(response.data.message);
            }
            else {
                navigate(`/company-tickets/${companyId}`)
                toast.success(response.data.message);
            }
            dispatch(addTicketSuccess(response.data));
        }
        else {
            toast.error(response.data.message);
            dispatch(addTicketFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(addTicketFailure());
    }
}

export const editTicket = (data, user, navigate) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post('/api/ticket/editTicket', data);
        if (response.data.success) {
            if (user.role === ROLES.Company) {
                navigate(`/company/company-tickets/${user.companyIdentifier}`)
                toast.success(response.data.message);
                dispatch(editTicketSuccess(response.data));
            }
            else if (user.role === ROLES.Employee) {
                navigate(`/employee/all-tickets/${user.employeeIdentifier}`)
                toast.success(response.data.message);
                dispatch(editTicketSuccess(response.data));
            }
            else {
                navigate(`/admin/all-tickets`)
                toast.success(response.data.message);
                dispatch(editTicketSuccess(response.data));
            }
        }
        else {
            toast.error(response.data.message);
            dispatch(editTicketFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(editTicketFailure());
    }
}

export const deleteTicket = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/ticket/deleteTicket/${id}`);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(deleteTicketSuccess({ id }));
            getAllTickets("", 1, 5, "", "");
        }
        else {
            toast.error(response.data.message);
            dispatch(deleteTicketFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(deleteTicketFailure());
    }
};

export const addAssignee = (data, ticketId, companyIdentifier) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post('/api/ticket/TicketAssignment', data);
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(addAssigneeSuccess(response.data));
            dispatch(getTicket(ticketId));
            dispatch(getAllEmployeesforTicket("", companyIdentifier, 1, 10, ticketId))
        }
        else {
            toast.error(response.data.message);
            dispatch(addAssigneeFailure());
        }
    }
    catch (error) {
        toast.error(error.message);
        dispatch(addAssigneeFailure());
    }
}

export const removeAssignee = (ticketIdentifier, userIdentifier, companyIdentifier) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/ticket/removeAssignee`, { ticketIdentifier, userIdentifier });
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(removeAssigneeSuccess());
            dispatch(getTicket(ticketIdentifier));
            dispatch(getAllEmployeesforTicket("", companyIdentifier, 1, 10, ticketIdentifier))
        }
        else {
            toast.error(response.data.message);
            dispatch(removeAssigneeFailure());
        }
    }
    catch (error) {
        toast.error(error.message);
        dispatch(removeAssigneeFailure());
    }
}

// export const addComment = (userId, ticketId, comment) => async (dispatch) => {
//     try {
//         //dispatch(setLoading(true));
//         const response = await httpHandler.post('/api/ticket/addComment', { userId, ticketId, comment });
//         if (response.data.success) {
//             dispatch(getComments(ticketId));
//         }
//         else {
//             toast.error(response.data.message);
//         }
//     }
//     catch (error) {
//         toast.error(error.message);
//     }
// }

export const getComments = (ticketId, setTicketComments) => async (dispatch) => {
    try {
        //dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/ticket/getComments/${ticketId}`);
        if (response.status === 200) {
            dispatch(getCommentsSuccess(response.data));
            setTicketComments(response.data);
        }
        else {
            dispatch(getCommentsFailure());
        }
    }
    catch (error) {
        toast.error(error.message);
        dispatch(getCommentsFailure());
    }
}

export const deleteAttachment = (objectIdentifier, attachmentIdentifier, userIdentifier) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/ticket/deleteAttachmentToTicket`, { objectIdentifier, attachmentIdentifier, userIdentifier });
        if (response.data.success) {
            dispatch(deleteAttachmentSuccess(attachmentIdentifier));
            toast.success(response.data.message);
        }
        else {
            dispatch(deleteAttachmentFailure());
            toast.error(response.data.message);
        }
    } catch (error) {
        dispatch(deleteAttachmentFailure());
        toast.error(error.message);
    }
};

export const addAttachment = (data, ticketId) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await httpHandler.post(`/api/ticket/AddAttachmentToTicket`, data);
        if (response.data.success) {
            dispatch(getTicket(ticketId));
            dispatch(addAttachmentSuccess(response.data));
            toast.success(response.data.message);
        }
        else {
            dispatch(addAttachmentFailure());
            toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.response.data.error);
        dispatch(addAttachmentFailure());
    }
}

export const getAllEmployeesforTicket = (search, companyIdentifier, page, pagesize, ticketId) => async (dispatch) => {
    try {
        //dispatch(setLoading(true));
        const response = await httpHandler.get(`/api/ticket/getUnAssignedEmpForTickets?search=${search}&page=${page}&pagesize=${pagesize}&companyIdentifier=${companyIdentifier}&id=${ticketId}`);
        if (response.data.success) {
            if (response.data.data.length > 0) {
                dispatch(getAllEmployeesforTicketSuccess(response.data));
                //toast.success(response.data.message);
            }
            else {
                dispatch(getAllEmployeesforTicketFailure());
                //toast.success("No employee available");
            }
        }
        else {
            dispatch(getAllEmployeesforTicketFailure());
            toast.error(response.data.message);
        }
    }
    catch (error) {
        dispatch(getAllEmployeesforTicketFailure());
        toast.error(error.message);
    }
}
export const updateCommentsArray = (message) => async (dispatch) => {
    dispatch(updateCommentsArraySuccess(message));
}
export const { getTicketsSuccess, getTicketsFailure, getTicketSuccess, getTicketFailure, addTicketSuccess, addTicketFailure, changePrioritySuccess,
    changePriorityFailure, changeStatusSuccess, changeStatusFailure, deleteTicketSuccess, deleteTicketFailure, getCompanyTicketsSuccess,
    getCompanyTicketsFailure, addAssigneeSuccess, addAssigneeFailure, getCommentsSuccess, getCommentsFailure, editTicketSuccess, editTicketFailure,
    deleteAttachmentSuccess, deleteAttachmentFailure, addAttachmentSuccess, addAttachmentFailure, getAllEmployeesforTicketSuccess,
    getAllEmployeesforTicketFailure, removeAssigneeSuccess, removeAssigneeFailure, getAllTicketsForEmployeeSuccess, getAllTicketsForEmployeeFailure,
    updateCommentsArraySuccess, setLoading } = ticketSlice.actions;
export default ticketSlice.reducer;