import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "../../../components/loader/Loader";
import { getMonthlyWorkingHours, getTodayActivity, getTodayReport, getWeeklyWorkingHours, logPunchTime } from "../../../features/attendanceSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ButtonTexts, PunchFlg } from "../../../constant";

const AttendanceEmployee = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const { loading, isLoading, todayActivity, weeklyWorkHours, monthlyworkHours, punchFlg, todayReport } = useSelector(state => state.attendance);

  useEffect(() => {
    dispatch(getTodayActivity(user.employeeIdentifier));
  }, [dispatch, punchFlg, user.employeeIdentifier]);

  useEffect(() => {
    dispatch(getWeeklyWorkingHours(user.employeeIdentifier));
    dispatch(getMonthlyWorkingHours(user.employeeIdentifier));

    const interval = setInterval(() => {
      dispatch(getWeeklyWorkingHours(user.employeeIdentifier));
    }, 2 * 60 * 1000);

    return () => clearInterval(interval);
  }, [dispatch, user.employeeIdentifier]);

  useEffect(() => {
    dispatch(getTodayReport(user.employeeIdentifier));
    const todayInterval = setInterval(() => {
      dispatch(getTodayReport(user.employeeIdentifier));
    }, 2 * 60 * 1000);

    return () => clearInterval(todayInterval);
  }, [dispatch, user.employeeIdentifier, punchFlg]);

  const formatDate = date => moment(date).format("DD-MMM-YYYY");
  const formatDateForPunchTime = date => moment(date).format("ddd, Do MMM YYYY h.mm A");
  const formatTime = date => moment(date).format("h:mm A");

  const handlePunchTime = () => {
    dispatch(logPunchTime(user.employeeIdentifier, punchFlg));
  };

  const calculatePercentage = (workingHours, maxWorkingHours) => {
    const percentage = (workingHours / maxWorkingHours) * 100;
    return percentage.toFixed(2);
  };

  return (
    <>
      <Helmet>
        <title>Attendance - CHS</title>
        <meta name="description" content="Login page" />
      </Helmet>
      {!isLoading ? (
        <div className="content container-fluid time-tracker-box">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Attendance</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Employee</Link>
                  </li>
                  <li className="breadcrumb-item active">Attendance</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card punch-status">
                <div className="card-body" style={{ height: "405px" }}>
                  <h5 className="card-title">
                    Timesheet{" "}
                    <small className="text-muted">{formatDate()}</small>
                  </h5>
                  <div className="punch-det">
                    <h6>Punch In at</h6>
                    <p>
                      {todayReport?.entryTime
                        ? formatDateForPunchTime(todayReport?.entryTime)
                        : "Please Start your day"}
                    </p>
                  </div>
                  <div className="punch-info">
                    <div className="punch-hours">
                      <span>
                        {todayReport?.workingHours
                          ? todayReport.workingHours.toFixed(2)
                          : 0}{" "}
                        hrs
                      </span>
                    </div>
                  </div>
                  <div className="punch-btn-section">
                    <button
                      disabled={loading}
                      type="button"
                      onClick={handlePunchTime}
                      className="btn btn-primary punch-btn"
                    >
                      {loading ? "Loading..." : punchFlg === PunchFlg.IN ? ButtonTexts.PunchIN : punchFlg === PunchFlg.OUT ? ButtonTexts.PunchOUT : "Punch IN"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card att-statistics">
                <div className="card-body">
                  <h5 className="card-title">Statistics</h5>
                  <div className="stats-list">
                    <div className="stats-info">
                      <p>
                        Today{" "}
                        <strong>
                          <span>
                            {todayReport?.workingHours
                              ? todayReport.workingHours.toFixed(2)
                              : 0}{" "}
                          </span>
                          <small>/ 8 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{
                            width: `${calculatePercentage(todayReport?.workingHours ? todayReport.workingHours.toFixed(2) : 0, 8)}%`
                          }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="stats-info">
                      <p>
                        This Week{" "}
                        <strong>
                          {weeklyWorkHours ? weeklyWorkHours.toFixed(2) : 0}{" "}
                          <small>/ 40 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width: `${calculatePercentage(weeklyWorkHours ? weeklyWorkHours.toFixed(2) : 0, 40)}%`
                          }}
                          aria-valuenow={31}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="stats-info">
                      <p>
                        This Month{" "}
                        <strong>
                          {monthlyworkHours
                            ? monthlyworkHours.toFixed(2)
                            : 0}
                          <small>/ 160 hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{
                            width: `${calculatePercentage(monthlyworkHours ? monthlyworkHours.toFixed(2) : 0, 160)}%`
                          }}
                          aria-valuenow={62}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="stats-info">
                      <p>Remaining <strong>{160 - (monthlyworkHours ? monthlyworkHours.toFixed(2) : 0)} <small>/ 160 hrs</small></strong></p>
                      <div className="progress">
                        <div className="progress-bar bg-danger" role="progressbar"
                          style={{ width: `${calculatePercentage((160 - (monthlyworkHours ? monthlyworkHours.toFixed(2) : 0)), 160)}%` }}
                          aria-valuenow={62} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card recent-activity">
                <div className="card-body">
                  <h5 className="card-title">Today Activity</h5>
                  {todayActivity && todayActivity.length > 0 ? (
                    <ul className="res-activity-list">
                      {todayActivity.map((activity) => (
                        <li key={activity.id}>
                          <p className="mb-0">
                            {activity.punchFlg === "I"
                              ? "Punch In at"
                              : "Punch Out at"}
                          </p>
                          <p className="res-activity-time">
                            <i className="fa fa-clock-o" />{" "}
                            {formatTime(activity.loggingTime)}
                          </p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No activity found for today.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
// function mapStateToProps(state) {
//   return {};
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators(employeeActions, dispatch),
//   };
// }

export default AttendanceEmployee;