import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { addAssignee, getAllEmployeesforTicket, removeAssignee } from "../../features/ticketsSlice";
import { useSelector } from "react-redux";
import Loader from "../loader/Loader";
import ReactSelect from "react-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

function AssigneeModal(props) {
    const dispatch = useDispatch();
    const { isLoading, unAssignedEmployees } = useSelector(state => state.ticket);
    const [addAssigneeValue, setAddAssigneeValue] = useState([]);
    const state = {
        search: "",
        page: 1,
        pagesize: 12,
    };
    useEffect(() => {
        if (props.user.companyIdentifier) {
            dispatch(getAllEmployeesforTicket(state.search, props.user.companyIdentifier ? props.user.companyIdentifier : "", state.page, state.pagesize, props.ticketId));
        }
    }, [state.search, state.page, state.pagesize, dispatch, props.ticketId, props.user.companyIdentifier]);

    const {
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(),
    });

    const handleAddAssignee = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("ticketId", props.ticketId);
        addAssigneeValue.forEach((employeeId) => {
            formData.append("employeeId", employeeId);
        });
        dispatch(addAssignee(formData, props.ticketId, props.user.companyIdentifier));
        //props.toggleAddAssigneeModal();
    };

    const options = unAssignedEmployees.map((employee) => {
        return {
            value: employee.employeeIdentifier,
            label: employee.name,
        };
    });

    const handleRemoveAssignee = (id) => {
        dispatch(removeAssignee(props.ticketId, id, props.user.companyIdentifier));
    };

    return (
        <>
            {isLoading ? <Loader /> : ""}
            <Modal
                isOpen={props.addAssigneeModal}
                toggle={props.toggleAddAssigneeModal}
            >
                <div id="edit-profile-box">
                    <ModalHeader toggle={props.toggleAddAssigneeModal}>
                        Assign User to this Ticket
                    </ModalHeader>
                </div>
                <ModalBody>
                    <div className="modal-body">
                        {unAssignedEmployees ?
                            <div>
                                <form onSubmit={handleAddAssignee}>
                                    <div className="form-group">
                                        <Controller
                                            name="add-assignee"
                                            control={control}
                                            render={({ field: { value, onChange } }) => {
                                                const handleAddAssigneeChange = (selected) => {
                                                    const selectedValues = selected ? selected.map(option => option.value) : [];
                                                    onChange(selectedValues);
                                                    setAddAssigneeValue(selectedValues); // Update the state with the selected values
                                                };
                                                return (
                                                    <ReactSelect
                                                        options={options}
                                                        value={options.filter(option => value.includes(option.value))}
                                                        onChange={handleAddAssigneeChange}
                                                        isClearable
                                                        isSearchable
                                                        isMulti
                                                        required
                                                    />
                                                );
                                            }}
                                            defaultValue=""
                                        />
                                        <small>{errors?.company?.message}</small>
                                    </div>
                                    <div className="form-group text-center">
                                        <button
                                            className="btn btn-primary account-btn"
                                        // onClick={handleAddAssignee}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                            : ""}
                        <div className="mt-4">
                            <ul className="chat-user-list">
                                {props.assigned.length > 0 ? (
                                    props.assigned.map((employee, index) => (
                                        <li key={index}>
                                            <Link to="#">
                                                <div className="media d-flex justify-content-start align-items-center">
                                                    <div className="avatar me-4 h-auto">
                                                        <img src={employee.userProfile} alt={employee.name} />
                                                    </div>
                                                    <div className="profile-box-block d-flex justify-content-between w-100">
                                                        <div className="media-body align-self-center text-nowrap d-flex justify-content-start  flex-column">
                                                            <div className="user-name lh-1">{employee.name}</div>
                                                            <span className="designation">{employee.designation}</span>
                                                        </div>
                                                        <div className="float-right" onClick={() => handleRemoveAssignee(employee.userIdentifier)}>
                                                            <i className="fa fa-trash-o text-danger" /></div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    ))
                                ) : (
                                    <p>Nothing Here!</p>
                                )}
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default AssigneeModal;