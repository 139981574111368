import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../loader/Loader";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../../features/authLoginSlice";

const schema = yup
  .object({
    otp: yup.string().required("OTP is required"),
    newPassword: yup.string().min(6).required("Password is required").trim(),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Password must match")
      .required("Confirm New Password is required")
      .trim(),
  })
  .required();

function ResetPassword() {
  const {userId} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eye, seteye] = useState(true);
  const onEyeClick = () => seteye(!eye);
  const { isLoading } = useSelector((state) => state.userLogin);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    dispatch(resetPassword(data, userId, navigate));
  };

  return (
    <>
      <Helmet>
        <title>Reset Password - Track Trick</title>
        <meta name="description" content="Login page" />
      </Helmet>
      {isLoading ? <Loader /> : ""}
      <div className="account-content">
        <div className="container">
          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">Reset Password</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label>Otp</label>
                  <Controller
                    name="otp"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control  ${
                          errors?.otp ? "error-input" : ""
                        }`}
                        type="number"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.otp?.message}</small>
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <Controller
                    name="newPassword"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <div className="pass-group">
                        <input
                          type={eye ? "password" : "text"}
                          className={`form-control  ${
                            errors?.newPassword ? "error-input" : ""
                          }`}
                          onChange={onChange}
                          autoComplete="false"
                        />
                        <span
                          onClick={onEyeClick}
                          className={`fa toggle-password" ${
                            eye ? "fa-eye-slash" : "fa-eye"
                          }`}
                        />
                      </div>
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.newPassword?.message}</small>
                </div>
                <div className="form-group">
                  <label>Confirm New Password</label>
                  <Controller
                    name="confirmNewPassword"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <div className="pass-group">
                        <input
                          type={"password"}
                          className={`form-control  ${
                            errors?.confirmNewPassword ? "error-input" : ""
                          }`}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      </div>
                    )}
                    defaultValue=""
                  />
                  <small>{errors?.confirmNewPassword?.message}</small>
                </div>
                <div className="form-group text-center">
                  <button className="btn btn-primary account-btn" type="submit">
                    Reset Password
                  </button>
                </div>
                <div className="account-footer">
                  <p>
                    Remember your password? <Link to="/login">Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
