import React, { useState } from "react";

const CommentForm = ({
    handleSubmit,
    submitLabel,
    hasCancelButton = false,
    handleCancel,
    initialText = "",
}) => {
    const [commentText, setCommentText] = useState(initialText);
    const user = JSON.parse(localStorage.getItem('user'));
    const isTextareaDisabled = commentText.length === 0;
    const onSubmit = (event) => {
        event.preventDefault();
        handleSubmit(commentText);
        setCommentText("");
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="u-gutters">
                <div className="editable-box-img">
                    <img alt={(user.name).slice(0, 2).toUpperCase()} className="rounded-circle" src={user.userProfile} />
                </div>
                <div attr="desc" className="editable">
                    <div className="description-content js-desc-content">
                        <div className="u-bottom js-hide-with-desc">
                            <textarea
                                className="description-fake-text-area"
                                type="text"
                                placeholder="Write a comment..."
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                            />
                        </div>
                        <button className="btn btn-success mt-2 btn-md px-4 fw-normal" disabled={isTextareaDisabled}>
                            {submitLabel}
                        </button>
                        {hasCancelButton && (
                            <button
                                type="button"
                                className="btn btn-danger mt-2 btn-md px-4 fw-normal mx-2"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CommentForm;