import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactSelect from 'react-select';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import { getExpense, updateExpense } from '../../../features/expenseSlice';
import Loader from '../../../components/loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCompanies } from '../../../features/companySlice';
import moment from 'moment';
import { getAllEmployeesforCompany } from '../../../features/employeeSlice';

const paidBy = [
        { value: 'cash', label: 'Cash' },
        { value: 'check', label: 'Check' },
        { value: 'gpay', label: 'GPay' },
        { value: 'upi', label: 'UPI' }
];

const expenseEditSchema = yup.object({
        ItemName: yup.string().required('Name is required'),
        PurchaseFrom: yup.string().required('Purchase From is required'),
        PurchaseDate: yup.string().required('Purchase Date is required'),
        PurchaseBy: yup.string().required('Purchased By is required'),
        Amount: yup.string().required('Amount is required'),
        PaidBy: yup.string().required('Paid By is required'),
        Status: yup.string().required('Status is required'),
        Description: yup.string().required('Description is required'),
        Files: yup
                .mixed()
                .test('fileType', 'Only jpg, jpeg, and png files are allowed', (value) => {
                        if (value && value.length > 0) {
                                return value.every(file => ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type));
                        }
                        return true;
                })
                .nullable(),
        Receipt: yup.mixed().nullable(),
});

const statusOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Approved', label: 'Approved' }
];

export default function EditExpense() {
        const dispatch = useDispatch();
        const navigate = useNavigate()
        const { expenseIdentifier } = useParams();
        const { expense } = useSelector((state) => state.expense);
        const [selectedImage, setSelectImage] = useState([]);
        const [selectedDate, setSelectedDate] = useState(null);
        const { isLoading } = useSelector((state) => state.expense);
        const { companiesList } = useSelector((state) => state.company);
        const { companyEmployees } = useSelector((state) => state.employee);
        const [selectedCompanyId, setSelectedCompanyId] = useState("");

        console.log('isssss', expense?.data?.data);

        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10
        });

        const companyOptions = companiesList?.map(company => ({
                value: company?.companyIdentifier,
                label: company?.name
        }));

        const emloyeeOptions = companyEmployees?.map(employee => ({
                value: employee.employeeIdentifier,
                label: employee.name
        }));

        const { formState: { errors }, control, handleSubmit, setValue } = useForm({
                resolver: yupResolver(expenseEditSchema),
        });

        useEffect(() => {
                if (expense?.data?.data != null || expense?.data?.data != undefined) {
                        dispatch(getAllEmployeesforCompany(state.search, expense?.data?.data?.company, state.page, state.pagesize));
                }
        }, [dispatch, state.search, expense?.data?.data?.company, state.page, state.pagesize]);

        useEffect(() => {
                dispatch(getExpense(expenseIdentifier))
        }, [dispatch]);

        useEffect(() => {
                dispatch(getAllCompanies(state.search, state.page, state.pagesize));
        }, [])

        const handleDateChange = (date) => {
                setSelectedDate(date);
        };

        const handleFileChange = (e, onChange) => {
                const files = Array.from(e.target.files);
                setSelectImage(prevImages => [...prevImages, ...files]);
                onChange(files); // Set value in the form state using onChange from Controller
        };
        useEffect(() => {
                if (selectedCompanyId) {
                        dispatch(getAllEmployeesforCompany(state.search, selectedCompanyId, state.page, state.pagesize));
                }
        }, [selectedCompanyId, dispatch, state.search, state.page, state.pagesize]);

        useEffect(() => {
                if (expense) {
                        setValue('ItemName', expense?.data?.data?.itemName);
                        setValue('PurchaseFrom', expense?.data?.data?.purchaseFrom);
                        setValue('PurchaseDate', moment(expense?.data?.data?.purchaseDate));
                        setValue('PurchaseBy', expense?.data?.data?.purchaseBy)
                        setValue('Company', expense?.data?.data?.company);
                        setValue('Amount', expense?.data?.data?.amount);
                        setValue('Amount', expense?.data?.data?.amount);
                        setValue('Status', expense?.data?.data?.status);
                        setValue("Description", expense?.data?.data.description);
                        setValue("PaidBy", expense?.data?.data.paidBy)
                }
        }, [expense, setValue])

        const onSubmit = (data) => {
                const formData = new FormData();
                formData.append('ItemName', data?.ItemName);
                formData.append('PurchaseFrom', data?.PurchaseFrom);
                const formattedDate = data?.PurchaseDate ? moment(data.PurchaseDate).format('YYYY-MM-DD') : '';
                formData.append('PurchaseDate', formattedDate);
                formData.append('PurchaseBy', data?.PurchaseBy);
                formData.append('Amount', data?.Amount);
                formData.append('paidBy', data?.PaidBy);
                formData.append('Status', data?.Status);
                formData.append('expenseIdentifier', expenseIdentifier);
                formData.append('Description', data?.Description);
                // Check if Files exist and is an array before using forEach
                if (data.Files && Array.isArray(data.Files)) {
                        data.Files.forEach(file => formData.append('Files', file));
                }

                // data.Files.forEach(file => formData.append('Files', file));
                formData.append('Receipt', data?.Receipt);
                dispatch(updateExpense(formData));
                navigate('/admin/all-expense')
        }

        return (
                <div
                        id="edit_expense"
                        className="content container-fluid"
                >
                        <div className="page-header">
                                <div className="row align-items-center">
                                        <div className="col">
                                                <h3 className="page-title">Edit Expense</h3>
                                                <ul className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="/admin-dashboard">Dashboard</a></li>
                                                        <li className="breadcrumb-item active">Edit Tax</li>
                                                </ul>
                                        </div>
                                </div>
                        </div>
                        {
                                isLoading
                                        ?
                                        <Loader />
                                        :
                                        <div className="modal-body">
                                                <form onSubmit={handleSubmit(onsubmit)} >
                                                        <div className="row mb-3">
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="itemName">Item Name</label>
                                                                                <Controller
                                                                                        name="ItemName"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        className={`form-control mb-0`}
                                                                                                        type="text"
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                        defaultValue={expense?.data?.data?.itemName}
                                                                                />
                                                                                <span className='text-danger'>{errors?.ItemName?.message}</span>
                                                                        </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="purchaseFrom">Purchase From</label>
                                                                                <Controller
                                                                                        name="PurchaseFrom"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        className={`form-control mb-0`}
                                                                                                        type="text"
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                        defaultValue={expense?.data?.data?.purchaseFrom}
                                                                                />
                                                                                <span className='text-danger'>{errors?.PurchaseFrom?.message}</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="purchaseDate">Purchase Date</label>
                                                                                <Controller
                                                                                        name="PurchaseDate"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <DatePicker
                                                                                                        format="MM/DD/YYYY"
                                                                                                        placeholder="Select Date"
                                                                                                        onChange={(date) => {
                                                                                                                onChange(date ? moment(date).format('YYYY-MM-DD') : null);
                                                                                                                handleDateChange(date);
                                                                                                        }}
                                                                                                        value={value ? moment(value, 'YYYY-MM-DD') : null}
                                                                                                        className={`form-control mb-0`}
                                                                                                />
                                                                                        )}
                                                                                />

                                                                                <span className='text-danger'>{errors?.PurchaseDate?.message}</span>
                                                                        </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="Company">Company</label>
                                                                                <Controller
                                                                                        name="Company"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={companyOptions}
                                                                                                        value={companyOptions.find(option => option.value === value)}
                                                                                                        onChange={(selected) => {
                                                                                                                const selectedValue = selected ? selected.value : "";
                                                                                                                onChange(selectedValue); // Update react-hook-form state
                                                                                                                setSelectedCompanyId(selectedValue); // Update local state
                                                                                                        }}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                />
                                                                                        )}
                                                                                        defaultValue={expense?.data?.data?.company}
                                                                                />
                                                                                <span className='text-danger'>{errors?.PaidBy?.message}</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="amount">Amount</label>
                                                                                <Controller
                                                                                        name="Amount"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        className={`form-control mb-0`}
                                                                                                        type="text"
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <span className='text-danger'>{errors?.Amount?.message}</span>
                                                                        </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="PurchaseBy">Purchased By</label>
                                                                                <Controller
                                                                                        name="PurchaseBy"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={emloyeeOptions}
                                                                                                        value={emloyeeOptions.find(option => option.value === value)}
                                                                                                        onChange={selected => onChange(selected?.value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <span className='text-danger'>{errors?.PurchaseBy?.message}</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="status">Status</label>
                                                                                <Controller
                                                                                        name="Status"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={statusOptions}
                                                                                                        value={statusOptions.find(option => option.value === value)}
                                                                                                        onChange={selected => onChange(selected?.value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <span className='text-danger'>{errors?.Status?.message}</span>
                                                                        </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                        <label htmlFor="Description">Description</label>
                                                                        <div className="form-group">
                                                                                <Controller
                                                                                        name="Description"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <textarea className="form-control" rows={3} value={value} onChange={onChange} />
                                                                                        )}
                                                                                />
                                                                                <span className='text-danger'>{errors?.Description?.message}</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className='row'>
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="attachments">Attachments</label>
                                                                                <Controller
                                                                                        name="Files"
                                                                                        control={control}
                                                                                        render={({ field: { onChange, value } }) => (
                                                                                                <input
                                                                                                        className={`form-control mb-0`}
                                                                                                        type="file"
                                                                                                        multiple
                                                                                                        onChange={(e) => handleFileChange(e, onChange)}
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <span className='text-danger'>{errors?.Files?.message}</span>
                                                                        </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="paidBy">Paid By</label>
                                                                                <Controller
                                                                                        name="PaidBy"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={paidBy}
                                                                                                        value={paidBy.find(option => option.value === value)}
                                                                                                        onChange={selected => onChange(selected?.value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <span className='text-danger'>{errors?.PaidBy?.message}</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className='row'>
                                                                <div className="col-md-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="Receipt">Receipt</label>
                                                                                <Controller
                                                                                        name="Receipt"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        className={`form-control mb-0`}
                                                                                                        type="file"
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <span className='text-danger'>{errors?.Receipt?.message}</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="mb-3">
                                                                <ul className="list-unstyled">
                                                                        {selectedImage.map((image, index) => (
                                                                                <li className="d-flex align-items-center mb-2 position-relative" key={index}>
                                                                                        <img src={URL.createObjectURL(image)} alt="attachment" className="me-2" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                                                        <span style={{ position: 'absolute', top: 0, }} className="btn btn-danger btn-sm" onClick={() => {
                                                                                                setSelectImage(prevImages => prevImages.filter((_, i) => i !== index));
                                                                                        }}><i className="fa fa-close"></i></span>
                                                                                </li>
                                                                        ))}
                                                                </ul>
                                                        </div>
                                                        <div className="text-center mt-4">
                                                                <button type="submit" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>Submit</button>
                                                        </div>
                                                </form>
                                        </div>
                        }
                </div>
        )
}
