import React from 'react'
import { attendance, collective, time } from '../../../assets/assets2/images'

function IntegrationArea() {
    return (
        <section id="integration" className="integration-area pb-90">
            <div className="container">
                <div className="section-title">
                    <span className="tag">Integrations</span>
                    <h2 className="title">Build for your workflow</h2>
                </div>
                <div className="row justify-content-center gx-xl-5">
                    <div className="col-md-4 col-sm-6">
                        <div className="item-single mb-30">
                            <div className="icon">
                                <img src={attendance} alt="Git" />
                            </div>
                            <div className="content">
                                <h3>Attendance</h3>
                                <p>To help improve your personal and your productivity Samu ties into.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="item-single mb-30">
                            <div className="icon">
                                <img src={time} alt="Git" />
                            </div>
                            <div className="content">
                                <h3>Time Track</h3>
                                <p>To help improve your personal and your productivity Samu ties into.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="item-single mb-30">
                            <div className="icon">
                                <img src={collective} alt="Git" />
                            </div>
                            <div className="content">
                                <h3>Group Work</h3>
                                <p>To help improve your personal and your productivity Samu ties into.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IntegrationArea