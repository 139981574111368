import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactSelect from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import Loader from '../../../components/loader/Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanySize, CompanyType } from '../../../constant';
import { useSelector } from 'react-redux';
import { addCompany } from '../../../features/companySlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { eyeButtonClass } from '../../../assets/css';
import { addCompanySchema } from '../../../formSchemas/companySchema';

function AddCompany() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { isLoading } = useSelector(state => state.company);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(addCompanySchema),
    });

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("email", data.email);
        formData.append("name", data.name);
        formData.append("password", data.password);
        formData.append("confirmPassword", data.confirmPassword);
        formData.append("address", data.address);
        formData.append("country", data.country);
        formData.append("contact", data.contact);
        formData.append("website", data.website);
        formData.append("registrationNumber", data.registrationNumber);
        formData.append("taxIdentificationNumber", data.taxIdentificationNumber);
        formData.append("foundingDate", data.foundingDate.toISOString());
        formData.append("businessType", data.businessType);
        formData.append("companySize", data.companySize);
        formData.append("CEO", data.CEO);
        formData.append("founder", data.founder);
        formData.append("ceoProfileUrl", data.ceoProfileUrl);
        formData.append("founderProfileUrl", data.founderProfileUrl);
        formData.append("companyProfilePhoto", data.companyProfilePhoto);
        dispatch(addCompany(formData, navigate));
    };

    return (
        <>
            <Helmet>
                <title>Add Company</title>
                <meta name="description" content="Add Company page" />
            </Helmet>
            {isLoading ? <Loader /> : ("")}
            <div className="account-content">
                <div className="container">
                    <h3 className="account-title">Add Company</h3>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="fields-type mt-2">
                                    <h4>Basic Information:</h4>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.email ? "error-input mb-0" : ""
                                                        }`}
                                                    type="email"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors?.email?.message ? <small>{errors?.email?.message}</small> : ""}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.name ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors?.name?.message ? <small>{errors?.name?.message}</small> : ""}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <div className="input-group">
                                                    <input
                                                        className={`form-control mb-0 ${errors?.password ? "error-input mb-0" : ""}`}
                                                        type={showPassword ? "text" : "password"}
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="off"
                                                    />
                                                    <button
                                                        className={`btn btn-outline-secondary ${eyeButtonClass} ${errors?.password ? "error-input mb-0" : ""}`}
                                                        type="button"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                                                    </button>
                                                </div>
                                            )}
                                            defaultValue=""
                                        />
                                        {errors?.password?.message ? <small>{errors?.password?.message}</small> : ""}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <Controller
                                            name="confirmPassword"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <div className="input-group">
                                                    <input
                                                        className={`form-control mb-0 ${errors?.confirmPassword ? "error-input mb-0" : ""}`}
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="off"
                                                    />
                                                    <button
                                                        className={`btn btn-outline-secondary ${eyeButtonClass} ${errors?.confirmPassword ? "error-input mb-0" : ""}`}
                                                        type="button"
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    >
                                                        {showConfirmPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                                                    </button>
                                                </div>
                                            )}
                                            defaultValue=""
                                        />
                                        {errors?.confirmPassword?.message ? <small>{errors?.confirmPassword?.message}</small> : ""}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <Controller
                                            name="address"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.address ? "error-input mb-0" : ""}`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.address && <small>{errors?.address?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Country</label>
                                        <Controller
                                            name="country"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.country ? "error-input mb-0" : ""}`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.country && <small>{errors?.country?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Contact Number</label>
                                        <Controller
                                            name="contact"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <PhoneInput
                                                    className={`form-control mb-0 ${errors?.contact ? "error-input" : ""}`}
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="IN"
                                                    value={value}
                                                    initialValueFormat="national"
                                                    onChange={onChange}
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.contact && <small>{errors?.contact?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Website</label>
                                        <Controller
                                            name="website"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.website ? "error-input mb-0" : ""}`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.website && <small>{errors?.website?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Registration Number</label>
                                        <Controller
                                            name="registrationNumber"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.registrationNumber ? "error-input mb-0" : ""}`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.registrationNumber && <small>{errors?.registrationNumber?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Tax Identification Number</label>
                                        <Controller
                                            name="taxIdentificationNumber"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.taxIdentificationNumber ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.taxIdentificationNumber && <small>{errors?.taxIdentificationNumber?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Founding Date</label>
                                        <Controller
                                            name="foundingDate"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.foundingDate ? "error-input mb-0" : ""
                                                        }`}
                                                    type="date"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.foundingDate && <small>{errors?.foundingDate?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Business Type</label>
                                        <Controller
                                            name="businessType"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <ReactSelect
                                                    options={CompanyType}
                                                    value={CompanyType.find(option => option.value === value)}
                                                    onChange={selected => onChange(selected?.value)}
                                                    isClearable
                                                    isSearchable
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.businessType && <small>{errors?.businessType?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Company Size</label>
                                        <Controller
                                            name="companySize"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <ReactSelect
                                                    options={CompanySize}
                                                    value={CompanySize.find(option => option.value === value)}
                                                    onChange={selected => onChange(selected?.value)}
                                                    isClearable
                                                    isSearchable
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.companySize && <small>{errors?.companySize?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>CEO</label>
                                        <Controller
                                            name="CEO"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.CEO ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.CEO && <small>{errors?.CEO?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Founder</label>
                                        <Controller
                                            name="founder"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.founder ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="off"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors.founder && <small>{errors?.founder?.message}</small>}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>CEO Profile Photo</label>
                                        <Controller
                                            name="ceoProfileUrl"
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <div className="input-group">
                                                    <input
                                                        type="file"
                                                        onChange={(e) => onChange(e.target.files[0])}
                                                        onBlur={onBlur}
                                                        accept="image/*"
                                                        className="form-control form-control-lg"
                                                    />
                                                </div>
                                            )}
                                        />
                                        {errors.ceoProfileUrl && (
                                            <small>{errors?.ceoProfileUrl?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Founder Profile Photo</label>
                                        <Controller
                                            name="founderProfileUrl"
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <div className="input-group">
                                                    <input
                                                        type="file"
                                                        onChange={(e) => onChange(e.target.files[0])}
                                                        onBlur={onBlur}
                                                        accept="image/*"
                                                        className="form-control form-control-lg"
                                                    />
                                                </div>
                                            )}
                                        />
                                        {errors.founderProfileUrl && (
                                            <small>{errors?.founderProfileUrl?.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Company's Image</label>
                                        <Controller
                                            name="companyProfilePhoto"
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <div className="input-group">
                                                    <input
                                                        type="file"
                                                        onChange={(e) => onChange(e.target.files[0])}
                                                        onBlur={onBlur}
                                                        accept="image/*"
                                                        className="form-control form-control-lg"
                                                    />
                                                </div>
                                            )}
                                        />
                                        {errors.companyProfilePhoto && (
                                            <small>{errors?.companyProfilePhoto?.message}</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group text-center">
                                <button
                                    className="btn btn-primary account-btn"
                                    type="submit"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AddCompany