import { createSlice } from "@reduxjs/toolkit";
import httpHandler from "../utils/httpHandler";
import { toast } from "react-hot-toast";

const departmentSlice = createSlice({
  name: "department",
  initialState: {
    departments: [],
    isLoading: false,
    department: null,
    companyDepartments: [],
    companyDepartmentsCount: null,
  },
  reducers: {
    addDepartmentSuccess: (state, action) => {
      state.isLoading = false;
    },
    addDepartmentFailure: (state, action) => {
      state.isLoading = false;
    },
    getDepartmentsSuccess: (state, action) => {
      state.departments = action.payload;
      state.isLoading = false;
    },
    getDepartmentsFailure: (state, action) => {
      state.departments = null;
      state.isLoading = false;
    },
    updateDepartmentSuccess: (state, action) => {
      state.isLoading = false;
    },

    updateDepartmentFailure: (state, action) => {
      state.isLoading = false;
    },
    deleteDepartmentSuccess: (state, action) => {
      const departmentIdentifier = action.payload.departmentIdentifier;
      state.departments = state.departments.filter(
        (department) => department.departmentIdentifier !== departmentIdentifier
      );
      state.isLoading = false;
    },
    deleteDepartmenFailure: (state, action) => {
      state.isLoading = false;
    },
    getDepartmentSuccess: (state, action) => {
      state.department = action.payload.data;
      state.isLoading = false;
    },
    getDepartmentFailure: (state, action) => {
      state.department = null;
      state.isLoading = false;
    },
    getAllCompanyDepartmentsSuccess: (state, action) => {
      state.companyDepartments = action.payload.data;
      state.companyDepartmentsCount = action.payload.count;
      state.isLoading = false;
    },
    getAllCompanyDepartmentsFailure: (state, action) => {
      state.companyDepartments = [];
      state.companyDepartmentsCount = null;
      state.isLoading = false;
    },
    addCompanyDepartmentSuccess: (state, action) => {
      state.isLoading = false;
    },
    addCompanyDepartmentFailure: (state, action) => {
      state.isLoading = false;
    },
    deleteCompanyDepartmentSuccess: (state, action) => {
      const departmentIdentifier = action.payload.id;
      state.companyDepartments = state.companyDepartments.filter(
        (department) => department.departmentIdentifier !== departmentIdentifier
      );
      state.companyDepartmentsCount = state.companyDepartmentsCount - 1;
      state.isLoading = false;
    },
    deleteCompanyDepartmentFailure: (state, action) => {
      state.isLoading = false;
    },
    updateCompanyDepartmentSuccess: (state, action) => {
      state.isLoading = false;
    },
    updateCompanyDepartmentFailure: (state, action) => {
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = true;
    },
  },
});

export const getAllDepartments =
  (search, page, pagesize) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const response = await httpHandler.get(
        `/api/Department/getAllDepartments?search=${search}&pageNumber=${page}&pageSize=${pagesize}&companyIdentifier=`
      );
      if (response.data.success) {
        dispatch(getDepartmentsSuccess(response.data));
      } else {
        dispatch(getDepartmentsFailure(response.data.message));
      }
    } catch (error) {
      dispatch(getDepartmentsFailure(error.message));
    }
  };

export const getDepartment = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.get(`/api/Department/getDepartment/${id}`);
    if (response.data.success) {
      dispatch(getDepartmentSuccess(response.data));
    }
    else {
      dispatch(getDepartmentFailure());
      toast.error(response.data.message);
    }
  } catch (error) {
    dispatch(getDepartmentFailure());
    toast.error(error.message);
  }
};

export const addDepartment = (data, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.post(
      `/api/Department/createDepartment`,
      data
    );

    if (response.data.success) {
      toast.success(response.data.message);
      dispatch(addDepartmentSuccess(response.data));
      navigate("/departments");
    } else {
      dispatch(addDepartmentFailure(response.data.message));
      toast.error();
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(addDepartmentFailure());
  }
};

export const updateDepartment = (data) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
    await httpHandler.post(`/api/Department/updateDepartment`, data);
    toast.success("Updated Successfully");
    dispatch(updateDepartmentSuccess());
    var search = "";
    dispatch(getAllDepartments(search, 1, 10));;
  } catch (error) {
    toast.error(error.message);
    dispatch(updateDepartmentFailure(error));
  }
};

export const deleteDepartment = (id, department) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await httpHandler.post(`/api/Department/deleteDepartment/${id}`);
    var search = "";
    if (department === true) {
      toast.success("Deleted Successfully");
      dispatch(deleteDepartmentSuccess({ id }));
      dispatch(getAllDepartments(search, 1, 10));
    } else {
      dispatch(getAllDepartments(search, 1, 10));
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(deleteDepartmenFailure(error));
  }
};

export const getAllCompanyDepartments = (search, page, pagesize, companyID) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.get(
      `/api/Department/getAllDepartments?search=${search}&pageNumber=${page}&pageSize=${pagesize}&companyIdentifier=${companyID}`
    );
    if (response.data.success) {
      dispatch(getAllCompanyDepartmentsSuccess(response.data));
    }
    else {
      dispatch(getAllCompanyDepartmentsFailure());
      toast.error(response.data.message);
    }
  } catch (error) {
    dispatch(getAllCompanyDepartmentsFailure(error.message));
    toast.error(error.message);
  }
};

export const addCompanyDepartment = (data, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.post(`/api/Department/createDepartment`, data);

    if (response.data.success) {
      dispatch(addCompanyDepartmentSuccess(response.data));
      toast.success(response.data.message);
      navigate(`/company/company-departments/${data.company}`);
    } else {
      dispatch(addCompanyDepartmentFailure());
      toast.error(response.data.message);
    }
  } catch (error) {
    dispatch(addCompanyDepartmentFailure());
    toast.error(error.message);
  }
};

export const deleteCompanyDepartment = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    var response = await httpHandler.post(`/api/Department/deleteDepartment/${id}`);
    if (response.data.success) {
      dispatch(deleteCompanyDepartmentSuccess({ id }));
      toast.success(response.data.message);
    }
    else {
      deleteCompanyDepartmentFailure();
      toast.error(response.data.message);
    }
  } catch (error) {
    dispatch(deleteCompanyDepartmentFailure());
    toast.error(error.message);
  }
};

export const updateCompanyDepartment = (data, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.post(`/api/Department/updateDepartment`, data);
    if (response.data.success) {
      dispatch(updateCompanyDepartmentSuccess());
      toast.success(response.data.message);
      navigate(`/company/company-departments/${data.companyID}`)
    }
    else {
      dispatch(updateCompanyDepartmentFailure());
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(updateCompanyDepartmentFailure());
  }
};

export const {
  addDepartmentSuccess,
  addDepartmentFailure,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  updateDepartmentSuccess,
  updateDepartmentFailure,
  getDepartmentSuccess,
  getDepartmentFailure,
  deleteDepartmentSuccess,
  deleteDepartmenFailure,
  getAllCompanyDepartmentsSuccess,
  getAllCompanyDepartmentsFailure,
  addCompanyDepartmentSuccess,
  addCompanyDepartmentFailure,
  deleteCompanyDepartmentSuccess,
  deleteCompanyDepartmentFailure,
  updateCompanyDepartmentSuccess,
  updateCompanyDepartmentFailure,
  setLoading,
} = departmentSlice.actions;

export default departmentSlice.reducer;
