import { createSlice } from "@reduxjs/toolkit";
import httpHandler from "../utils/httpHandler";
import { toast } from "react-hot-toast";
import { ROLES } from "../constant";

const url = "/api/user/account/";
const authLoginSlice = createSlice({
  name: "userLogin",
  initialState: {
    user: null,
    isLoading: false,
    error: "",
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.data;
      state.isLoading = false;
    },
    loginFailure: (state) => {
      state.user = null;
      state.isLoading = false;
    },
    logoutSuccess: (state) => {
      state.user = null;
      state.isLoading = false;
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    updatePasswordSuccess: (state) => {
      state.error = "";
      state.isLoading = false;
    },
    updatePasswordFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    forgotPasswordSuccess: (state) => {
      state.isLoading = false;
    },
    forgotPasswordFailure: (state) => {
      state.isLoading = false;
    },
    resetPasswordSuccess: (state) => {
      state.isLoading = false;
    },
    resetPasswordFailure: (state) => {
      state.isLoading = false;
    },
    updateProfilePictureSuccess: (state, action) => {
      state.isLoading = false;
      var data = JSON.parse(localStorage.getItem("user"));
      data.userProfile = action.payload.data;
      state.user = data;
      localStorage.setItem("user", JSON.stringify(data));
    }
  },
});

export const loginUser = (data, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await httpHandler.post(`${url}loginUser`, data);
    if (response.data.success) {
      const user = response.data.data;
      localStorage.setItem("user", JSON.stringify(user));
      toast.success(response.data.message);
      let currentDate = new Date().getTime();
      let expirationDate = new Date(user.expiryDate).getTime();
      let expiration = expirationDate - currentDate;
      dispatch(autologoutUser(expiration, navigate));

      if (user.role === ROLES.Admin) navigate("/admin/dashboard");
      else if (user.role === ROLES.Company) navigate("/company/dashboard");
      else if (user.role === ROLES.Employee) navigate("/employee/dashboard");
      else {
        toast.success("Ask Admin to assign you a role.");
      }
      dispatch(loginSuccess(response.data));
    } else {
      toast.error(response.data.message);
      dispatch(loginFailure());
    }
  } catch (error) {
    toast.error(error.message || "Internal Server Error");
    dispatch(loginFailure());
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch(setLoading());
  localStorage.removeItem("user");
  dispatch(logoutSuccess());
  toast.success("LoggedOut Successfully");
};

export const autologoutUser =
  (expirationDate, navigate) => async (dispatch) => {
    setTimeout(() => {
      dispatch(logoutUser());
      navigate("/login");
    }, expirationDate);
  };

export const updatePassword = (data, navigate, reset) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await httpHandler.post(`${url}changePassword`, data);
    if (response.data.success) {
      toast.success(response.data.message);
      navigate(user.role === ROLES.Admin ? "/admin/profile" : "/user-profile");
      dispatch(updatePasswordSuccess());
      reset();
    } else {
      toast.error(response.data.message);
      dispatch(updatePasswordFailure(response.data.message));
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(updatePasswordFailure(error.message));
  }
};

export const forgotPassword = (email, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.get(`${url}forgotPassword/${email}`);
    if (response.data.success) {
      toast.success(response.data.message);
      dispatch(forgotPasswordSuccess());
      navigate(`/reset-password/${response.data.data}`);
    } else {
      toast.error(response.data.message);
      dispatch(forgotPasswordFailure());
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(forgotPasswordFailure());
  }
};

export const resetPassword = (data, userId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await httpHandler.post(`${url}resetPassword/${userId}`, data);
    if (response.data.success) {
      toast.success(response.data.message);
      dispatch(resetPasswordSuccess());
      navigate("/login");
    } else {
      toast.error(response.data.message);
      dispatch(resetPasswordFailure());
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(resetPasswordFailure());
  }
};

export const updateProfilePicture = (data) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await httpHandler.post(`${url}updateProfilePicture`, data);
    if (response.data.success) {
      toast.success(response.data.message);
      dispatch(updateProfilePictureSuccess(response.data));
    }
  } catch (error) {
  }
}
export const {
  loginSuccess,
  loginFailure,
  setLoading,
  logoutSuccess,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  updatePasswordSuccess,
  updatePasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  updateProfilePictureSuccess,
} = authLoginSlice.actions;
export default authLoginSlice.reducer;
