import React from 'react'
import { icon_1, icon_2, icon_3, icon_4, icon_5, icon_6 } from '../../../assets/assets2/images'

function ServicesArea() {
    return (
        <>
            <section className="services-area services-2 pt-90">
                <div className="container">
                    <div className="section-title">
                        <h2 className="title">Our Featured Services</h2>
                        <p>Lets you manage software development and track bugs. Crown streamlined design is built for speed and efficiency helping high performing team.</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single text-center mb-30">
                                <div className="icon">
                                    <img src={icon_1} alt="Icon" />
                                </div>
                                <h3>Software Development</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consectetur luctus tellus, a aliquam.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single text-center mb-30">
                                <div className="icon">
                                    <img src={icon_2} alt="Icon" />
                                </div>
                                <h3>Maintenance</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consectetur luctus tellus, a aliquam.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single text-center mb-30">
                                <div className="icon">
                                    <img src={icon_3} alt="Icon" />
                                </div>
                                <h3>Bug Solving</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consectetur luctus tellus, a aliquam.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single text-center mb-30">
                                <div className="icon">
                                    <img src={icon_4} alt="Icon" />
                                </div>
                                <h3>Customized Solution</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consectetur luctus tellus, a aliquam.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single text-center mb-30">
                                <div className="icon">
                                    <img src={icon_5} alt="Icon" />
                                </div>
                                <h3>UI/UX Design</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consectetur luctus tellus, a aliquam.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single text-center mb-30">
                                <div className="icon">
                                    <img src={icon_6} alt="Icon" />
                                </div>
                                <h3>24/7 Free Support</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consectetur luctus tellus, a aliquam.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServicesArea