import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const UnAuthorized = () => {
    return (
        <>
            <Helmet>
                <title>UnAuthorized - HRMS Admin Template</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <div className="error-box">
                <h1>UnAuthorized</h1>
                <h3><i className="fa fa-warning" /> Oops!</h3>
                <p> You are not authorized to this page!</p>
                <Link onClick={() => localStorage.setItem("firstload", "true")} to="/home" className="btn btn-custom">Back to Home</Link >
            </div>
        </>
    );
}

export default UnAuthorized;
