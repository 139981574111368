import React from 'react'
import { Link } from 'react-router-dom'
import ServicesArea from './components/ServicesArea'
import ChooseArea from './components/ChooseArea'
import AboutArea from './components/aboutArea'
import PricingArea from '../../components/PricingArea'
import FaqArea from './components/FaqArea'

function Services() {
    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="content text-center">
                        <h1>Our Services</h1>
                        <ul className="list-unstyled">
                            <li className="d-inline"><Link to="/home">Home</Link></li>
                            <li className="d-inline">/</li>
                            <li className="d-inline active">Services</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ServicesArea />
            <ChooseArea />
            <AboutArea />
            <PricingArea />
            <FaqArea />
        </>
    )
}

export default Services