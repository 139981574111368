import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { client_4, client_5, client_6, placeholder } from '../../../assets/assets2/images';
import { FaStar } from 'react-icons/fa';

function TestimonialArea() {
    const options = {
        margin: 40,
        autoplayTimeout: 6500,
        smartSpeed: 500,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },

            992: {
                items: 2,
            }
        }
    };

    const testimonials = [
        {
            id: 1,
            image: client_4,
            name: 'Maria Holder',
            rating: 5,
            text: 'Their team has led, scaled and delivered large scale software and design projects at multiple startups and high-growth environments',
        },
        {
            id: 2,
            image: client_5,
            name: 'Arnold Henry',
            rating: 5,
            text: 'Their team has led, scaled and delivered large scale software and design projects at multiple startups and high-growth environments',
        },
        {
            id: 3,
            image: client_6,
            name: 'Elisa Mos',
            rating: 5,
            text: 'Their team has led, scaled and delivered large scale software and design projects at multiple startups and high-growth environments',
        },
    ];
    return (
        <section id="testimonial" className="testimonial-area testimonial-2 ptb-120">
            <div className="container">
                <div className="section-title">
                    <h2 className="title">400,000 users around the world, love us with great trust</h2>
                </div>
                <OwlCarousel className="testimonial-slider owl-carousel" {...options}>
                    {testimonials.map((testimonial) => (
                        <div className="slider-item" key={testimonial.id}>
                            <div className="image">
                                <div className="lazy-container aspect-ratio aspect-ratio-1-1">
                                    <img className="lazyload lazy-image" src={placeholder} data-src={testimonial.image} alt="Person" />
                                </div>
                            </div>
                            <div className="content">
                                <p className="text">{testimonial.text}</p>
                                <div className="review yellow">
                                    {[...Array(testimonial.rating)].map((_, i) => (
                                        <i key={i}><FaStar/></i>
                                    ))}
                                </div>
                                <h3 className="name">{testimonial.name}</h3>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </section>
    );
}

export default TestimonialArea;
