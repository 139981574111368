import React from "react";
import "./loader.css";

function Loader() {
  return (
    <>
      <div className="loader">
        <div className="spinner">
        </div>
      </div>
    </>
  )
}
export default Loader;
