import axios from "axios";
function getToken() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    return user.token;
  }
  return "";
}

const get = (url) => {
  return new Promise((resolve, reject) => {
    let token = getToken();

    axios
      .get(`${process.env.REACT_APP_API_URL}${url}`, {
        maxRedirects: 0,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const post = (url, params, opt) => {
  return new Promise((resolve, reject) => {
    let token = getToken();
    if (!opt) opt = {};
    opt = {
      ...opt,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}${url}`, params, opt)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const put = (url, params, opt) => {
  return new Promise((resolve, reject) => {
    let token = getToken();
    if (!opt) opt = {};
    opt = {
      ...opt,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}${url}`, params, opt)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const http = { get, post, put };

export default http;
