import React from 'react'
import { Link } from 'react-router-dom'
import { about_2 } from '../../../assets/assets2/images'

function AboutArea() {
    return (
        <>
            <section id="about" className="about-area about-2 pb-90">
                <div className="container-fluid p-0">
                    <div className="row align-items-center justify-content-lg-end">
                        <div className="col-lg-auto left-content">
                            <div className="section-title">
                                <h2 className="title">Get notified about
                                    important mail</h2>
                                <p>Sed nec iaculis tortor, ac vulputate augue. Quisque mollis sapien ndit, scelerisque neque eget, finibus justo. Maecenas dapibus uere justo, et.</p>
                            </div>
                            <div className="item-single mb-30">
                                <div className="icon">
                                    <i className="fal fa-webcam"></i>
                                </div>
                                <div className="content">
                                    <h3>Video Conference</h3>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout the point</p>
                                </div>
                            </div>
                            <div className="item-single mb-30">
                                <div className="icon">
                                    <i className="fal fa-images"></i>
                                </div>
                                <div className="content">
                                    <h3>Photo Editing</h3>
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout the point</p>
                                </div>
                            </div>
                            <hr className="mt-0 mb-30"/>
                                <div className="cta-btns mb-30">
                                    <Link to="/contact" className="btn secondary-btn secondary-btn-3">Get Started Now</Link>
                                </div>
                        </div>
                        <div className="col-lg-6 right-content">
                            <div className="image mb-30">
                                <img src={about_2} alt="about"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutArea