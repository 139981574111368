import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import * as yup from "yup";
import { updatePassword } from "../../features/authLoginSlice";
import { useSelector } from "react-redux";

const schema = yup
  .object({
    currentPassword: yup.string().required("Old Password is required").min(6).trim(),
    newPassword: yup.string().required("New Password is required").min(6).trim(),
    confirmNewPassword: yup.string().required("Confirm Password is required")
      .oneOf([yup.ref("newPassword"), null], "Password must match").trim(),
  })
  .required();

function UpdatePasswordModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const { error } = useSelector((state) => state.userLogin);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    dispatch(updatePassword(data, navigate, reset));
    props.toggleUpdatePasswordModal();
  };

  return (
    <>
      <Modal
        isOpen={props.updatePasswordModalOpen}
        toggle={() => props.toggleUpdatePasswordModal()}
      >
        <ModalHeader toggle={() => props.toggleUpdatePasswordModal()}>
          Update Password
        </ModalHeader>
        <ModalBody>
          <div className="error">{error}</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group" style={{ display: "none" }}>
              <label>Email</label>
              <Controller
                name="email"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <input
                    className={`form-control  ${errors?.email ? "error-input" : ""
                      }`}
                    type="text"
                    value={value}
                    onChange={onChange}
                    autoComplete="false"
                    readOnly
                    disabled
                  />
                )}
                defaultValue={props.user.email}
              />
              <small>{errors?.email?.message}</small>
            </div>
            <div className="form-group">
              <label>Current Password</label>
              <Controller
                name="currentPassword"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="input-group">
                    <input
                      className={`form-control mb-0${errors?.currentPassword ? "error-input mb-0" : ""
                        }`}
                      type={showCurrentPassword ? "text" : "password"}
                      value={value}
                      onChange={onChange}
                      autoComplete="false"
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                    >
                      {showCurrentPassword ? "🔓" : "🙈"}
                    </button>
                  </div>
                )}
                defaultValue=""
              />
              <small>{errors?.currentPassword?.message}</small>
            </div>
            <div className="form-group">
              <label>New Password</label>
              <Controller
                name="newPassword"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="input-group">
                    <input
                      className={`form-control mb-0${errors?.newPassword ? "error-input mb-0" : ""
                        }`}
                      type={showNewPassword ? "text" : "password"}
                      value={value}
                      onChange={onChange}
                      autoComplete="false"
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? "🔓" : "🙈"}
                    </button>
                  </div>
                )}
                defaultValue=""
              />

              <small>{errors?.newPassword?.message}</small>
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <Controller
                name="confirmNewPassword"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="input-group">
                    <input
                      className={`form-control mb-0${errors?.confirmNewPassword ? "error-input mb-0" : ""
                        }`}
                      type={showConfirmNewPassword ? "text" : "password"}
                      value={value}
                      onChange={onChange}
                      autoComplete="false"
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() =>
                        setShowConfirmNewPassword(!showConfirmNewPassword)
                      }
                    >
                      {showConfirmNewPassword ? "🔓" : "🙈"}
                    </button>
                  </div>
                )}
                defaultValue=""
              />
              <small>{errors?.confirmNewPassword?.message}</small>
            </div>
            <div className="form-group text-center">
              <button className="btn btn-primary account-btn" type="submit">
                Update
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default UpdatePasswordModal;
