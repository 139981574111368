import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logo_1, logo_2 } from '../../assets/assets2/images';

const HomeNav = () => {

    useEffect(() => {
        function handleScroll() {
          const header = document.querySelector('.sticky-header');
          if (window.scrollY >= 200) {
            header.classList.add('is-sticky');
          } else {
            header.classList.remove('is-sticky');
          }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
        <>
            <header id="header" className="header-area header-2">
                <div className="main-navbar-area sticky-header">
                    <div className="main-responsive-nav">
                        <div className="container">
                            <div className="main-responsive-menu">
                                <div className="logo">
                                    <Link to={"/home"}>
                                        <img src={logo_1} alt="logo" />
                                        <img src={logo_2} alt="logo" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-nav">
                        <div className="container">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <Link className="navbar-brand" to="/home">
                                    <img src={logo_1} alt="logo" />
                                    <img src={logo_2} alt="logo" />
                                </Link>
                                <div className="collapse navbar-collapse mean-menu">
                                    <ul className="navbar-nav ms-auto" id="menu">
                                        <li className="nav-item">
                                            <Link to="/home" className="nav-link toggle active">Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/who-we-are" className="nav-link toggle">Who We are</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/how-it-work" className="nav-link toggle">How it works</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/services" className="nav-link toggle">Services</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/blog" className="nav-link">Blog</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/contact" className="nav-link">Contact</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/login" className="nav-link">Login</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HomeNav