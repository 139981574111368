import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../features/authLoginSlice';
import Loader from '../loader/Loader';
import { useSelector } from 'react-redux';

function ForgotPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.userLogin);
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const email = formData.get('email');
        dispatch(forgotPassword(email, navigate));
    }

    return (
        <>
            <Helmet>
                <title>Forgot Password - CHS</title>
                <meta name="description" content="Login page" />
            </Helmet>
            {isLoading ? <Loader /> : ""}
            <div className="account-content">
                <div className="container">
                    <div className="account-box">
                        <div className="account-wrapper">
                            <h3 className="account-title">Forgot Password?</h3>
                            <p className="account-subtitle">Enter your email</p>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <input className="form-control" type="email" name="email" required/>
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn btn-primary account-btn" type="submit">Reset Password</button>
                                </div>
                                <div className="account-footer">
                                    <p>Remember your password? <Link to="/login">Login</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
