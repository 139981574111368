import { createSlice } from "@reduxjs/toolkit";
import httpHandler from "../utils/httpHandler";
import { toast } from "react-hot-toast";

const url = "/api/user/account/";
const authRegisterSlice = createSlice({
  name: "userRegister",
  initialState: {
    isLoading: false,
  },
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    registerSuccess: (state, action) => {
      state.isLoading = false;
    },
    registerFailure: (state, action) => {
      state.isLoading = false;
    },
    verifyEmailSuccess: (state, action) => {
      state.isLoading = false;
    },
    verifyEmailFailure: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const registerUser = (data, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await httpHandler.post(`${url}registerUser`, data);
    if (response.data.success) {
      dispatch(registerSuccess());
      toast.success(response.data.message);
      navigate("/login");
    } else {
      toast.error(response.data.message);
      dispatch(registerFailure());
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(registerFailure());
  }
};

export const verifyEmail = (id, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await httpHandler.post(`${url}verifyEmail/${id}`);
    if (response.data.success) {
      navigate("/login");
      dispatch(verifyEmailSuccess());
      toast.success(response.data.message);
    } else {
      dispatch(verifyEmailFailure());
      navigate("/login");
      toast.error(response.data.message);
    }
  } catch (error) {
    navigate("/login");
    dispatch(verifyEmailFailure());
    toast.error(error.message);
  }
};

export const { registerSuccess, registerFailure, verifyEmailSuccess, verifyEmailFailure, setLoading } =
  authRegisterSlice.actions;
export default authRegisterSlice.reducer;
