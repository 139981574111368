import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTicket, getComments, deleteAttachment, addAttachment } from '../../features/ticketsSlice';
import { genericImg } from '../../assets/img';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import AssigneeModal from './AssigneeModal';
import Loader from '../loader/Loader';
import * as signalR from '@microsoft/signalr';
import httpHandler from '../../utils/httpHandler';
import Fancybox from '../Fancybox';
import { ROLES } from '../../constant';

function TicketView() {
    const user = JSON.parse(localStorage.getItem("user"));
    const { ticketId } = useParams();
    const dispatch = useDispatch();
    const { isLoading, ticket, images, files } = useSelector(state => state.ticket);
    const [addAssigneeModal, setAddAssigneeModal] = useState(false);
    const toggleAddAssigneeModal = () => setAddAssigneeModal(!addAssigneeModal);
    const [connection, setConnection] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [tickteComments, setTicketComments] = useState([]);

    useEffect(() => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL + '/ticketCommentHub?ticketId=' + ticketId, {
                accessTokenFactory: () => user.token,
            })
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
    }, [user.token, ticketId]);

    useEffect(() => {
        if (connection) {
            connection.start().then(() => {
                connection.on("ReceiveMessage", (receivedTicketId, comment) => {
                    if (receivedTicketId === ticketId) {
                        setTicketComments((prevComments) => [...prevComments, comment]);
                    }
                });
            });
        }
    }, [connection, ticketId]);

    const handleMessageChange = (e) => {
        setMessageText(e.target.value);
    };

    const addComment = () => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post(`/api/ticket/addComment`, { userId: user.userIdentifier, ticketId: ticketId, comment: messageText })
                .then((response) => {
                    if (connection && connection.state === signalR.HubConnectionState.Connected) {
                        connection.invoke("SendMessage", response.data.data, ticketId)
                            .then(() => {
                                setMessageText("");
                                resolve(); // Resolve the promise after successful invocation
                            })
                            .catch((error) => {
                                reject(error); // Reject the promise if invocation fails
                            });
                    } else {
                        reject(new Error("SignalR connection is not in the 'Connected' state."));
                    }
                })
                .catch((error) => {
                    reject(error); // Reject the promise if the HTTP request fails
                });
        });
    };

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteTicketAttachment(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    useEffect(() => {
        let firstload = localStorage.getItem("minheight")
        if (firstload === "true") {
            setTimeout(function () {
                window.location.reload(1)
                localStorage.removeItem("minheight")
            }, 1000)
        }
    }, []);

    useEffect(() => {
        const reloadData = () => {
            dispatch(getTicket(ticketId));
        };
        reloadData();
    }, [dispatch, ticketId]);

    useEffect(() => {
        const getAllComments = () => {
            dispatch(getComments(ticketId, setTicketComments));
        };
        getAllComments();
    }, [dispatch, ticketId]);

    const formatDate = (date) => {
        return moment(date ? date : new Date()).format("yyyy-MM-DD HH:mm");
    };

    const deleteTicketAttachment = (id) => {
        dispatch(deleteAttachment(ticketId, id, user.userIdentifier));
    }

    const handleAddImages = (event) => {
        event.preventDefault();
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.accept = 'image/*';
        fileInput.addEventListener('change', handleFileSelect);
        fileInput.click();
    }

    const handleFileSelect = (event) => {
        const files = event.target.files;
        const formData = new FormData();
        formData.append("objectIdentifier", ticketId);
        formData.append("userIdentifier", user.userIdentifier);
        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
        }
        dispatch(addAttachment(formData, ticketId));
    }
    const handleAddFiles = (event) => {
        event.preventDefault();
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.accept = 'application/msword, application/vnd.ms-excel, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileInput.addEventListener('change', handleFileSelect);
        fileInput.click();
    }

    const handlePreview = (file) => {
        const previewUrl = `${file.fileName}`;
        const previewWindow = window.open(previewUrl, '_blank');
        previewWindow.focus();
    }

    const handleEditClick = (id) => {
        dispatch(getTicket(id));
    }

    return (
        <>

            {ticket ? (
                <>
                    <Helmet>
                        <title>Tickets - HRMS Admin Template</title>
                        <meta name="description" content="Login page" />
                    </Helmet>
                    {isLoading ? <Loader /> : ""}
                    <div className="chat-main-row">
                        <div className="chat-main-wrapper">
                            <div className="col-lg-8 message-view task-view">
                                <div className="chat-window">
                                    <div className="fixed-header d-flex justify-content-between w-100">
                                        <div className="navbar flex-row">
                                            <div className="float-start d-flex justify-content-between w-100 ">
                                                <div className="ticket-header">
                                                    <span>Status: </span> <span className="badge badge-warning">{ticket.status}</span>
                                                    <span className="m-l-15 text-muted">Created: </span>
                                                    <span>{formatDate(ticket.createDate)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="text-success" onClick={() => handleEditClick(ticketId)} to={user.role === ROLES.Company ? `/company/edit-ticket/${ticketId}` : user.role === ROLES.Employee ? `/employee/edit-ticket/${ticketId}` : '/not-found'} >
                                            <i className="fa fa-pencil m-r-5" />
                                        </Link>
                                    </div>
                                    <div className="chat-contents">
                                        <div className="chat-content-wrap">
                                            <div className="chat-wrap-inner">
                                                <div className="chat-box">
                                                    <div className="task-wrapper">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="project-title">
                                                                    <div className="m-b-20">
                                                                        <span className="h5 card-title ">{ticket.title}</span>
                                                                        <div className="float-end ticket-priority"><span>Priority: </span>
                                                                            <div className="btn-group">
                                                                                <Link to="#" className="badge badge-danger">{ticket.priority} </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {ticket.description}
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex justify-content-between mb-3">
                                                                    <h5 className="card-title m-0">Uploaded image files</h5>
                                                                    <Link className="followers-add float-right" title="Add Attachment" to="#" onClick={handleAddImages}>
                                                                        <i className="material-icons">add</i>
                                                                    </Link>
                                                                </div>
                                                                <Fancybox
                                                                    options={{
                                                                        Carousel: {
                                                                            infinite: false,
                                                                        },
                                                                    }}
                                                                >
                                                                    <div className="row">
                                                                        {images.map((image, index) => (
                                                                            <div className="col-md-3 col-sm-6" key={index}>
                                                                                <div className="uploaded-box single-gallery grid-item">
                                                                                    <div className="uploaded-img gallery-img">
                                                                                        <Link
                                                                                            target="_blank"
                                                                                            to={image.fileName}
                                                                                            data-fancybox="gallery"
                                                                                        >
                                                                                            <img className='ticket-view-img' src={image.fileName} alt="unboundImg14" />
                                                                                        </Link>
                                                                                        <Link to={"#"} onClick={() => handleDeleteClick(image.attachment_Identifier)}>
                                                                                            <span className="delete-icon">&#10006;</span>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Fancybox>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-0">
                                                            <div className="card-body">
                                                                <div className="d-flex justify-content-between mb-3">
                                                                    <h5 className="card-title m-0">Uploaded files</h5>
                                                                    <Link to="#" className="followers-add float-right" title="Add Attachment" onClick={handleAddFiles}>
                                                                        <i className="material-icons">add</i>
                                                                    </Link>
                                                                </div>
                                                                <ul className="files-list">
                                                                    <li>
                                                                        {files.map((file, index) => (
                                                                            <div className="files-cont d-flex justify-content-between mb-4" key={index}>
                                                                                <div className='pdf-section-box'>
                                                                                    <div className="file-type">
                                                                                        {file.fileName.endsWith(".pdf") ? (
                                                                                            <span className="files-icon"><i className="fa fa-file-pdf-o" /></span>
                                                                                        ) : (
                                                                                            <span className="files-icon"><i className="fa fa-file-o" /></span>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="files-info">
                                                                                        <span className="file-name text-ellipsis"><Link to="#" onClick={() => handlePreview(file)}>{file.fileName.split("_")[0].split("/").pop()}</Link></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex" data-popper-placement="bottom-end">
                                                                                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => handleDeleteClick(file.attachment_Identifier)}>
                                                                                        <i className="fa fa-trash-o m-r-5" />
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 message-view task-chat-view ticket-chat-view" id="task_window">
                                <div className="chat-window">
                                    {user.companyIdentifier ? (
                                        <div className="fixed-header">
                                            <div className="navbar">
                                                <div className="task-assign">
                                                    <span className="assign-title">Assigned to </span>
                                                    {ticket.ticketAssignment.length > 0 ? (
                                                        ticket.ticketAssignment.map((file, index) => (
                                                            <Link key={index} to="#" className="avatar">
                                                                <img src={file.userProfile} alt="" />
                                                            </Link>
                                                        ))
                                                    ) : (
                                                        "")}
                                                    <Link to="#" className="followers-add" title="Add Assignee" onClick={() => setAddAssigneeModal(true)}><i className="material-icons">add</i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ("")}
                                    <div className="chat-contents task-chat-contents">
                                        <div className="chat-content-wrap">
                                            <div className="chat-wrap-inner">
                                                <div className="chat-box">
                                                    <div className="chats">
                                                        {ticket.title}
                                                        {tickteComments?.length > 0 ? (
                                                            tickteComments?.map((comment, index) => (
                                                                <div className="chat chat-left" key={index}>
                                                                    <div className="chat-avatar">
                                                                        <Link to="#" className="avatar">
                                                                            <img src={comment?.userProfileUrl ? comment.userProfileUrl : genericImg} alt="" />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="chat-body">
                                                                        <div className="chat-bubble">
                                                                            <div className="chat-content">
                                                                                <span className="task-chat-user">{comment.userName}</span> <span className="chat-time">{formatDate(comment.createDate)}</span>
                                                                                <p>{comment.comment}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p>Nothing Here!</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-footer">
                                        <div className="message-bar">
                                            <div className="message-inner">
                                                <Link className="link attach-icon" to="#"><img src={"Attachment"} alt="" /></Link>
                                                <div className="message-area">
                                                    <div className="input-group">
                                                        <textarea className="form-control" placeholder="Type comment..."
                                                            value={messageText} onChange={handleMessageChange} />
                                                        <span className="input-group-append">
                                                            <button className="btn btn-primary" type="button" onClick={addComment}>
                                                                <i className="fa fa-send" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AssigneeModal
                        user={user}
                        ticketId={ticketId}
                        assigned={ticket.ticketAssignment}
                        addAssigneeModal={addAssigneeModal}
                        toggleAddAssigneeModal={toggleAddAssigneeModal}
                    />
                </>
            ) : ("Loading....")
            }
        </>
    );
}

export default TicketView;