import React, { useEffect, useState } from 'react'
import NavMenu from '../../../components/NavBars/NavMenu'
import Sidebar from '../../../components/SideBar/Sidebar'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Priorities, ProjectCategory, Status, typeOptions } from '../../../constant';
import ReactSelect from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanies } from '../../../features/companySlice';
import { getAllEmployees } from '../../../features/employeeSlice';
import { createProject } from '../../../features/projectSlice';
import { getAllClients } from '../../../features/clientSlice';
import { useNavigate } from 'react-router-dom';

const schema = yup
    .object({
        projectName: yup.string().required("ProjectName is required").trim(),
        clientIdentifier: yup.string().required(),
        startDate: yup.date().required("Date is required"),
        endDate: yup.date().required("Date is required"),
        budget: yup.string().required("Budget is required").max(12),
        projectOwnerIdentifier: yup.string().min(6).required().trim(),
        projectDescription: yup.string().required("Description is required").trim(),
        priority: yup.string().required("Priority is required"),
        status: yup.string().required("Status is required"),
        category: yup.string().required("ProjectCategory is required"),
        notes: yup.string().required("Notes are required"),
        companyIdentifier: yup.string().required(),
    })
    .required();

function CreateProject() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const { companies } = useSelector(state => state.company);
    const { employees } = useSelector(state => state.employee);
    const { clients } = useSelector(state => state.client);

    const state = {
        search: "",
        page: 1,
        pagesize: 25,
        companySearch: "",
        employeeSearch: "",
    }

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const getCompanies = async () => {
            dispatch(getAllCompanies(state.companySearch, state.page, state.pagesize));
        };
        getCompanies();
    }, [state.companySearch, state.page, state.pagesize])

    useEffect(() => {
        const getEmployees = async () => {
            dispatch(getAllEmployees(state.employeeSearch, state.page, state.pagesize));
        }
        getEmployees();
    }, [state.employeeSearch, state.page, state.pagesize])

    useEffect(() => {
        const allClients = async () => {
            dispatch(getAllClients(state.search, state.page, state.pagesize));
        };
        allClients();
    }, [state.search, state.page, state.pagesize])

    const companyOptions = companies.map((company) => {
        return {
            value: company.companyIdentifier,
            label: company.name,
        };
    });
    const clientOptions = clients.map((client) => {
        return {
            value: client.clientIdentifier,
            label: client.clientName,
        };
    });

    const employeeOptions = employees.map((employee) => {
        return {
            value: employee.employeeIdentifier,
            label: employee.name,
        };
    });

    const onSubmit = async (data) => {
        const isCompany = false;
        const formData = new FormData();
        formData.append("ProjectName", data.projectName);
        formData.append("ProjectDescription", data.projectDescription);
        formData.append("ProjectOwnerIdentifier", data.projectOwnerIdentifier);
        formData.append("StartDate", data.startDate.toISOString());
        formData.append("EndDate", data.endDate.toISOString());
        formData.append("Budget", data.budget);
        formData.append("Status", data.status);
        formData.append("Priority", data.priority);
        formData.append("Category", data.category);
        formData.append("Notes", data.notes);
        formData.append("ClientIdentifier", data.clientIdentifier);
        formData.append("Notes", data.notes);
        formData.append("CompanyIdentifier", data.companyIdentifier);
        dispatch(createProject(formData, navigate, isCompany));
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <NavMenu onMenuClick={() => toggleMobileMenu()} />
                <Sidebar />
                <div className="page-wrapper">
                    <Helmet>
                        <title>Create Project</title>
                        <meta name="description" content="Login page" />
                    </Helmet>
                    <div className="account-content">
                        <div className="container">
                            <div>
                                <div className="account-wrapper">
                                    <h3 className="account-title">Create Project</h3>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Project Name</label>
                                                        <Controller
                                                            name="projectName"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.projectName ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.projectName?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Client</label>
                                                        <Controller
                                                            name="clientIdentifier"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <ReactSelect
                                                                    options={clientOptions}
                                                                    value={clientOptions.find(option => option.value === value)}
                                                                    onChange={selected => onChange(selected?.value)}
                                                                    isSearchable
                                                                    isMulti
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.clientIdentifier?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Start Date</label>
                                                        <Controller
                                                            name="startDate"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.startDate ? "error-input" : ""
                                                                        }`}
                                                                    type="date"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.startDate?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>End Date</label>
                                                        <Controller
                                                            name="endDate"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.endDate ? "error-input" : ""
                                                                        }`}
                                                                    type="date"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.endDate?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Budget</label>
                                                        <Controller
                                                            name="budget"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.budget ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.budget?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label></label>
                                                        <Controller
                                                            name="type"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <ReactSelect
                                                                    options={typeOptions}
                                                                    value={typeOptions.find(option => option.value === value)}
                                                                    onChange={selected => onChange(selected?.value)}
                                                                    isSearchable
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.type?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Project Leader</label>
                                                        <Controller
                                                            name="projectOwnerIdentifier"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <ReactSelect
                                                                    options={employeeOptions}
                                                                    value={employeeOptions.find(option => option.value === value)}
                                                                    onChange={selected => onChange(selected?.value)}
                                                                    isClearable
                                                                    isSearchable
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.projectOwnerIdentifier?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Project Description</label>
                                                        <Controller
                                                            name="projectDescription"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.projectDescription ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.projectDescription?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Priority</label>
                                                        <Controller
                                                            name="priority"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <select
                                                                    className={`form-control ${errors?.priority ? "error-input" : ""} select`}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                >
                                                                    <option value={""}>
                                                                        {"--Select--"}
                                                                    </option>
                                                                    {Priorities.map(({ label, value }) => (
                                                                        <option key={value} value={value}>
                                                                            {label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        />
                                                        <small>{errors?.priority?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Status</label>
                                                        <Controller
                                                            name="status"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <select
                                                                    className={`form-control ${errors?.status ? "error-input" : ""} select`}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                >
                                                                    {Status.map(({ label, value }) => (
                                                                        <option key={value} value={value}>
                                                                            {label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        />
                                                        <small>{errors?.status?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Project Category</label>
                                                        <Controller
                                                            name="category"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <select
                                                                    className={`form-control ${errors?.category ? "error-input" : ""} select`}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                >
                                                                    {ProjectCategory.map(({ label, value }) => (
                                                                        <option key={value} value={value}>
                                                                            {label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        />
                                                        <small>{errors?.category?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Notes</label>
                                                        <Controller
                                                            name="notes"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.notes ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.notes?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Company</label>
                                                        <Controller
                                                            name="companyIdentifier"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <ReactSelect
                                                                    options={companyOptions}
                                                                    value={companyOptions.find(option => option.value === value)}
                                                                    onChange={selected => onChange(selected?.value)}
                                                                    isClearable
                                                                    isSearchable
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.companyIdentifier?.message}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group text-center">
                                                <button
                                                    className="btn btn-primary account-btn"
                                                    type="submit"
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* /Account Form */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default CreateProject