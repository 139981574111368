import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import toast from "react-hot-toast";
import { PunchFlg } from "../constant";

const attendanceSlice = createSlice({
    name: 'attendance',
    initialState: {
        loading: false,
        isLoading: false,
        punchFlg: PunchFlg.IN,
        weeklyWorkHours: null,
        monthlyworkHours: null,
        todayReport: null,
        todayHours: null,
        todayActivity: [],
        filteredData: [],

    },
    reducers: {
        setPunchLoading: (state) => {
            state.loading = true;
        },
        setLoading: (state) => {
            state.isLoading = true;
        },
        logPunchTimeSuccess: (state) => {
            state.loading = false;
        },
        logPunchTimeFailure: (state) => {
            state.loading = false;
        },
        getTodayActivitySuccess: (state, action) => {
            state.todayActivity = action.payload.data;
        },
        getTodayActivityFailure: (state) => {
            state.todayActivity = [];
        },
        getSearchByDateSuccess: (state, action) => {
            state.isLoading = false;
            state.filteredData = action.payload;
        },
        getSearchByDateFailure: (state, action) => {
            state.isLoading = false;
            state.filteredData = [];
        },
        getMonthlyWorkingHoursSuccess: (state, action) => {
            state.isLoading = false;
            state.monthlyworkHours = action.payload.data;
        },
        getMonthlyWorkingHoursFailure: (state, action) => {
            state.isLoading = false;
        },
        getWeeklyWorkingHoursSuccess: (state, action) => {
            state.isLoading = false;
            state.weeklyWorkHours = action.payload.data;
        },
        getWeeklyWorkingHoursFailure: (state, action) => {
            state.isLoading = false;
        },
        togglePunchFlg: (state, action) => {
            state.punchFlg = action?.payload === PunchFlg.IN ? PunchFlg.OUT : action?.payload === PunchFlg.OUT ? PunchFlg.IN : PunchFlg.IN;
        },
        getTodayReportSuccess: (state, action) => {
            state.todayReport = action.payload.data;
        },
        getTodayReportFailure: (state, action) => {
            state.todayReport = null;
        }
    }
});

export const logPunchTime = (employeeIdentifier, punchFlg) => async (dispatch) => {
    try {
        dispatch(setPunchLoading());
        const response = await httpHandler.post(`/api/attendance/logPunchTime?employeeId=${employeeIdentifier}&punchFlg=${punchFlg}`)
        if (response.data.success) {
            toast.success(response.data.message);
            dispatch(togglePunchFlg(punchFlg));
            dispatch(logPunchTimeSuccess());
        }
        else {
            toast.error(response.data.message);
            dispatch(logPunchTimeFailure());
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(logPunchTimeFailure());
    }
}
export const getTodayActivity = (employeeId) => async (dispatch) => {
    try {
        const response = await httpHandler.get(`/api/attendance/getActivityByDate?employeeId=${employeeId}`)
        if (response.data.success) {
            dispatch(getTodayActivitySuccess(response.data));
        }
        else {
            dispatch(getTodayActivityFailure());
            toast.error(response.data.message);
        }
    } catch (error) {
        dispatch(getTodayActivityFailure());
        toast.error(error.message);
    }
};

export const getTodayReport = (employeeId) => async (dispatch) => {
    try {
        const response = await httpHandler.get(`/api/attendance/getTodayReport?employeeId=${employeeId}`)
        if (response.data.success) {
            var lastPunchFlg = response.data.data.lastPunchFlg;
            dispatch(togglePunchFlg(lastPunchFlg));
            dispatch(getTodayReportSuccess(response.data));
        }
        else {
            dispatch(togglePunchFlg());
            toast.error(response.data.message);
            dispatch(getTodayReportFailure());
        }
    } catch (error) {
        dispatch(togglePunchFlg());
        toast.error(error.message);
        dispatch(getTodayReportFailure());
    }
};

export const getWeeklyWorkingHours = (employeeId) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.get(`/api/attendance/getWorkingHoursByWeek?employeeId=${employeeId}`)
        if (response.data.success) {
            dispatch(getWeeklyWorkingHoursSuccess(response.data));
        }
        else {
            dispatch(getWeeklyWorkingHoursFailure());
            toast.error(response.data.message);
        }
    } catch (error) {
        dispatch(getWeeklyWorkingHoursFailure());
        toast.error(error.message);
    }
}

export const getMonthlyWorkingHours = (employeeId) => async (dispatch) => {
    try {
        dispatch(setLoading());
        const response = await httpHandler.get(`/api/attendance/getWorkingHoursByMonth?employeeId=${employeeId}`)
        if (response.data.success) {
            dispatch(getMonthlyWorkingHoursSuccess(response.data));
        }
        else {
            dispatch(getMonthlyWorkingHoursFailure());
            toast.error(response.data.message);
        }
    } catch (error) {
        dispatch(getMonthlyWorkingHoursFailure());
        toast.error(error.message);
    }
}

export const { setPunchLoading, logPunchTimeSuccess, logPunchTimeFailure, getTodayActivitySuccess, getTodayActivityFailure, getSearchByDateSuccess, getSearchByDateFailure, getMonthlyWorkingHoursSuccess,
    getMonthlyWorkingHoursFailure, getWeeklyWorkingHoursSuccess, getWorkingHoursByDateFailure, getWeeklyWorkingHoursFailure, getTodayReportSuccess,
    getTodayReportFailure, togglePunchFlg, setLoading } = attendanceSlice.actions;
export default attendanceSlice.reducer;