import React from 'react'

function ContactArea() {
    return (
        <section id="contact" className="contact-area contact-2 ptb-120">
            <div className="container">
                <div className="section-title">
                    <h2 className="title text-white">Contact us</h2>
                    <p className="text-white">Samu lets you manage software development and track bugs. Samu's streamlined design is built for speed and efficiency helping high performing team.</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <form id="contactForm">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-30">
                                        <input type="text" name="name" className="form-control" id="name" required data-error="Enter your name" placeholder="Your Name*" />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-30">
                                        <input type="email" name="email" className="form-control" id="email" required data-error="Enter your email" placeholder="Your Email*" />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group mb-30">
                                        <textarea name="message" id="message" className="form-control" cols="30" style={{height : 'auto'}} rows="8" required data-error="Please enter your message" placeholder="Your Message..."></textarea>
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div className="col-md-12 text-center">
                                    <button type="submit" className="submit-btn-block" title="Send message">Send Message</button>
                                    <div id="msgSubmit"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactArea