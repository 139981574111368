import React from 'react';
import { FaLaptop, FaThList, FaUsers, FaHistory } from 'react-icons/fa';
function FeaturesArea() {
    return (
        <section id="features" className="features-area features-3 pb-90">
            <div className="container">
                <div className="row align-items-center gx-xl-5">
                    <div className="col-lg-6">
                        <div className="content mb-30">
                            <div className="section-title mb-0">
                                <span className="tag">Software Features</span>
                                <h2 className="title">Stunning with lots of features</h2>
                                <p>Samu lets you manage software development and track bugs. Samu's streamlined design is built for speed and efficiency helping high performing team.</p>
                                <a href="contact.html" className="btn primary-btn primary-btn-4" style={{color:'white'}}>Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-first">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-sm-6">
                                <div className="item-single bg-orange">
                                    <div className="icon"><i><FaLaptop/></i></div>
                                    <h3>Fully Optimized Usability</h3>

                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="item-single bg-blue mt-30 mb-sm-30">
                                    <div className="icon"><i><FaThList/></i></div>
                                    <h3>List &amp; Board Management</h3>

                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="item-single bg-red">
                                    <div className="icon"><i><FaUsers/></i></div>
                                    <h3>Multiple Teams Selector</h3>

                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="item-single bg-purple mt-30 mb-30">
                                    <div className="icon"><i><FaHistory/></i></div>
                                    <h3>Real-Time Inspections</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturesArea