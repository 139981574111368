import React, { useState } from 'react'

function CompanyAddTicket() {
    // const [menu, setMenu] = useState(false);
    return (
        <>
        Add Tickets
            {/* <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <NavMenu onMenuClick={() => toggleMobileMenu()} />
                <Sidebar />
                <div className="page-wrapper" style={{ display: "block" }}>
                    <Helmet>
                        <title>Add Ticket - CHS</title>
                        <meta name="description" content="Login page" />
                    </Helmet>
                    {isLoading ? <Loader /> : ""}
                    <div className="account-content">
                        <div className="container">
                            <div>
                                <div className="account-wrapper">
                                    <h3 className="account-title">Add Ticket</h3>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Title</label>
                                                        <Controller
                                                            name="title"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.title ? "error-input" : ""}`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.title?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Priority</label>
                                                        <Controller
                                                            name="priority"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <select
                                                                    className={`form-control ${errors?.priority ? "error-input" : ""} select`}
                                                                    value={value}
                                                                    onChange={onChange}
                                                                > <option value={""}>
                                                                        {"--Select--"}
                                                                    </option>
                                                                    {Priorities.map(({ label, value }) => (
                                                                        <option key={value} value={value}>
                                                                            {label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        />
                                                        <small>{errors?.priority?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Description</label>
                                                        <Controller
                                                            name="description"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.description ? "error-input" : ""}`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.description?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Image</label>
                                                        <Controller
                                                            name="files"
                                                            control={control}
                                                            defaultValue={[]}
                                                            render={({ field: { onChange, onBlur } }) => (
                                                                <>
                                                                    <input
                                                                        type="file"
                                                                        onChange={(e) => {
                                                                            onChange([...e.target.files]);
                                                                        }}
                                                                        onBlur={onBlur}
                                                                        multiple
                                                                        accept="image/*"
                                                                        className="form-control"
                                                                    />
                                                                    {previewUrls.map((url, index) => (
                                                                        <div className="preview-image-container" key={index}>
                                                                            <img className="preview-image" src={url} alt={`Preview ${index}`} />
                                                                            <span className="deselect-icon" onClick={() => handleDeselectImage(index)}>x</span>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                        />
                                                        {errors.files && (
                                                            <span className="error-message">{errors.files.message}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group text-center">
                                                <button
                                                    className="btn btn-primary account-btn"
                                                    type="submit"
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default CompanyAddTicket