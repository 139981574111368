import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { genericImg } from "../../assets/img";
import { confirmAlert } from "react-confirm-alert";
import UpdatePasswordModal from "../auth/UpdatePasswordModal";
import Loader from "../loader/Loader";
import { useSelector } from "react-redux";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useDispatch } from "react-redux";
import { updateProfilePicture } from "../../features/authLoginSlice";

function UserProfile() {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const { isLoading } = useSelector((state) => state.userLogin);
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);
  const toggleUpdatePasswordModal = () => setUpdatePasswordModalOpen(!updatePasswordModalOpen);
  const [src, selectFile] = useState(null);
  const [updatePhotoModalOpen, setUpdatePhotoModalOpen] = useState(false);
  const toggleUpdatePhotoModal = () => setUpdatePhotoModalOpen(!updatePhotoModalOpen);
  const cropperRef = useRef(null);

  const handleUpdateProfile = (id) => {
    confirmAlert({
      title: "Change Profile Photo",
      buttons: [
        {
          label: "Upload Photo",
          onClick: () => {
            document.getElementById("photoUploadInput").click();
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handlePhotoUpload = e => {
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    selectFile(imageUrl);
    setUpdatePhotoModalOpen(true);
  };

  const getCroppedImage = () => {

    try {
      const canvas = cropperRef.current.getCroppedCanvas();
      if (canvas) {
        const base64Image = canvas.toDataURL('image/jpeg');
        const blob = dataURItoBlob(base64Image);
        var formData = new FormData();
        formData.append('userId', user.userIdentifier);
        formData.append('profilePhoto', blob, `${user.name}.jpg`);
        dispatch(updateProfilePicture(formData));
        toggleUpdatePhotoModal();
      }
    } catch (error) {
      console.error('Failed to get cropped canvas:', error);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeString });
    return blob;
  };

  return (
    <>
      {
        isLoading ?
          <Loader /> :
          (
            <>
              <Helmet>
                <title>Admin Profile - CHS</title>
                <meta name="description" content="Reactify Blank Page" />
              </Helmet>
              <div className="content container-fluid">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">My Profile</h3>
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="#" style={{ textTransform: "capitalize" }}>
                            {user.role}
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">{user.name}</li>
                      </ul>
                    </div>
                    <div className="col-auto float-end ml-auto">
                      <Link
                        to={`#`}
                        onClick={() => setUpdatePasswordModalOpen(true)}
                        className="btn add-btn"
                      >
                        UpdatePassword
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Update Password Modal */}
                <UpdatePasswordModal
                  updatePasswordModalOpen={updatePasswordModalOpen}
                  toggleUpdatePasswordModal={toggleUpdatePasswordModal}
                  user={user}
                />
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="profile-view">
                          <div className="profile-img-wrap">
                            <div className="profile-img">
                              <Link
                                to="#"
                                onClick={() => {
                                  handleUpdateProfile(user.userIdentifier);
                                }}
                              >
                                <img src={genericImg} alt="" />
                              </Link>
                            </div>
                          </div>
                          <input
                            id="photoUploadInput"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handlePhotoUpload}
                          />
                          <div className="profile-basic">
                            <div className="row">
                              <div className="col-md-5">
                                <div className="profile-info-left">
                                  <h3 className="user-name m-t-0 mb-0">
                                    {user.name}
                                  </h3>
                                  <div className="staff-id">
                                    {user.role.replace(
                                      user.role[0],
                                      user.role.charAt(0).toUpperCase()
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-7">
                                <ul className="personal-info">
                                  <li>
                                    <div className="title">Email:</div>
                                    <div className="text">
                                      {user.email ? user.email : "N/A"}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="title">Address:</div>
                                    <div className="text">
                                      {/* {user.address ? user.address : "N/A"} */}
                                      Kunshra
                                    </div>
                                  </li>
                                  <li>
                                    <div className="title">Country:</div>
                                    <div className="text">
                                      {/* {user.country ? user.country : "N/A"} */}
                                      India
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {src && (
                  <div className="image-prev">
                    {/* <Cropper
                      ref={cropperRef}
                      src={src}
                      style={{ height: 400, width: '100%' }}
                      aspectRatio={1}
                    /> */}
                    <Cropper
                      style={{ height: 400, width: "100%" }}
                      aspectRatio={1}
                      preview=".img-preview"
                      src={src}
                      ref={cropperRef}
                      viewMode={1}
                      guides={true}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      checkOrientation={false}
                    />
                    <button className="btn btn-danger" onClick={getCroppedImage}>
                      Crop Image
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
    </>
  );
}

export default UserProfile;
