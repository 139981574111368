import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { deteleExpense, getAllExpense } from '../../../features/expenseSlice';
import { Table } from 'antd';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../components/loader/Loader';

export default function AllExpense() {
        const { allExpense, count, isLoading } = useSelector((state) => state.expense);
        const dispatch = useDispatch();
        const [state, setState] = useState({
                search: "",
                page: 1,
                pagesize: 10
        })

        useEffect(() => {
                dispatch(getAllExpense(state.search, state.page, state.pagesize))
        }, [state.search, state.page, state.pagesize, dispatch]);

        const onShowSizeChange = (current, pageSize) => {
                setState({ ...state, page: 1, pagesize: pageSize });
        };

        const itemRender = (current, type, originalElement) => {
                if (type === 'prev') {
                        return <button className="btn btn-sm btn-primary">Previous</button>;
                }
                if (type === 'next') {
                        return <button className="btn btn-sm btn-primary">Next</button>;
                }
                return originalElement;
        };

        const handleDeleteClick = id => {
                confirmAlert({
                        title: 'Confirm to delete',
                        message: 'Are you sure to do this.',
                        buttons: [
                                {
                                        label: 'Yes',
                                        onClick: () => handleDelete(id),
                                },
                                {
                                        label: 'No',
                                },
                        ],
                });
        };

        const handleDelete = id => {
                dispatch(deteleExpense(id));
        }

        const columns = [
                {
                        title: 'Item',
                        dataIndex: 'itemName',
                        render: (text, record) => <span>{text}</span>,
                },
                {
                        title: 'Purchase From',
                        dataIndex: 'purchaseFrom',
                        render: (text) => <span>{text}</span>,
                },
                {
                        title: 'Purchase Date',
                        dataIndex: 'purchaseDate',
                        render: (text) => {
                                const formattedDate = moment(text).format('DD MMM YYYY');
                                return <span>{formattedDate}</span>;
                        },
                },
                {
                        title: 'Purchase By',
                        dataIndex: 'purchaseBy',
                        render: (text) => <span>{text}</span>,
                },
                {
                        title: 'Amount',
                        dataIndex: 'amount',
                        render: (text) => <span>{text ? `$${text}` : '-'}</span>,
                },
                {
                        title: 'Paid By',
                        dataIndex: 'paidBy',
                        render: (text) => <span>{text}</span>,
                },
                {
                        title: 'Status',
                        dataIndex: 'isActive',
                        render: (text, record) => (
                                <div className="dropdown action-label">
                                        <a
                                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                href="#"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="true"
                                        >
                                                <i className={`fa fa-dot-circle-o ${record.isActive ? 'text-success' : 'text-danger'}`}></i>
                                                {record.isActive ? 'Active' : 'Inactive'}
                                        </a>
                                        <div className="dropdown-menu">
                                                <span className="dropdown-item"
                                                        style={{ cursor: 'pointer' }}
                                                >
                                                        <i className="fa fa-dot-circle-o text-success"></i> Active
                                                </span>
                                                <span
                                                        className="dropdown-item" style={{ cursor: 'pointer' }} >
                                                        <i className="fa fa-dot-circle-o text-danger"></i> Inactive
                                                </span>
                                        </div>
                                </div>
                        ),
                },
                {
                        title: 'Action',
                        render: (text, record) => (
                                <div className="d-flex justify-content-around">
                                        <Link className="dropdown-item px-2 text-success" to={`/admin/edit-expense/${record.expenseIdentifier}`}>
                                                <i className="fa fa-pencil m-r-5" />
                                        </Link>
                                        <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
                                                handleDeleteClick(record.expenseIdentifier);
                                        }}>
                                                <i className="fa fa-trash-o m-r-5" />
                                        </Link>
                                </div>
                        ),
                },
        ];


        return (
                <div className="content container-fluid">
                        <div className="page-header">
                                <div className="row align-items-center">
                                        <div className="col">
                                                <h3 className="page-title">Expenses</h3>
                                                <ul className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="admin-dashboard.html">Dashboard</a></li>
                                                        <li className="breadcrumb-item active">Expenses</li>
                                                </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                                <Link to='/admin/create-expense' className="btn add-btn">
                                                        <i className="fa fa-plus"></i> Add Expense
                                                </Link>
                                        </div>
                                </div>
                        </div>
                        <div className="row filter-row">
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <input type="text" className="form-control floating" onChange={(e) => setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' })} />
                                                <label className="focus-label">Item Name</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus select-focus focused">
                                                <select className="select floating select2-hidden-accessible" aria-hidden="true">
                                                        <option>-- Select --</option>
                                                        <option>Loren Gatlin</option>
                                                        <option>Tarah Shropshire</option>
                                                </select>
                                                <label className="focus-label">Purchased By</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus select-focus focused">
                                                <select className="select floating select2-hidden-accessible" aria-hidden="true">
                                                        <option>-- Select --</option>
                                                        <option>Cash</option>
                                                        <option>Cheque</option>
                                                </select>
                                                <label className="focus-label">Paid By</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <div className="cal-icon">
                                                        <input className="form-control floating datetimepicker" type="text" />
                                                </div>
                                                <label className="focus-label">From</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <div className="form-group form-focus">
                                                <div className="cal-icon">
                                                        <input className="form-control floating datetimepicker" type="text" />
                                                </div>
                                                <label className="focus-label">To</label>
                                        </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                                        <button className="btn btn-success w-100">Search</button>
                                </div>
                        </div>
                        <div className="row">
                                <div className="col-md-12">
                                        <div className="table-responsive">
                                                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                        <div className="row">
                                                                <div className="col-sm-12 col-md-6">
                                                                        <div className="dataTables_length mt-3" id="DataTables_Table_0_length" style={{ display: 'flex', gap: 6 }}>
                                                                                <label>
                                                                                        Show
                                                                                </label>
                                                                                <select style={{ width: 40 }} name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm">
                                                                                        <option value="10">10</option>
                                                                                        <option value="25">25</option>
                                                                                        <option value="50">50</option>
                                                                                        <option value="100">100</option>
                                                                                </select>
                                                                                <span>
                                                                                        Entries
                                                                                </span>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-6"></div>
                                                        </div>
                                                        {isLoading
                                                                ?
                                                                <Loader />
                                                                :
                                                                <div className="row">
                                                                        <div className="col-sm-12">
                                                                                <Table
                                                                                        className="table-striped"
                                                                                        columns={columns}
                                                                                        // dataSource={allExpense}
                                                                                        dataSource={Array.isArray(allExpense) ? allExpense : []}
                                                                                        rowKey={(record) => record?.expenseIdentifier}
                                                                                        pagination={{
                                                                                                current: state.page,
                                                                                                pageSize: state.pagesize,
                                                                                                total: count,
                                                                                                showTotal: (total, range) =>
                                                                                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                                                                showSizeChanger: true,
                                                                                                onShowSizeChange: onShowSizeChange,
                                                                                                itemRender: itemRender,
                                                                                                onChange: (page, pageSize) =>
                                                                                                        setState({ ...state, page, pagesize: pageSize }),
                                                                                        }}
                                                                                />
                                                                        </div>
                                                                </div>
                                                        }
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        )
}
