import React from 'react'
import { Link } from 'react-router-dom'

function PricingArea() {
    return (
        <section id="pricing" className="pricing-area pricing-2 pb-90">
            <div className="container">
                <div className="section-title">
                    <h2 className="title">Best pricing plan</h2>
                    <p>Samu lets you manage software development and track bugs. Samu's streamlined design is built for speed and efficiency helping high performing team.</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-4 col-lg-3">
                        <div className="item-single mb-30">
                            <div className="content">
                                <h3 className="label">Free</h3>
                                <span>
                                    <span className="price">$0</span>
                                    <span className="slash">/</span>
                                    mo
                                </span>
                            </div>
                            <ul className="item-list list-unstyled p-0">
                                <li>Unlimited  Pages</li>
                                <li>All Team Members</li>
                                <li>Unlimited Leads</li>
                                <li>Unlimited Page Views</li>
                                <li>Export in HTML/CSS</li>
                            </ul>
                            <Link to="#" className="btn primary-btn primary-btn-2" style={{color:'white'}}>Get Started</Link>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="item-single mb-30">
                            <div className="content">
                                <h3 className="label">Silver</h3>
                                <span>
                                    <span className="price">$30</span>
                                    <span className="slash">/</span>
                                    mo
                                </span>
                            </div>
                            <ul className="item-list list-unstyled p-0">
                                <li>Unlimited  Pages</li>
                                <li>All Team Members</li>
                                <li>Unlimited Leads</li>
                                <li>Unlimited Page Views</li>
                                <li>Export in HTML/CSS</li>
                            </ul>
                            <Link to="#" className="btn primary-btn primary-btn-2" style={{color:'white'}}>Get Started</Link>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="item-single mb-30">
                            <div className="content">
                                <h3 className="label">Gold</h3>
                                <span>
                                    <span className="price">$10</span>
                                    <span className="slash">/</span>
                                    mo
                                </span>
                            </div>
                            <ul className="item-list list-unstyled p-0">
                                <li>Unlimited  Pages</li>
                                <li>All Team Members</li>
                                <li>Unlimited Leads</li>
                                <li>Unlimited Page Views</li>
                                <li>Export in HTML/CSS</li>
                            </ul>
                            <Link to="#" className="btn primary-btn primary-btn-2" style={{color:'white'}}>Get Started</Link>
                        </div>
                    </div>
                    <div className="col-sm-4 col-lg-3">
                        <div className="item-single mb-30">
                            <div className="content">
                                <h3 className="label">Customize</h3>
                                <span>
                                    <span className="price">$20</span>
                                    <span className="slash">/</span>
                                    mo
                                </span>
                            </div>
                            <ul className="item-list list-unstyled p-0">
                                <li>Unlimited  Pages</li>
                                <li>All Team Members</li>
                                <li>Unlimited Leads</li>
                                <li>Unlimited Page Views</li>
                                <li>Export in HTML/CSS</li>
                            </ul>
                            <Link to="#" className="btn primary-btn primary-btn-2" style={{color:'white'}}>Get Started</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingArea