import * as yup from "yup";
import { emailrgx } from "../constant";

const commonFileTests = (value) => {
    if (!value) return true;

    return (
        value.size <= 1024 * 1024 &&
        ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    );
};

export const addCompanySchema = yup
    .object({
        email: yup.string().required("Email is required").matches(emailrgx, "Invalid Email").trim(),
        name: yup.string().required("Name is required").trim(),
        password: yup.string().min(6, "Password must be at least 6 characters").required("Password is required").trim(),
        confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Password must match")
            .required("ConfirmPassword is required").trim(),
        address: yup.string().required("Address is required"),
        country: yup.string().required("Country is required"),
        contact: yup.string().required("Phone Number is required"),
        website: yup.string().required("Website is required"),
        registrationNumber: yup.string().required("Registration Number is required"),
        taxIdentificationNumber: yup.string().required("Tax Identification Number is required"),
        foundingDate: yup.date().transform((value, originalValue) => {
            if (!originalValue) return undefined;
            return value;
        })
            .typeError("Invalid Date")
            .required("Founding date is required"),
        businessType: yup.string().required("Business Type Number is required"),
        companySize: yup.string().required("Company Size is required"),
        CEO: yup.string(),
        founder: yup.string(),
        ceoProfileUrl: yup.mixed()
            .test("fileSize", "File size is too large", commonFileTests)
            .test("fileType", "Invalid file type", commonFileTests),
        founderProfileUrl: yup.mixed()
            .test("fileSize", "File size is too large", commonFileTests)
            .test("fileType", "Invalid file type", commonFileTests),
        companyProfilePhoto: yup
            .mixed()
            .required("Photo is required")
            .test("fileSize", "File size is too large", commonFileTests)
            .test("fileType", "Invalid file type", commonFileTests)
    })
    .required();