import React from 'react'
import { blog_4, blog_5, blog_6, placeholder } from '../../../assets/assets2/images'
import { Link } from 'react-router-dom'

function BlogArea() {
    return (
        <>
            <div className="blog-area blog-with-sidebar pt-120 pb-0">
                <div className="container">
                    <div className="row justify-content-center gx-xl-5">
                        <div className="col-lg-8">
                            <article className="item-single mb-30">
                                <div className="image">
                                    <Link to="/blog-details" className="lazy-container aspect-ratio">
                                        <img className="lazyload lazy-image" src={placeholder} data-src={blog_4} alt="Blog" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <ul className="info-list">
                                        <li><i className="far fa-calendar-alt"></i>18 April 2023</li>
                                        <li><i className="fas fa-user"></i>Admin</li>
                                        <li><i className="fas fa-tag"></i>Design Agency</li>
                                    </ul>
                                    <h3 className="title">
                                        <Link to="/blog-details">
                                            Most Suited For You to Get a personalized Quotation.
                                        </Link>
                                    </h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                                    </p>
                                    <Link to="/blog-details" className="btn primary-btn primary-btn-5" style={{color:'white'}}>Read More</Link>
                                </div>
                            </article>
                            <article className="item-single mb-30">
                                <div className="image">
                                    <Link to="/blog-details" className="lazy-container aspect-ratio">
                                        <img className="lazyload lazy-image" src={placeholder} data-src={blog_5} alt="Blog" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <ul className="info-list">
                                        <li><i className="far fa-calendar-alt"></i>20 April 2023</li>
                                        <li><i className="fas fa-user"></i>Harry</li>
                                        <li><i className="fas fa-tag"></i>Marketing Agency</li>
                                    </ul>
                                    <h3 className="title">
                                        <Link to="/blog-details">
                                            How is Technology Working With New Things?
                                        </Link>
                                    </h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                                    </p>
                                    <Link to="/blog-details" className="btn primary-btn primary-btn-5" style={{color:'white'}}>Read More</Link>
                                </div>
                            </article>
                            <article className="item-single mb-30">
                                <div className="image">
                                    <Link to="/blog-details" className="lazy-container aspect-ratio">
                                        <img className="lazyload lazy-image" src={placeholder} data-src={blog_6} alt="Blog" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <ul className="info-list">
                                        <li><i className="far fa-calendar-alt"></i>22 April 2023</li>
                                        <li><i className="fas fa-user"></i>John</li>
                                        <li><i className="fas fa-tag"></i>Design Agency</li>
                                    </ul>
                                    <h3 className="title">
                                        <Link to="/blog-details">
                                            Top 10 Important Tips on IT Services & Design
                                        </Link>
                                    </h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                                    </p>
                                    <Link to="/blog-details" className="btn primary-btn primary-btn-5" style={{color:'white'}}>Read More</Link>
                                </div>
                            </article>
                            <div className="pagination mb-30">
                                <Link to="#" className="page-numbers"><i className="fas fa-angle-left"></i></Link>
                                <span className="page-numbers current" aria-current="page">1</span>
                                <Link to="#" className="page-numbers">2</Link>
                                <Link to="#" className="page-numbers"><i className="fas fa-angle-right"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <aside className="sidebar-widget-area">
                                <div className="widget widget-social-link mb-30">
                                    <h3 className="title">Follow Us</h3>
                                    <ul className="list-unstyled m-0">
                                        <li className="d-inline">
                                            <Link to="https://www.facebook.com/" target="_blank" className="facebook"><i className="fab fa-facebook-square"></i></Link>
                                        </li>
                                        <li className="d-inline ms-1">
                                            <Link to="https://www.twitter.com/" target="_blank" className="twitter"><i className="fab fa-twitter-square"></i></Link>
                                        </li>
                                        <li className="d-inline ms-1">
                                            <Link to="https://www.google.com/" target="_blank" className="google"><i className="fab fa-google-plus-square"></i></Link>
                                        </li>
                                        <li className="d-inline ms-1">
                                            <Link to="https://www.linkedin.com/" target="_blank" className="linkedin"><i className="fab fa-linkedin"></i></Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget-post mb-30">
                                    <h3 className="title">Recent Posts</h3>
                                    <article className="article-item mb-30">
                                        <div className="image">
                                            <Link to="/blog-details" className="lazy-container aspect-ratio">
                                                <img className="lazyload lazy-image" src={placeholder} data-src={blog_4} alt="Blog" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <h6>
                                                <Link to="/blog-details">Make your everyday life easy from now.</Link>
                                            </h6>
                                            <div className="time">
                                                2 days ago
                                            </div>
                                        </div>
                                    </article>
                                    <article className="article-item mb-30">
                                        <div className="image">
                                            <Link to="/blog-details" className="lazy-container aspect-ratio">
                                                <img className="lazyload lazy-image" src={placeholder} data-src={blog_5} alt="Blog" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <h6>
                                                <Link to="/blog-details">Top 10 life hackers tools for your work.</Link>
                                            </h6>
                                            <div className="time">
                                                3 days ago
                                            </div>
                                        </div>
                                    </article>
                                    <article className="article-item">
                                        <div className="image">
                                            <Link to="/blog-details" className="lazy-container aspect-ratio">
                                                <img className="lazyload lazy-image" src={placeholder} data-src={blog_6} alt="Blog" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <h6>
                                                <Link to="/blog-details">Don't be upset when you are in work</Link>
                                            </h6>
                                            <div className="time">
                                                2 days ago
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="widget widget-adds mb-30">
                                    <div className="content text-center">
                                        <h3>Get this theme now 20% off</h3>
                                        <Link href="/404-error" className="btn secondary-btn text-uppercase">Get Started</Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogArea