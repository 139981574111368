import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { emailrgx } from "../../../constant";
import { getAllCompanies } from "../../../features/companySlice";
import { addEmployee } from "../../../features/employeeSlice";
import Loader from "../../../components/loader/Loader";

const schema = yup
    .object({
        name: yup.string().required("Name is required").trim(),
        email: yup.string().required("Email is required").matches(emailrgx, "Invalid Email").trim(),
        password: yup.string().min(6).required("Password is required").trim(),
        confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Password must match")
            .required("ConfirmPassword is required").trim(),
        joiningDate: yup.date().required("Joining Date is required"),
        mobile: yup.string().required("Phone Number is required").max(12),
        designation: yup.string().required("Designation is required"),
        address: yup.string().required("Address is required"),
        country: yup.string().required("Country is required"),
        company: yup.string().required(),
        profilePhoto: yup
            .mixed()
            .required("Profile photo is required")
            .test(
                "fileSize",
                "File size is too large",
                (value) => value && value.size <= 1024 * 1024
            )
            .test(
                "fileType",
                "Invalid file type",
                (value) => (value && ["image/jpeg", "image/png", "image/gif"].includes(value.type))
            )
    })
    .required();

function AddEmployee() {
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { isLoading, companies } = useSelector(state => state.company);
    const state = {
        search: "",
        page: 1,
        pagesize: 10
    }

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        dispatch(getAllCompanies(state.search, state.page, state.pagesize));
    }, [state.search, state.page, state.pagesize, dispatch]);

    const options = companies.map((company) => {
        return {
            value: company.companyIdentifier,
            label: company.name,
        };
    });

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("password", data.password);
        formData.append("confirmPassword", data.confirmPassword);
        formData.append("joiningDate", data.joiningDate.toISOString());
        formData.append("mobile", data.mobile);
        formData.append("designation", data.designation);
        formData.append("address", data.address);
        formData.append("country", data.country);
        formData.append("employeeProfile", data.profilePhoto);
        dispatch(addEmployee(formData, data.company, user, navigate));
    };

    return (
        <>
            <Helmet>
                <title>Employee - HRMS Admin Template</title>
                <meta name="description" content="Login page" />
            </Helmet>
            {isLoading ? <Loader /> : ("")}
            <div className="account-content">
                <div className="container">
                    <h3 className="account-title">Add Employee</h3>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.name ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="false"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.name?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.email ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="false"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.email?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <div className="input-group">
                                                    <input
                                                        className={`form-control mb-0 ${errors?.password ? "error-input mb-0" : ""
                                                            }`}
                                                        type={showPassword ? "text" : "password"}
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        onClick={() =>
                                                            setShowPassword(!showPassword)
                                                        }
                                                    >
                                                        {showPassword ? "🔓" : "🙈"}
                                                    </button>
                                                </div>
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.password?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <Controller
                                            name={`confirmPassword`}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <div className="input-group">
                                                    <input
                                                        className={`form-control mb-0 ${errors?.confirmPassword
                                                            ? "error-input mb-0"
                                                            : ""
                                                            }`}
                                                        type={
                                                            showConfirmPassword ? "text" : "password"
                                                        }
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="off"
                                                    />
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        onClick={() =>
                                                            setShowConfirmPassword(
                                                                !showConfirmPassword
                                                            )
                                                        }
                                                    >
                                                        {showConfirmPassword ? "🔓" : "🙈"}
                                                    </button>
                                                </div>
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.confirmPassword?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Joining Date</label>
                                        <Controller
                                            name="joiningDate"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.joiningDate ? "error-input mb-0" : ""
                                                        }`}
                                                    type="date"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="false"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.joiningDate?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <Controller
                                            name="mobile"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.mobile ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="false"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.mobile?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Designation</label>
                                        <Controller
                                            name="designation"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <select
                                                    className={`form-control mb-0 ${errors?.designation ? "error-input mb-0" : ""
                                                        } select`}
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <option value="">Select Designation</option>
                                                    <option value="Web Designer">
                                                        Web Designer
                                                    </option>
                                                    <option value="Web Developer">
                                                        Web Developer
                                                    </option>
                                                    <option value="HR Management">
                                                        HR Management
                                                    </option>
                                                    <option value="Digital Marketer">
                                                        Digital Marketer
                                                    </option>
                                                </select>
                                            )}
                                        />
                                        <small>{errors?.designation?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Company</label>
                                        <Controller
                                            name="company"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <ReactSelect
                                                    options={options}
                                                    value={options.find(option => option.value === value)}
                                                    onChange={selected => onChange(selected?.value)}
                                                    isClearable
                                                    isSearchable
                                                    className="company-box"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.company?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <Controller
                                            name="address"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.address ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="false"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.address?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Country</label>
                                        <Controller
                                            name="country"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <input
                                                    className={`form-control mb-0 ${errors?.country ? "error-input mb-0" : ""
                                                        }`}
                                                    type="text"
                                                    value={value}
                                                    onChange={onChange}
                                                    autoComplete="false"
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        <small>{errors?.country?.message}</small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Profile Photo</label>
                                        <Controller
                                            name="profilePhoto"
                                            control={control}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <>
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file && file.type.startsWith("image/")) {
                                                                const previewUrl = URL.createObjectURL(file);
                                                                setPreviewUrl(previewUrl);
                                                                onChange(file);
                                                            }
                                                        }}
                                                        onBlur={onBlur}
                                                        accept="image/*"
                                                        className="form-control form-control-lg"
                                                    />
                                                    {previewUrl && (
                                                        <div className="preview-image-container">
                                                            <img className="preview-image" src={previewUrl} alt="Preview" />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        />
                                        {errors.profilePhoto && (
                                            <span className="error-message">{errors.profilePhoto.message}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group text-center">
                                <button
                                    className="btn btn-primary account-btn"
                                    type="submit"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddEmployee;
