import React from 'react'
import { Link } from 'react-router-dom'
import { overview_2, placeholder } from '../assets/assets2/images'

function OverviewArea() {
    return (
        <section id="overview" className="overview-area mt-0 ptb-90">
            <div className="container-fluid p-lg-0">
                <div className="row align-items-center gx-xl-5">
                    <div className="col-lg-5 col-xxl-4">
                        <div className="content mb-30">
                            <div className="section-title">
                                <span className="tag">Help You Achieve More</span>
                                <h2 className="title">Its real-time sync architecture makes it blazing fast</h2>
                                <p>We are a software company that provides innovative and effective software solutions to help our clients achieve their business goals.
                                    We believe in using the latest technology to provide our clients with the best possible service, and we are always looking for new
                                    ways to improve our clients dream make true.</p>
                                <Link to={"#"} className="btn primary-btn primary-btn-2" style={{color:'white'}}>Try it Free for 7 Days</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-first">
                        <div className="image mb-30">
                            <div className="lazy-container aspect-ratio aspect-ratio-16-11">
                                <img className="lazyload lazy-image" src={placeholder} data-src={overview_2} alt="Overview" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OverviewArea