import React from 'react'
import { Link } from 'react-router-dom'

function Contact() {
    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="content text-center">
                        <h1>Contact us</h1>
                        <ul className="list-unstyled">
                            <li className="d-inline"><Link to="/home">Home</Link></li>
                            <li className="d-inline">/</li>
                            <li className="d-inline active">Contact</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="contact" className="contact-area contact-6 pt-120 pb-90">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon">
                                    <i className="fal fa-phone-plus"></i>
                                </div>
                                <div className="text">
                                    <p><a href="tel:+919569669666">+91 9569669666</a></p>
                                    <p><a href="tel:+918054669666">+91 8054669666</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon">
                                    <i className="fal fa-envelope"></i>
                                </div>
                                <div className="text">
                                    <p><a href="mailTo:hello@example.com">info@crownhillsolutions.com</a></p>
                                    <p><a href="mailTo:info@example.com">info@crownhillsolutions.com</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="item-single mb-50">
                                <div className="icon">
                                    <i className="fal fa-map-marker-alt"></i>
                                </div>
                                <div className="text">
                                    <p>GR Tower, Ind Area, Sector 75, S.A.S Nagar, Punjab 160055</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mb-30">
                            <form id="contactForm">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-30">
                                            <input type="text" name="name" className="form-control" id="name" required data-error="Enter your name" placeholder="Your Name*" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group mb-30">
                                            <input type="email" name="email" className="form-control" id="email" required data-error="Enter your email" placeholder="Your Email*" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group mb-30">
                                            <textarea name="message" id="message" className="form-control" cols="30" rows="8" required data-error="Please enter your message" placeholder="Your Message..."></textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <button type="submit" className="btn primary-btn primary-btn-5" style={{ color: 'white' }} title="Send message">Send Message</button>
                                        <div id="msgSubmit"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 mb-30">
                            <iframe title='demo' src="https://maps.google.com/maps?q=crownhillsolutions&t=&z=10&ie=UTF8&iwloc=&output=embed" style={{ border: "0" }} allowFullScreen="" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact