import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import Sidebar from '../../../components/SideBar/Sidebar';
import NavMenu from '../../../components/NavBars/NavMenu';
import { deleteCompanyDepartment, getAllCompanyDepartments, getDepartment } from '../../../features/departmentSlice';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/loader/Loader';

function CompanyDepartments() {
    const { companyID } = useParams();
    const dispatch = useDispatch();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const { isLoading, companyDepartments, companyDepartmentsCount } = useSelector(state => state.department);
    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 10,
    });

    const handleUpdateClick = (id) => {
        dispatch(getDepartment(id));
    };

    const reloadData = async () => {
        dispatch(getAllCompanyDepartments(state.search, state.page, state.pagesize, companyID));
    };

    useEffect(() => {
        reloadData();
    }, [state.search, state.page, state.pagesize]);

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleDelete = (id) => {
        dispatch(deleteCompanyDepartment(id));
    };

    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === "next") {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };
    const columns = [
        {
            title: "#",
            render: (text, record, index) => (
                <div className="dropdown action-label">
                    <h6>{index + 1}</h6>
                </div>
            ),
        },
        {
            title: "Department-Name",
            dataIndex: "departmentName",
            render: (text, record) => <h5 className="table-avatar">{text}</h5>,
            sorter: (a, b) => a.departmentName.length - b.departmentName.length,
        },
        {
            title: "Department-Head",
            dataIndex: "departmentHeadName",
            render: (text, record) => <h5 className="table-avatar">{text}</h5>,
            sorter: (a, b) => a.departmentHeadName.length - b.departmentHeadName.length,
        },
        {
            title: "Discription",
            dataIndex: "departmentDescription",
            sorter: (a, b) => a.departmentDescription.length - b.departmentDescription.length,
        },
        {
            title: "Action",
            render: (text, record) => (
                <div
                    className="d-flex justify-content-around"
                    data-popper-placement="bottom-end"
                >
                    <Link
                        className="dropdown-item px-2 text-success"
                        to={`/company/comp-update_departments/${record.departmentIdentifier}`}
                        onClick={() => handleUpdateClick(record.departmentIdentifier)}
                    >
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link
                        className="dropdown-item px-2 text-danger"
                        to={"#"}
                        onClick={() => {
                            handleDeleteClick(record.departmentIdentifier);
                        }}
                    >
                        <i className="fa fa-trash-o m-r-5" />
                    </Link>
                </div>
            ),
        },
    ];
    return (
        <>
            <Helmet>
                <title>Company | Departments</title>
                <meta name="description" content="Login page" />
            </Helmet>

            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Departments</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="#">Company</Link>
                                </li>
                                <li className="breadcrumb-item active">Departments</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <Link to={`/company/comp-add_department/${companyID}`} className="btn add-btn">
                                <i className="fa fa-plus" /> Add Department
                            </Link>
                        </div>
                    </div>
                    <div className="row filter-row justify-content-end">
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group form-focus">
                                <input
                                    type="text"
                                    className="form-control floating"
                                    onChange={(e) =>
                                        setState({
                                            ...state,
                                            search: e.target.value
                                                ? e.target.value.toLowerCase()
                                                : "",
                                        })
                                    }
                                />
                                <label className="focus-label">Search....</label>
                            </div>
                        </div>
                    </div>
                    {!isLoading ? (
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <Table
                                        className="table-striped"
                                        pagination={{
                                            current: state.page,
                                            pageSize: state.pagesize,
                                            total: companyDepartmentsCount,
                                            showTotal: (total, range) =>
                                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                            showSizeChanger: true,
                                            onShowSizeChange: onShowSizeChange,
                                            itemRender: itemRender,
                                            onChange: (page, pageSize) =>
                                                setState({ ...state, page, pagesize: pageSize }),
                                        }}
                                        style={{ overflowX: "auto" }}
                                        columns={columns}
                                        dataSource={companyDepartments}
                                        rowKey={(record) => record.departmentIdentifier}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </>
    )
}

export default CompanyDepartments