import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import toast from "react-hot-toast";

export const taxSlice = createSlice({
        name: 'tax',
        initialState: {
                allTax: [],
                isActive: null,
                tax: null,
                count: null,
                isLoading: false
        },
        reducers: {
                getAllTaxSuccess: (state, action) => {
                        state.allTax = action.payload.data;
                        state.isLoading = false;
                },
                getAllTaxFailure: (state, action) => {
                        state.allTax = null;
                        state.isLoading = false;

                },
                addTaxSuccess: (state, action) => {
                        state.allTax = action.payload
                        state.isLoading = false;
                },
                addTaxFailure: (state, action) => {
                        state.allTax = null
                        state.isLoading = false;
                },
                updateTaxSuccess: (state, action) => {
                        state.tax = action.payload;
                        state.isLoading = false;
                },
                updateTaxFailure: (state, action) => {
                        state.tax = null
                },
                getTaxSuccess: (state, action) => {
                        state.tax = action.payload.data;
                        state.isLoading = false;
                },
                getTaxFailure: (state, action) => {
                        state.tax = null
                        state.isLoading = false;

                },
                deleteTaxSuccess: (state, action) => {
                        const Id = action.payload.id;
                        state.allTax.data = state.allTax.data.filter((tax) => tax.taxIdentifier !== Id)
                },
                deleteTaxFailure: (state, action) => {
                        // state.allTax = null
                },
                updateTaxStatusSuccess: (state, action) => {
                        const { isActive, taxIdentifier } = action.payload;
                        const tax = state.allTax.data.find(tax => tax.taxIdentifier === taxIdentifier);
                        if (tax) {
                                tax.isActive = isActive;
                        }
                },
                updateTaxStatusFailure: (state, action) => {
                        state.allTax = null
                },
                setLoading: (state, action) => {
                        state.isLoading = true;
                },
        }
});

export const addTax = (data) => async (dispatch) => {
        try {
                dispatch(setLoading);
                const response = await httpHandler.post(`/api/Tax/CreateTax`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(addTaxSuccess(response));
                } else {
                        toast.error(response.data.message);
                        dispatch(addTaxFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
        }
}

export const getAllTax = () => async (dispatch) => {
        try {
                const response = await httpHandler.get(`/api/Tax/getalltaxes`);
                if (response.data.success) {
                        dispatch(getAllTaxSuccess(response));
                } else {
                        toast.error(response.data.message);
                }
        }
        catch (error) {
                toast.error(error.message);
        }
}

export const updateTax = (data) => async (dispatch) => {
        try {
                const response = await httpHandler.post(`/api/Tax/updateTax`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(updateTaxSuccess(response));
                } else {
                        toast.error(response.data.message);
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(updateTaxFailure());
        }
}

export const getTax = (id) => async (dispatch) => {
        try {
                const response = await httpHandler.get(`/api/Tax/${id}`);
                dispatch(getTaxSuccess(response));
        } catch (error) {
                toast.error(error.message);
                dispatch(getTaxFailure());
        }
};

export const deleteTax = (id) => async (dispatch) => {
        try {
                const response = await httpHandler.post(`/api/Tax/deleteTax/${id}`);
                if (response.data.success) {
                        toast.success("Deleted Successfully");
                        dispatch(deleteTaxSuccess({ id }));
                } else {
                        toast.error(response.data.message);
                        dispatch(deleteTaxFailure(response.data.message));
                }
        } catch (error) {
                toast.error(error.message);
                dispatch(deleteTaxFailure(error.message));
        }
};

export const statusUpdate = (isActive, taxIdentifier) => async (dispatch) => {
        try {
                const response = await httpHandler.post(`/api/Tax/update/status`, { isActive, taxIdentifier });
                if (response.data.success) {
                        toast.success("Status Successfully Changed");
                        dispatch(updateTaxStatusSuccess({ isActive, taxIdentifier }));
                } else {
                        toast.error(response.data.message);
                        dispatch(updateTaxStatusFailure(response.data.message));
                }
        } catch (error) {
                toast.error(error.message);
                dispatch(updateTaxStatusFailure(error.message));
        }
};


export const { setLoading, getAllTaxSuccess, getAllTaxFailure, addTaxSuccess, addTaxFailure, updateTaxSuccess, updateTaxFailure, getTaxSuccess, getTaxFailure, deleteTaxSuccess, deleteTaxFailure, updateTaxStatusSuccess, updateTaxStatusFailure } = taxSlice.actions;
export default taxSlice.reducer;
