import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import { deleteCompany, getAllCompanies, getCompany } from '../../../features/companySlice';

function AllCompanies() {
    const dispatch = useDispatch();
    const { companiesList, count, companyLoading } = useSelector(state => state.company);
    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 10
    })

    useEffect(() => {
        dispatch(getAllCompanies(state.search, state.page, state.pagesize));
    }, [state.search, state.page, state.pagesize, dispatch]);

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleDelete = (id) => dispatch(deleteCompany(id));
    const handleEditClick = (id) => dispatch(getCompany(id));

    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };

    const columns = [
        {
            title: "#",
            dataIndex: "ticketIdentifier",
            render: (text, record, index) => (
                <div className="dropdown action-label">
                    <h6 key={text}>{index + 1}</h6>
                </div>
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => (
                <h5 className="table-avatar">
                    <Link
                        to={`/admin/comp-profile/${record.companyIdentifier}`}
                        className="avatar"
                    >
                        <img alt="" src={record.userProfile} />
                    </Link>
                    <Link to={`/admin/comp-profile/${record.companyIdentifier}`}>
                        {text}
                    </Link>
                </h5>
            ),
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Address",
            dataIndex: "address",
            render: (text, record, index) => (
                record?.address?.substring(0, 40) + "..."
            ),
            sorter: (a, b) => a.address.length - b.address.length,
        },
        {
            title: "Country",
            dataIndex: "country",
            sorter: (a, b) => a.country.length - b.country.length,
        },
        {
            title: "Action",
            render: (text, record) => (
                <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
                    <Link className="dropdown-item px-2 text-success" onClick={() => handleEditClick(record.companyIdentifier)}
                        to={`/admin/update-company/${record.companyIdentifier}`}>
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
                        handleDeleteClick(record.companyIdentifier);
                    }}>
                        <i className="fa fa-trash-o m-r-5" />
                    </Link>
                </div>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Employeeslist - CHS</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Company</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Company</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <Link to="/admin/add-company" className="btn add-btn">
                                <i className="fa fa-plus" /> Add Company
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row filter-row justify-content-end">
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus">
                            <input
                                type="text"
                                className="form-control floating"
                                onChange={(e) => setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' })}
                            />
                            <label className="focus-label">Search....</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <Table
                                className="table-striped"
                                pagination={{
                                    current: state.page,
                                    pageSize: state.pagesize,
                                    total: count,
                                    showTotal: (total, range) =>
                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                    onChange: (page, pageSize) =>
                                        setState({ ...state, page, pagesize: pageSize }),
                                }}
                                style={{ overflowX: "auto" }}
                                columns={columns}
                                dataSource={companiesList}
                                rowKey={(record) => record.companyIdentifier}
                                loading={companyLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllCompanies;