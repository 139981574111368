import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import { genericImg } from '../../../assets/img';
import { formatDate } from '../../../_helpers/common';
import { Link, useParams } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteTask, deleteTaskboardList, getAllBoardEmployees, getBoardDetails, getTaskDetails, getTaskboardList, reorderTasksinDistinctList, reorderTasksinSameList } from '../../../features/boardSlice';
import AssignBoardUsers from './components/AssignBoardUsers';
import AddListModal from './components/AddListModal';
import EditListModal from './components/EditListModal';
import AddTaskModal from './components/AddTaskModal';
import TaskDetailModal from './components/TaskDetailModal';

function Taskboard() {
    const { boardID } = useParams();
    const dispatch = useDispatch();
    const { isLoading, boardDetails, taskBoardUsers } = useSelector(state => state.taskBoard);
    const [boardAssignmentModalOpen, setBoardAssignmentModalOpen] = useState(false);
    const toggleboardAssignmentModal = () => setBoardAssignmentModalOpen(!boardAssignmentModalOpen);
    const [addListModalOpen, setAddListModalOpen] = useState(false);
    const toggleAddListModal = () => setAddListModalOpen(!addListModalOpen);
    const [editListModalOpen, setEditListModalOpen] = useState(false);
    const toggleEditListModal = () => setEditListModalOpen(!editListModalOpen);
    const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
    const toggleAddTaskModal = () => setAddTaskModalOpen(!addTaskModalOpen);
    const [taskDetailModalOpen, setTaskDetailModalOpen] = useState(false);
    const toggleTaskDetailModal = () => setTaskDetailModalOpen(!taskDetailModalOpen);
    const [listID, setlistID] = useState("");

    useEffect(() => {
        dispatch(getBoardDetails(boardID));
    }, [dispatch, boardID])

    const handleAssignmentModalClick = () => {
        dispatch(getAllBoardEmployees(null, boardID))
        setBoardAssignmentModalOpen(true);
    }

    const handleEditListClick = (listID) => {
        dispatch(getTaskboardList(listID));
        toggleEditListModal();
    }

    const handleDeleteClick = (listID) => {
        dispatch(deleteTaskboardList(listID, boardID));
    }

    const handleAddTask = async (id) => {
        setlistID(id);
        toggleAddTaskModal();
    };

    // Handle drag and drop tasks
    const handleDragEnd = async (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }
        const updatedLists = [...boardDetails.lists];
        // Reorder in the same list
        if (source.droppableId === destination.droppableId) {
            const list = updatedLists.find(
                (list) => list.listIdentifier === source.droppableId
            );
            const tasks = [...list.tasks];
            const [removed] = tasks.splice(source.index, 1);
            tasks.splice(destination.index, 0, removed);
            dispatch(reorderTasksinSameList(tasks, list.listIdentifier));
        }
        // Move task to another list
        else {
            const sourceList = updatedLists.find(
                (list) => list.listIdentifier === source.droppableId
            );
            const destinationList = updatedLists.find(
                (list) => list.listIdentifier === destination.droppableId
            );
            const sourceTasks = [...sourceList.tasks];
            const destinationTasks = [...destinationList.tasks];
            const [removed] = sourceTasks.splice(source.index, 1);
            destinationTasks.splice(destination.index, 0, removed);
            dispatch(reorderTasksinDistinctList({
                sourceListIdentifier: source.droppableId,
                destinationListIdentifier: destination.droppableId,
                taskIdentifier: result.draggableId,
                sourceTasks,
                destinationTasks
            })
            );
        }
    };

    const handleTaskDeleteClick = (id, listIdentifier) => {
        dispatch(deleteTask(id, listIdentifier));
    }

    const handleTaskDetailClick = (id) => {
        dispatch(getTaskDetails(id));
        toggleTaskDetailModal();
    }

    return (
        <>
            <Helmet>
                <title>Task Board - TrackTick</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header mb-4">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="task-board-block">
                                <h3 className="page-title">{boardDetails?.name}
                                </h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/employee/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Task Board</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {!isLoading && (
                    <>
                        <div className="row board-view-header mb-2">
                            <div className="col-4">
                                <div className="pro-teams">
                                    <div className="pro-team-lead">
                                        <h4 className="mx-2 mt-0">CreatedBy</h4>
                                        <div className="avatar-group" data-toggle="tooltip" data-placement="top" title={boardDetails?.createdBy}>
                                            <div className="avatar">
                                                <img
                                                    className="avatar-img rounded-circle border border-white"
                                                    alt="User"
                                                    src={boardDetails?.userProfile ? boardDetails?.userProfile : genericImg}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pro-team-members">
                                        <h4>Team</h4>
                                        <div className="avatar-group">
                                            {taskBoardUsers.filter(user => user.isAssigned).slice(0, 5).map((user, index) => (
                                                <div key={user.userIdentifier} className="avatar" data-toggle="tooltip" data-placement="top" title={user.name}>
                                                    <img
                                                        className="avatar-img rounded-circle border border-white"
                                                        alt="User"
                                                        src={user.userProfile}
                                                    />
                                                </div>
                                            ))}
                                            <div className="avatar">
                                                <Link
                                                    to="#"
                                                    onClick={() => handleAssignmentModalClick()}
                                                    className="avatar-title rounded-circle border border-white"
                                                >
                                                    <i className="fa fa-plus" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 text-end">
                                <Link
                                    to="#"
                                    onClick={() => toggleAddListModal()}
                                    className="btn btn-white float-end ml-2"
                                >
                                    <i className="fa fa-plus" /> Create List
                                </Link>
                            </div>
                        </div>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <div className="kanban-board card mb-0">
                                <div className="card-body">
                                    <div className="kanban-cont">
                                        {boardDetails?.lists.length > 0
                                            ? boardDetails.lists.map((list) => (
                                                <Droppable
                                                    droppableId={list.listIdentifier}
                                                    key={list.listIdentifier}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className={`kanban-list kanban-${list.colour}`}
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                        >
                                                            <div className="kanban-header">
                                                                <span className="status-title">
                                                                    {list.name}
                                                                </span>
                                                                <div className="dropdown kanban-action">
                                                                    <Link to="#" data-bs-toggle="dropdown">
                                                                        <i className="fa fa-ellipsis-v" />
                                                                    </Link>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link
                                                                            to={"#"}
                                                                            onClick={() =>
                                                                                handleEditListClick(list.listIdentifier)
                                                                            }
                                                                            className="dropdown-item"
                                                                        >
                                                                            Edit
                                                                        </Link>
                                                                        <Link
                                                                            to={"#"}
                                                                            onClick={() =>
                                                                                handleDeleteClick(list.listIdentifier)
                                                                            }
                                                                            className="dropdown-item"
                                                                        >
                                                                            Delete
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="kanban-wrap">
                                                                {list?.tasks?.map((task, index) => (
                                                                    <Draggable
                                                                        draggableId={task.taskIdentifier}
                                                                        index={index}
                                                                        key={task.taskIdentifier}
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                className="card panel"
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <div className="kanban-box">
                                                                                    <div className="task-board-header p-2">
                                                                                        <div className="mx-2" style={{ cursor: "pointer" }}
                                                                                            onClick={() =>
                                                                                                handleTaskDetailClick(task.taskIdentifier)
                                                                                            }
                                                                                        >
                                                                                            <span className="status-title">
                                                                                                {task.title}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="dropdown kanban-task-action">
                                                                                            <Link
                                                                                                to="#"
                                                                                                data-bs-toggle="dropdown"
                                                                                            >
                                                                                                <i className="fa fa-angle-down" />
                                                                                            </Link>
                                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                                <Link
                                                                                                    to="#"
                                                                                                    className="dropdown-item"
                                                                                                    onClick={() =>
                                                                                                        handleTaskDeleteClick(task.taskIdentifier, list.listIdentifier)
                                                                                                    }
                                                                                                >
                                                                                                    Delete
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="task-board-body">
                                                                                        <div className="kanban-footer">
                                                                                            <span className="task-info-cont">
                                                                                                <span className="task-date">
                                                                                                    <i className="fa fa-clock-o" />{" "}
                                                                                                    {task.dueDate
                                                                                                        ? formatDate(
                                                                                                            task.dueDate
                                                                                                        )
                                                                                                        : "Not Set"}
                                                                                                </span>
                                                                                                <span className="task-priority badge bg-inverse-warning">
                                                                                                    {task.priority
                                                                                                        ? task.priority
                                                                                                        : "Normal"}
                                                                                                </span>
                                                                                            </span>
                                                                                            <span className="task-users">
                                                                                                {/* {task.assignedUsers?.slice(0, 6).map((user, index) => (
                                                                                                    <img key={user.employeeIdentifier} src={user?.userProfile ? user?.userProfile : genericImg} alt="" className="task-avatar" width={24} height={24} />
                                                                                                ))} */}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                            <div className="add-new-task">
                                                                <Link
                                                                    to="#"
                                                                    onClick={() =>
                                                                        handleAddTask(list.listIdentifier)
                                                                    }
                                                                >
                                                                    Add New Task <i className="fa fa-plus" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Droppable>
                                            ))
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </DragDropContext>
                    </>
                )}
            </div>
            <AssignBoardUsers
                boardId={boardID}
                boardMembers={boardDetails?.employees}
                boardAssignmentModalOpen={boardAssignmentModalOpen}
                toggleboardAssignmentModal={toggleboardAssignmentModal}
            />
            <AddListModal
                boardId={boardID}
                addListModalOpen={addListModalOpen}
                toggleAddListModal={toggleAddListModal}
            />
            <EditListModal
                boardId={boardID}
                editListModalOpen={editListModalOpen}
                toggleEditListModal={toggleEditListModal}
            />
            <AddTaskModal
                listID={listID}
                boardId={boardID}
                addTaskModalOpen={addTaskModalOpen}
                toggleAddTaskModal={toggleAddTaskModal}
            />
            <TaskDetailModal
                boardID={boardID}
                taskDetailModalOpen={taskDetailModalOpen}
                toggleTaskDetailModal={toggleTaskDetailModal}
            />
        </>
    )
}

export default Taskboard