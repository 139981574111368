import React from 'react'
import { FaLaptop, FaThList, FaUsers } from 'react-icons/fa';

function FeaturesArea() {
    return (
        <section id="features" className="features-area">
            <div className="container">
                <div className="section-title">
                    <span className="tag">Software Features</span>
                    <h2 className="title">Stunning with lots of features</h2>
                    <p>Samu lets you manage software development and track bugs. Samu's streamlined design is built for speed and efficiency helping high performing team.</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6">
                        <div className="item-single mb-30">
                            <div className="icon blue"><i><FaLaptop /></i></div>
                            <h3>Fully Optimized </h3>
                            <p>To help improve your personal and your  team's productivity Samu ties into your existing tools.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="item-single mb-30">
                            <div className="icon yellow"><i><FaThList /></i></div>
                            <h3>List & Board</h3>
                            <p>To help improve your personal and your  team's productivity Samu ties into your existing tools.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="item-single mb-30">
                            <div className="icon red"><i><FaUsers /></i></div>
                            <h3>Multiple Teams</h3>
                            <p>To help improve your personal and your  team's productivity Samu ties into your existing tools.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturesArea