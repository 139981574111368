import React, { useEffect, useState } from "react";
import NavMenu from "../NavBars/NavMenu";
import Sidebar from "../SideBar/Sidebar";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "antd";
import { deleteDepartment, getAllDepartments, getDepartment } from "../../features/departmentSlice";

function AllDepartments() {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => setMenu(!menu);
  const { departments } = useSelector(state => state.department);
  const [state, setState] = useState({
    search: "",
    page: 1,
    pagesize: 10,
  });

  const handleUpdateClick = (id) => {
    getDepartment(id);
  };

  useEffect(() => {
    dispatch(getAllDepartments(state.search, state.page, state.pagesize));
  }, [state.search, state.page, state.pagesize, dispatch]);

  const handleDeleteClick = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDelete = (id) => {
    var department = false;
    dispatch(deleteDepartment(id, department));
  };

  const onShowSizeChange = (current, pageSize) => {
    setState({ ...state, page: 1, pagesize: pageSize });
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="btn btn-sm btn-primary">Previous</button>;
    }
    if (type === "next") {
      return <button className="btn btn-sm btn-primary">Next</button>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: "#",
      render: (text, record, index) => (
        <div className="dropdown action-label">
          <h6>{index + 1}</h6>
        </div>
      ),
    },
    {
      title: "Department-Name",
      dataIndex: "departmentName",
      render: (text, record) => <h5 className="table-avatar">{text}</h5>,
      sorter: (a, b) => a.departmentName.length - b.departmentName.length,
    },
    {
      title: "Department-Head",
      dataIndex: "departmentHeadName",
      render: (text, record) => <h5 className="table-avatar">{text}</h5>,
      sorter: (a, b) => a.departmentHeadName.length - b.departmentHeadName.length,
    },
    {
      title: "Discription",
      dataIndex: "departmentDescription",
      sorter: (a, b) => a.departmentDescription.length - b.departmentDescription.length,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.length - b.companyName.length,
    },
    {
      title: "Action",
      render: (text, record) => (
        <div
          className="d-flex justify-content-around"
          data-popper-placement="bottom-end"
        >
          <Link
            className="dropdown-item px-2 text-success"
            to={`/update-department/${record.departmentIdentifier}`}
            onClick={() => handleUpdateClick(record.departmentIdentifier)}
          >
            <i className="fa fa-pencil m-r-5" />
          </Link>
          <Link
            className="dropdown-item px-2 text-danger"
            to={"#"}
            onClick={() => {
              handleDeleteClick(record.departmentIdentifier);
            }}
          >
            <i className="fa fa-trash-o m-r-5" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <NavMenu onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <Helmet>
            <title>Departments - CHS Admin Template</title>
            <meta name="description" content="Login page" />
          </Helmet>
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Departments</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Admin</Link>
                    </li>
                    <li className="breadcrumb-item active">Departments</li>
                  </ul>
                </div>
                <div className="col-auto float-end ml-auto">
                  <Link to="/add-department" className="btn add-btn">
                    <i className="fa fa-plus" /> Add Department
                  </Link>
                </div>
              </div>
              <div className="row filter-row justify-content-end">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus">
                    <input
                      type="text"
                      className="form-control floating"
                      onChange={(e) =>
                        setState({
                          ...state,
                          search: e.target.value
                            ? e.target.value.toLowerCase()
                            : "",
                        })
                      }
                    />
                    <label className="focus-label">Search....</label>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        current: state.page,
                        pageSize: state.pagesize,
                        total: departments.count,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                        onChange: (page, pageSize) =>
                          setState({ ...state, page, pagesize: pageSize }),
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      dataSource={departments.data}
                      rowKey={(record) => record.departmentIdentifier}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDepartments;