import React, { useState } from 'react'
import CommentForm from './CommentForm'
import { useDispatch } from 'react-redux';

function TaskComments(props) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const [activeComment, setActiveComment] = useState(null);
  const rootComments = props.comments?.filter((comment) => comment.parentId === 0);
  const getReplies = (commentId) =>
    props.comments
      .filter((comment) => comment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
      );

  const addComment = (text, parentId) => {
    props.addComment(text, parentId);
    setActiveComment(null);
  };

  const updateComment = (text, commentId) => {
    const formData = new FormData();
    formData.append("comment", text);
    formData.append("commentId", commentId);
    //dispatch(updateTaskComment(formData, props.taskIdentifier));
    setActiveComment(null);
  };

  const deleteComment = (commentId) => {
    //dispatch(deleteTaskComment(commentId, props.taskIdentifier));
  };

  return (
    <>
      <div className="card-module-title-activity card-module-title-no-divider description-title">
        <i className="fa fa-list-ul me-2" aria-hidden="true"></i>
        <h3 className="u-inline-block m-0">Activity</h3>
      </div>
      <div className="comments">
        <CommentForm submitLabel="Write" handleSubmit={addComment} />
        <div className="comments-container">
          {/* {rootComments?.map((rootComment) => (
            <Comment
              key={rootComment.commentId}
              comment={rootComment}
              replies={getReplies(rootComment.commentId)}
              activeComment={activeComment}
              setActiveComment={setActiveComment}
              addComment={addComment}
              updateComment={updateComment}
              deleteComment={deleteComment}
              currentUserId={user.userIdentifier}
            />
          ))} */}
        </div>
      </div>
    </>
  )
}

export default TaskComments