import React from 'react';
import { FaCrop } from 'react-icons/fa';

function FeaturesArea() {
    return (
        <section id="features" className="main-features-area main-features-2">
            <div className="features-test">
                <div className="container">
                    <div className="section-title">
                        <h2 className="title">Easy to build your app</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                            convallis dolor metus, a iaculis libero facilisis quis. Proin
                            porttitor dui mauris.
                        </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon blue bg-blue"><i><FaCrop/></i></div>
                                <h3>Easy To Customize</h3>
                                <p>
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon yellow bg-yellow">
                                    <i className="fal fa-chart-network"></i>
                                </div>
                                <h3>Powerful Concepts</h3>
                                <p>
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon green bg-green">
                                    <i className="fal fa-file-certificate"></i>
                                </div>
                                <h3>Fully Secured</h3>
                                <p>
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon red bg-red">
                                    <i className="fal fa-user-headset"></i>
                                </div>
                                <h3>Lifetime Support</h3>
                                <p>
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon green bg-green">
                                    <i className="fal fa-file-certificate"></i>
                                </div>
                                <h3>Fully Secured</h3>
                                <p>
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon red bg-red">
                                    <i className="fal fa-user-headset"></i>
                                </div>
                                <h3>Lifetime Support</h3>
                                <p>
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon blue bg-blue"><i className="fal fa-crop"></i></div>
                                <h3>Easy To Customize</h3>
                                <p>
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="item-single mb-30">
                                <div className="icon yellow bg-yellow">
                                    <i className="fal fa-chart-network"></i>
                                </div>
                                <h3>Powerful Concepts</h3>
                                <p>
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider">
                <div className="icon">
                    <a href="#integration"><i className="fal fa-angle-down"></i></a>
                </div>
                <span className="line"></span>
            </div>
        </section>)
}

export default FeaturesArea