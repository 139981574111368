import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import { approveCompany, deleteCompany, getAllUnApprovedCompanies } from '../../../features/companySlice';
import Loader from '../../../components/loader/Loader';

function UnApprovedCompanies() {
    const dispatch = useDispatch();
    const { isLoading, unapprovedCompanies, count } = useSelector(state => state.company);
    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 5
    })

    useEffect(() => {
        dispatch(getAllUnApprovedCompanies(state.search, state.page, state.pagesize));
    }, [state.search, state.page, state.pagesize, dispatch]);

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleApproveClick = (id) => {
        confirmAlert({
            title: "Confirm to Approve",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleApprove(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };
    const handleDelete = (id) => {
        dispatch(deleteCompany(id));
    };

    const handleApprove = (id) => {
        dispatch(approveCompany(id));
    }

    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };

    const columns = [
        {
            title: "#",
            dataIndex: "ticketIdentifier",
            render: (text, record, index) => (
                <div className="dropdown action-label">
                    <h6 key={text}>{index + 1}</h6>
                </div>
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => (
                <h5 className="table-avatar">
                    <Link
                        to={`#`}
                        className="avatar"
                    >
                        <img alt="" src={record.userProfile} />
                    </Link>
                    <Link to={`#`}>
                        {text}
                    </Link>
                </h5>
            ),
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email.length - b.email.length,
        },
        {
            title: "Action",
            render: (text, record) => (
                <div>
                    <Link
                        to={"#"}
                        className="btn btn-primary m-r-5"
                        onClick={() => {
                            handleApproveClick(record.companyIdentifier);
                        }}
                    >
                        <i className="fa fa-check m-r-5" /> Approve
                    </Link>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            handleDeleteClick(record.companyIdentifier);
                        }}
                    >
                        <i className="fa fa-trash-o m-r-5" /> Delete
                    </button>
                </div>
            ),
        }
    ];
    return (
        <>
            <Helmet>
                <title>UnApproved Companies - Track Tick</title>
                <meta name="description" content="Login page" />
            </Helmet>
            {isLoading ? <Loader /> : ""}
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Company</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">UnApproved Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row filter-row">
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus">
                            <input
                                type="text"
                                className="form-control floating"
                                onChange={(e) => setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' })}
                            />
                            <label className="focus-label">Search....</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <Table
                                className="table-striped"
                                pagination={{
                                    current: state.page,
                                    pageSize: state.pagesize,
                                    total: count,
                                    showTotal: (total, range) =>
                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                    onChange: (page, pageSize) =>
                                        setState({ ...state, page, pagesize: pageSize }),
                                }}
                                style={{ overflowX: "auto" }}
                                columns={columns}
                                dataSource={unapprovedCompanies}
                                rowKey={(record) => record.companyIdentifier}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnApprovedCompanies;