import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import NavMenu from '../NavBars/NavMenu'
import Sidebar from '../SideBar/Sidebar'

function DashboardLayout() {
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <NavMenu onMenuClick={() => toggleMobileMenu()} />
                <Sidebar />
                <div className="page-wrapper">
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default DashboardLayout