import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import toast from "react-hot-toast";

export const expenseSlice = createSlice({
        name: 'expense',
        initialState: {
                allExpense: [],
                expense: null,
                isActive: null,
                count: null,
                isLoading: false
        },
        reducers: {
                getAllExpenseSuccess: (state, action) => {
                        state.allExpense = action.payload.data;
                        state.isLoading = false;
                },
                getAllExpenseFailure: (state, action) => {
                        state.allExpense = null
                        state.isLoading = false;
                },
                deleteExpenseSuccess: (state, action) => {
                        const Id = action.payload.id;
                        state.allExpense = state.allExpense?.filter((expense) => expense?.expenseIdentifier !== Id);
                        state.isLoading = false;
                },
                deleteExpenseFailure: (state, action) => {
                        // state.allExpense = null
                },
                getExpenseSuccess: (state, action) => {
                        state.expense = action.payload;
                        state.isLoading = false;
                },
                getExpenseFailure: (state, action) => {
                        state.isLoading = false;
                },
                updateExpenseSuccess: (state, action) => {
                        state.expense = action.payload;
                        state.isLoading = false;
                },
                updateExpenseFailure: (state, action) => {
                        state.isLoading = false;
                },
                setLoading: (state) => {
                        state.isLoading = true;
                }
        }
});

export const addExpense = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/Expense`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(getAllExpenseSuccess(response))
                } else {
                        dispatch(getAllExpenseFailure())
                }
        }
        catch (error) {
                toast.error(error.message);
        }
}

export const getAllExpense = (search, page, pagesize) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/Expense?search=${search}&page=${page}&pagesize=${pagesize}`);
                if (response.data.success) {
                        dispatch(getAllExpenseSuccess(response.data))
                } else {
                        dispatch(getAllExpenseFailure())
                }
        }
        catch (error) {
                toast.error(error.message);
        }
}

export const deteleExpense = (id) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/Expense/deleteExpense?Identifier=${id}`);
                if (response.data.success) {
                        toast.success("Deleted Successfully");
                        dispatch(deleteExpenseSuccess(id));
                } else {
                        dispatch(deleteExpenseFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
        }
}

export const getExpense = (id) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/Expense/${id}`)
                if (response.data.message) {
                        toast.success(response.data.message);
                        dispatch(getExpenseSuccess(response))
                } else {
                        dispatch(getExpenseFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
        }
}

export const updateExpense = (data) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.post(`/api/Expense/UpdateExpense`, data)
                if (response.data.message) {
                        toast.success(response.data.message);
                        dispatch(updateExpenseSuccess(response));
                }
        }
        catch (error) {
                toast.error(error.message);
        }
}

export const { updateExpenseSuccess, updateExpenseFailure, getExpenseSuccess, getExpenseFailure, setLoading, getAllExpenseSuccess, getAllExpenseFailure, deleteExpenseSuccess, deleteExpenseFailure } = expenseSlice.actions;
export default expenseSlice.reducer;