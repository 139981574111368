import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useDispatch } from 'react-redux';
import { getAllBoardMembers, taskAssignment, updateTaskUserSelection } from '../../../../features/boardSlice';

function TaskAssignUsers(props) {
    const dispatch = useDispatch();
    const { isLoading, taskUsers } = useSelector(state => state.taskBoard);
    const [state, setState] = useState({
        search: ""
    });

    const handleSubmit = () => {
        dispatch(taskAssignment({ taskMembers: taskUsers, taskIdentifier: props.taskID }));
        props.toggleTaskUserAssignModal();
    }

    const handleUserSelection = (selectedUser) => {
        dispatch(updateTaskUserSelection(selectedUser));
    };

    useEffect(() => {
        dispatch(getAllBoardMembers(props.boardID, state.search));
    }, [state.search])

    return (
        <>
            <Modal
                isOpen={props.showTaskUserAssignModal}
                toggle={() => props.toggleTaskUserAssignModal()}>
                <ModalHeader
                    toggle={() => props.toggleTaskUserAssignModal()}>
                    Members
                </ModalHeader>
                <ModalBody>
                    {!isLoading ? (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control pt-0 pb-0 border-end-0"
                                            placeholder="Search ..."
                                            onChange={(e) => setState({ ...state, search: e.target.value ? e.target.value.toLowerCase() : '' })}
                                        />
                                        <button
                                            className="btn btn-outline-secondary btn-md btn-outline-secondary bg-white border-start-0"
                                            type="button"
                                            id="button-addon2"
                                            style={{ height: "44px", borderColor: "#e3e3e3" }} disabled
                                        >
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul className="chat-user-list">
                                    {taskUsers?.length > 0 ? (
                                        taskUsers.map((member, index) => {
                                            return (
                                                <li key={member.userIdentifier} >
                                                    <div className="media d-flex justify-content-start align-items-center">
                                                        <span className="avatar me-3 h-auto"><img alt={member.name} src={member.userProfile} /></span>
                                                        <div className="media-body d-flex justify-content-between align-items-center w-100">
                                                            <div className="d-flex justify-content-start flex-column">
                                                                <span className="user-name lh-1">{member.name}</span>
                                                                <span className="designation">{member.designation}</span>
                                                            </div>
                                                            <div className="checkbox-wrapper-7">
                                                                <input className="tgl tgl-ios"
                                                                    id={`cb2-${index}`}
                                                                    type="checkbox"
                                                                    checked={member.isAssigned}
                                                                    onChange={() => handleUserSelection(member)}
                                                                />
                                                                <label className="tgl-btn" htmlFor={`cb2-${index}`}></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    ) : (
                                        <p>Nothing Here!</p>
                                    )}
                                </ul>
                            </div>
                            <div className="submit-section">
                                <button className="btn btn-primary submit-btn" onClick={handleSubmit}>Submit</button>
                            </div>
                        </>
                    ) : (
                        "Loading"
                    )}
                </ModalBody>
            </Modal >
        </>
    )
}

export default TaskAssignUsers