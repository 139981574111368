import React from 'react'
import { Link } from 'react-router-dom'
import { banner_8, wave_shape_2 } from '../../assets/assets2/images'
import FeaturesArea from './components/featuresArea'
import IntegrationArea from './components/integrationArea'
import AboutArea from './components/aboutArea'
import BlogArea from '../../components/BlogArea'

function HowItWorks() {
  return (
    <>
      <div className="home-banner-area banner-bg banner-8">
        <div className="container-fluid p-0">
          <div className="row align-items-center justify-content-lg-end">
            <div className="col-lg-6 left-content">
              <div className="banner-content mb-30">
                <h1 className="title">
                  We Help You to Get Best Business Solution Ever
                </h1>
                <Link to="/contact" className="btn btn-2 primary-btn primary-btn-6"
                style={{color:'white'}}>30 Days Free Trial</Link>
              </div>
            </div>
            <div className="col-lg-6 right-content">
              <div className="banner-image image-right mb-30">
                <img
                  src={banner_8}
                  className="shadow-none"
                  alt="Banner"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-shapes">
          <img src={wave_shape_2} alt="wave shape" />
        </div>
      </div>

      <FeaturesArea/>
      <IntegrationArea/>
      <AboutArea/>
      <BlogArea/>
    </>
  )
}

export default HowItWorks