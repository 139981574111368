import React from 'react';
import { Link } from 'react-router-dom';
import { NotFound } from '../assets/img';

const Error404 = () => {
  return (
    <>
      <div className="error-area ptb-100">
        <div className="container">
          <div className="error-content">
            <img src={NotFound} alt="_404" />
            <h3>Ooops! Page Not Found</h3>
            <p>
              The page you are looking for might have been removed had its name changed or is temporarily unavailable.
            </p>
            <Link to="/home" className="btn primary-btn primary-btn-5" style={{color:'white'}}>Back to Home</Link>
          </div>
        </div>
      </div>
    </>
  );

}

export default Error404;
