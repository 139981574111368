import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from "react-select";
import { Priorities, ProjectCategory, Status } from '../../../constant';
import { Helmet } from 'react-helmet';
import Sidebar from '../../../components/SideBar/Sidebar';
import NavMenu from '../../../components/NavBars/NavMenu';
import { getAllEmployees } from '../../../features/employeeSlice';
import { getAllCompanies } from '../../../features/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { getProject, updateProject } from '../../../features/projectSlice';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { getAllClients } from '../../../features/clientSlice';

const schema = yup
    .object({
        projectName: yup.string().required("ProjectName is required").trim(),
        clientIdentifier: yup.string().required(),
        startDate: yup.date().required("Date is required"),
        endDate: yup.date().required("Date is required"),
        budget: yup.string().required("Budget is required").max(12),
        projectOwnerIdentifier: yup.string().min(6).required().trim(),
        projectDescription: yup.string().required("Description is required").trim(),
        priority: yup.string().required("Priority is required"),
        status: yup.string().required("Status is required"),
        category: yup.string().required("ProjectCategory is required"),
        notes: yup.string().required("Notes are required"),
        companyIdentifier: yup.string().required(),
    })
    .required();

function EditProject() {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const { companies } = useSelector(state => state.company);
    const { employees } = useSelector(state => state.employee);
    const { project } = useSelector(state => state.project);
    const { clients } = useSelector(state => state.client);

    const state = {
        search: "",
        page: 1,
        pagesize: 25,
        companySearch: "",
        employeeSearch: "",
    };
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        const getCompanies = async () => {
            dispatch(getAllCompanies(state.companySearch, state.page, state.pagesize));
        };
        getCompanies();
    }, [state.companySearch, state.page, state.pagesize])

    useEffect(() => {
        const getEmployees = async () => {
            dispatch(getAllEmployees(state.employeeSearch, state.page, state.pagesize));
        }
        getEmployees();
    }, [state.employeeSearch, state.page, state.pagesize])

    useEffect(() => {
        const allClients = async () => {
            dispatch(getAllClients(state.search, state.page, state.pagesize));
        };
        allClients();
    }, [state.search, state.page, state.pagesize])

    useEffect(() => {
        const project = async () => {
            dispatch(getProject(projectId));
        }
        project();
    }, [])

    const companyOptions = companies.map((company) => {
        return {
            value: company.companyIdentifier,
            label: company.name,
        };
    });

    const employeeOptions = employees.map((employee) => {
        return {
            value: employee.employeeIdentifier,
            label: employee.name,
        };
    });
    const clientOptions = clients.map((client) => {
        return {
            value: client.clientIdentifier,
            label: client.clientName,
        };
    });
    const onSubmit = async (data) => {
        dispatch(updateProject(data, projectId, navigate));
    };
    const formatDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };
    return (
        <>
            {project ?
                (
                    <>
                        <Helmet>
                            <title>Update Project</title>
                            <meta name="description" content="Login page" />
                        </Helmet>
                        <div className="account-content">
                            <div className="container">
                                <div>
                                    <div className="account-wrapper">
                                        <h3 className="account-title">Update Project</h3>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Project Name</label>
                                                            <Controller
                                                                name="projectName"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <input
                                                                        className={`form-control  ${errors?.projectName ? "error-input" : ""
                                                                            }`}
                                                                        type="text"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        autoComplete="false"
                                                                    />
                                                                )}
                                                                defaultValue={project.projectName}
                                                            />
                                                            <small>{errors?.projectName?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Client</label>
                                                            <Controller
                                                                name="clientIdentifier"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <ReactSelect
                                                                        options={clientOptions}
                                                                        value={clientOptions.find(option => option.value === value)}
                                                                        onChange={selected => onChange(selected?.value)}
                                                                        isClearable
                                                                        isSearchable
                                                                    />
                                                                )}
                                                                defaultValue={project.clientIdentifier}
                                                            />
                                                            <small>{errors?.clientIdentifier?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <Controller
                                                                name="startDate"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <input
                                                                        className={`form-control  ${errors?.startDate ? "error-input" : ""
                                                                            }`}
                                                                        type="date"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        autoComplete="false"
                                                                    />
                                                                )}
                                                                defaultValue={formatDate(project.startDate)}
                                                            />
                                                            <small>{errors?.startDate?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <Controller
                                                                name="endDate"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <input
                                                                        className={`form-control  ${errors?.endDate ? "error-input" : ""
                                                                            }`}
                                                                        type="date"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        autoComplete="false"
                                                                    />
                                                                )}
                                                                defaultValue={formatDate(project.endDate)}
                                                            />
                                                            <small>{errors?.endDate?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Budget</label>
                                                            <Controller
                                                                name="budget"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <input
                                                                        className={`form-control  ${errors?.budget ? "error-input" : ""
                                                                            }`}
                                                                        type="text"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        autoComplete="false"
                                                                    />
                                                                )}
                                                                defaultValue={project.budget}
                                                            />
                                                            <small>{errors?.budget?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Project Leader</label>
                                                            <Controller
                                                                name="projectOwnerIdentifier"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <ReactSelect
                                                                        options={employeeOptions}
                                                                        value={employeeOptions.find(option => option.value === value)}
                                                                        onChange={selected => onChange(selected?.value)}
                                                                        isClearable
                                                                        isSearchable
                                                                    />
                                                                )}
                                                                defaultValue={project.projectOwnerIdentifier}
                                                            />
                                                            <small>{errors?.projectOwnerIdentifier?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Project Description</label>
                                                            <Controller
                                                                name="projectDescription"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <input
                                                                        className={`form-control  ${errors?.projectDescription ? "error-input" : ""
                                                                            }`}
                                                                        type="text"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        autoComplete="false"
                                                                    />
                                                                )}
                                                                defaultValue={project.projectDescription}
                                                            />
                                                            <small>{errors?.projectDescription?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Priority</label>
                                                            <Controller
                                                                name="priority"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <select
                                                                        className={`form-control ${errors?.priority ? "error-input" : ""} select`}
                                                                        value={value}
                                                                        onChange={onChange}
                                                                    >
                                                                        {Priorities.map(({ label, value }) => (
                                                                            <option key={value} value={value}>
                                                                                {label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                )}
                                                                defaultValue={project.priority}
                                                            />
                                                            <small>{errors?.priority?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Status</label>
                                                            <Controller
                                                                name="status"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <select
                                                                        className={`form-control ${errors?.status ? "error-input" : ""} select`}
                                                                        value={value}
                                                                        onChange={onChange}
                                                                    >
                                                                        {Status.map(({ label, value }) => (
                                                                            <option key={value} value={value}>
                                                                                {label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                )}
                                                                defaultValue={project.status}
                                                            />
                                                            <small>{errors?.status?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Project Category</label>
                                                            <Controller
                                                                name="category"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <select
                                                                        className={`form-control ${errors?.category ? "error-input" : ""} select`}
                                                                        value={value}
                                                                        onChange={onChange}
                                                                    >
                                                                        {ProjectCategory.map(({ label, value }) => (
                                                                            <option key={value} value={value}>
                                                                                {label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                )}
                                                                defaultValue={project.category}
                                                            />
                                                            <small>{errors?.category?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Notes</label>
                                                            <Controller
                                                                name="notes"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <input
                                                                        className={`form-control  ${errors?.notes ? "error-input" : ""
                                                                            }`}
                                                                        type="text"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        autoComplete="false"
                                                                    />
                                                                )}
                                                                defaultValue={project.notes}
                                                            />
                                                            <small>{errors?.notes?.message}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Company</label>
                                                            <Controller
                                                                name="companyIdentifier"
                                                                control={control}
                                                                render={({ field: { value, onChange } }) => (
                                                                    <ReactSelect
                                                                        options={companyOptions}
                                                                        value={companyOptions.find(option => option.value === value)}
                                                                        onChange={selected => onChange(selected?.value)}
                                                                        isClearable
                                                                        isSearchable
                                                                    />
                                                                )}
                                                                defaultValue={project.companyIdentifier}
                                                            />
                                                            <small>{errors?.companyIdentifier?.message}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group text-center">
                                                    <button
                                                        className="btn btn-primary account-btn"
                                                        type="submit"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        {/* /Account Form */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ("")
            }
        </>
    )
}

export default EditProject