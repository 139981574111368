import React from 'react'
import { author_1, blog_4, blog_5, blog_6, placeholder } from '../../assets/assets2/images'
import { Link } from 'react-router-dom'

function BlogDetail() {
    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="content text-center">
                        <h1>Blog Details</h1>
                        <ul className="list-unstyled">
                            <li className="d-inline"><a href="index.html">Home</a></li>
                            <li className="d-inline">/</li>
                            <li className="d-inline active">Blog Details</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="blog-details-area pt-120 pb-90">
                <div className="container">
                    <div className="row justify-content-center gx-xl-5">
                        <div className="col-lg-8">
                            <div className="blog-description mb-50">
                                <article className="item-single">
                                    <div className="image">
                                        <div className="lazy-container aspect-ratio">
                                            <img className="lazyload lazy-image" src={placeholder} data-src={blog_4} alt="Blog" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <ul className="info-list">
                                            <li><i className="far fa-calendar-alt"></i>08 Dec 2022</li>
                                            <li><i className="fas fa-user"></i>Admin</li>
                                            <li><i className="fas fa-tag"></i>Design Agency</li>
                                        </ul>
                                        <h3 className="title">
                                            <Link to="/blog-details">
                                                Most Suited For You to Get a personalized Quotation.
                                            </Link>
                                        </h3>
                                        <p>
                                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to sure there isn't anything embarrassing hidden in the middle of text.
                                        </p>
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="video-image mb-30">
                                                    <Link to="https://www.youtube.com/watch?v=QSwvg9Rv2EI" className="youtube-popup video-btn">
                                                        <i className="fas fa-play"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <blockquote className="blockquote mb-30">
                                                    <i className="fas fa-quote-left"></i>
                                                    <p>Data including read length distribution and uniformity assessment in a few clicks</p>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <p className="m-0">All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from.</p>
                                    </div>
                                </article>
                            </div>
                            <div className="more-news mb-20">
                                <span className="h2 d-block mb-20">More News</span>
                                <div className="row">
                                    <div className="col-md-6">
                                        <article className="item-single mb-30">
                                            <div className="image">
                                                <Link to="/blog-details" className="lazy-container aspect-ratio">
                                                    <img className="lazyload lazy-image" src={placeholder} data-src={blog_5} alt="Blog" />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <ul className="info-list">
                                                    <li><i className="far fa-calendar-alt"></i>08 Dec 2022</li>
                                                    <li><i className="fas fa-user"></i>Admin</li>
                                                </ul>
                                                <h3 className="title m-0">
                                                    <Link to="/blog-details">
                                                        How is Technology Working With New Things?
                                                    </Link>
                                                </h3>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-md-6">
                                        <article className="item-single mb-30">
                                            <div className="image">
                                                <Link to="/blog-details" className="lazy-container aspect-ratio">
                                                    <img className="lazyload lazy-image" src={placeholder} data-src={blog_6} alt="Blog" />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <ul className="info-list">
                                                    <li><i className="far fa-calendar-alt"></i>18 Jan 2022</li>
                                                    <li><i className="fas fa-user"></i>Harry</li>
                                                </ul>
                                                <h3 className="title m-0">
                                                    <Link to="/blog-details">
                                                        Top 10 Important Tips on IT Services & Design
                                                    </Link>
                                                </h3>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div className="comments mb-30">
                                <div className="comment-box mb-50">
                                    <span className="h2 d-block mb-20">Comments</span>
                                    <ol className="comment-list">
                                        <li className="comment">
                                            <div className="comment-body">
                                                <div className="comment-author">
                                                    <div className="lazy-container aspect-ratio aspect-ratio-1-1">
                                                        <img className="lazyload lazy-image" src={placeholder} data-src={author_1} alt="placeholder" />
                                                    </div>
                                                </div>
                                                <div className="comment-content">
                                                    <h4 className="name">Adam Haul</h4>
                                                    <p>
                                                        Quality control of your data including read length distribution and uniformity assessment in a few clicks and choose your favorite. name this.
                                                    </p>
                                                    <Link to="/404-error" className="btn-reply"><i className="fas fa-reply-all"></i>Reply</Link>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                                <div className="comment-reply mb-30">
                                    <span className="h2 d-block">Post Comment</span>
                                    <p className="comment-notes">
                                        <span id="email-notes">Your email address will not be published.</span>
                                        Required fields are marked
                                        <span className="required">*</span>
                                    </p>
                                    <form id="commentForm" className="comment-form">
                                        <div className="form-group mb-20">
                                            <input type="text" className="form-control" name="name" placeholder=" Name*" required="required" />
                                        </div>
                                        <div className="form-group mb-20">
                                            <input type="email" className="form-control" name="email" placeholder=" Email*" required="required" />
                                        </div>
                                        <div className="form-group mb-30">
                                            <textarea name="message" className="form-control" placeholder="Comment" required="required" rows="6"></textarea>
                                        </div>
                                        <button type="submit" className="btn primary-btn primary-btn-5" style={{ color: 'white' }}>
                                            Post Comment
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <aside className="sidebar-widget-area">
                                <div className="widget widget-social-link mb-30">
                                    <h3 className="title">Follow Us</h3>
                                    <ul className="list-unstyled m-0">
                                        <li className="d-inline">
                                            <Link to="https://www.facebook.com/" target="_blank" className="facebook"><i className="fab fa-facebook-square"></i></Link>
                                        </li>
                                        <li className="d-inline ms-1">
                                            <Link to="https://www.twitter.com/" target="_blank" className="twitter"><i className="fab fa-twitter-square"></i></Link>
                                        </li>
                                        <li className="d-inline ms-1">
                                            <Link to="https://www.google.com/" target="_blank" className="google"><i className="fab fa-google-plus-square"></i></Link>
                                        </li>
                                        <li className="d-inline ms-1">
                                            <Link to="https://www.linkedin.com/" target="_blank" className="linkedin"><i className="fab fa-linkedin"></i></Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget-post mb-30">
                                    <h3 className="title">Recent Posts</h3>
                                    <article className="article-item mb-30">
                                        <div className="image">
                                            <Link to="/blog-details" className="lazy-container aspect-ratio">
                                                <img className="lazy-image ls-is-cached lazyloaded" src={blog_4} data-src={blog_4} alt="Blog" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <h6>
                                                <Link to="/blog-details">Make your everyday life easy from now.</Link>
                                            </h6>
                                            <div className="time">
                                                2 days ago
                                            </div>
                                        </div>
                                    </article>
                                    <article className="article-item mb-30">
                                        <div className="image">
                                            <Link to="/blog-details" className="lazy-container aspect-ratio">
                                                <img className="lazy-image ls-is-cached lazyloaded" src={blog_5} data-src={blog_5} alt="Blog" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <h6>
                                                <Link to="/blog-details">Top 10 life hackers tools for your work.</Link>
                                            </h6>
                                            <div className="time">
                                                3 days ago
                                            </div>
                                        </div>
                                    </article>
                                    <article className="article-item">
                                        <div className="image">
                                            <Link to="/blog-details" className="lazy-container aspect-ratio">
                                                <img className="lazy-image ls-is-cached lazyloaded" src={blog_6} data-src={blog_6} alt="Blog" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <h6>
                                                <Link to="/blog-details">Don't be upset when you are in work</Link>
                                            </h6>
                                            <div className="time">
                                                2 days ago
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="widget widget-adds mb-30">
                                    <div className="content text-center">
                                        <h3>Get this theme now 20% off</h3>
                                        <Link to="/contact" className="btn secondary-btn text-uppercase">Get Started</Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogDetail