import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';

const masterSlice = createSlice({
    name: 'master',
    initialState: {
        projectsCount: 0,
        employeesCount: 0,
        clientsCount: 0,
        companiesCount: 0,
        clients: [],
        projects: [],
        isLoading: false,
    },
    reducers: {
        adminDashboardInfoSuccess: (state, action) => {
            state.projectsCount = action.payload.data.projectsCount;
            state.clientsCount = action.payload.data.clientsCount;
            state.employeesCount = action.payload.data.employeesCount;
            state.companiesCount = action.payload.data.companiesCount;
            state.clients = action.payload.data.clients;
            state.projects = action.payload.data.projects;
            state.isLoading = false;
        },
        adminDashboardInfoFailure: (state, action) => {
            state.projectsCount = 0;
            state.clientsCount = 0;
            state.employeesCount = 0;
            state.companiesCount = 0;
            state.clients = [];
            state.projects = [];
            state.isLoading = false;
        },
        setLoading: (state, action) => {
            state.isLoading = true;
        },
    }
});

export const getAdminDashboardInfo = () => async (dispatch) => {
    try {
        dispatch(setLoading);
        var response = await httpHandler.get(`/api/base/getAdminDashboardInfo`);
        if (response.data.success)
            dispatch(adminDashboardInfoSuccess(response.data));
        else
            dispatch(adminDashboardInfoFailure());
    }
    catch (error) {
        dispatch(adminDashboardInfoFailure());
    }
}

export const { adminDashboardInfoSuccess, adminDashboardInfoFailure, setLoading } = masterSlice.actions;
export default masterSlice.reducer;