import React, { useEffect, useState } from 'react'
import NavMenu from '../../../components/NavBars/NavMenu'
import Sidebar from '../../../components/SideBar/Sidebar'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { getAllCompanies } from '../../../features/companySlice';
import { emailrgx } from '../../../constant';
import { addClient } from '../../../features/clientSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../../../components/loader/Loader';
const schema = yup
    .object({
        clientName: yup.string().required("Name is required").trim(),
        clientEmail: yup.string().required("Email is required").matches(emailrgx, "Invalid Email").trim(),
        clientAddress: yup.string().required("Address is required"),
        country: yup.string().required("Country is required").trim(),
        about: yup.string().required().trim(),
    })
    .required();

function CompanyAddClient() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const { companyId } = useParams();
    const { isLoading } = useSelector(state => state.client);
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const isCompany = true;
        const formData = new FormData();
        formData.append("ClientName", data.clientName);
        formData.append("ClientEmail", data.clientEmail);
        formData.append("ClientAddress", data.clientAddress);
        formData.append("Country", data.country);
        formData.append("About", data.about);
        formData.append("CompanyIdentifier", companyId);
        dispatch(addClient(formData, navigate, isCompany, companyId));
    };
    return (
        <>
            <Helmet>
                <title>Add Client</title>
                <meta name="description" content="Login page" />
            </Helmet>
            {
                !isLoading ? (
                    <div className="account-content">
                        <div className="container">
                            <div>
                                <div className="account-wrapper">
                                    <h3 className="account-title">Add Client</h3>
                                    <div className="modal-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Client Name</label>
                                                        <Controller
                                                            name="clientName"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.clientName ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.clientName?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Client Email</label>
                                                        <Controller
                                                            name="clientEmail"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.clientEmail ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.clientEmail?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Client Address</label>
                                                        <Controller
                                                            name="clientAddress"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.clientAddress ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.clientAddress?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Country</label>
                                                        <Controller
                                                            name="country"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.country ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.country?.message}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>About</label>
                                                        <Controller
                                                            name="about"
                                                            control={control}
                                                            render={({ field: { value, onChange } }) => (
                                                                <input
                                                                    className={`form-control  ${errors?.about ? "error-input" : ""
                                                                        }`}
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    autoComplete="false"
                                                                />
                                                            )}
                                                            defaultValue=""
                                                        />
                                                        <small>{errors?.about?.message}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group text-center">
                                                <Link
                                                    to={`/company/all-clients/${companyId}`}
                                                    className="btn btn-info text-white mr-3 back-list-btn"
                                                    type="button"
                                                >
                                                    Back To List
                                                </Link>
                                                <button
                                                    className="btn btn-primary account-btn"
                                                    type="submit"
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loader />
                )
            }
        </>
    )
}

export default CompanyAddClient