import { Controller, useForm, useFieldArray } from 'react-hook-form';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailrgx } from '../../../constant';
import { useDispatch } from 'react-redux';
import { addInvoice } from '../../../features/invoiceSlice';
import { useSelector } from 'react-redux';
import { getAllTaxTypes } from '../../../features/estimateSlice';
import { getAllClientsforCompany } from '../../../features/clientSlice';
import { getAllProjects } from '../../../features/projectSlice';
import { getAllCompanies } from '../../../features/companySlice';

const schema = yup.object({
        billingAddress: yup.string().required('Billing Address is required').trim(),
        clientId: yup.string(),
        invoiceDate: yup.date().required('Invoic Date is required'),
        email: yup.string().required('Email is required').matches(emailrgx, 'Invalid Email').trim(),
        dueDate: yup.date().required('Expiration Date is required'),
        otherInfo: yup.string('Other information is required'),
        tax: yup.string().required('Tax is required').trim(),
        title: yup.string().required('Title is required').trim(),
        discount: yup.number().required('Discount is required')
}).required();

function EditInvoice() {

        const [overallTotal, setOverallTotal] = useState(0);
        const [total, setTotal] = useState(0);
        const [taxPercentage, setTaxPercentage] = useState(0);
        const [grandTotal, setGrandTotal] = useState(0);
        const { taxTypes } = useSelector(state => state.estimates);
        const [selectedCompanyId, setSelectedCompanyId] = useState("");
        const { companyClients } = useSelector(state => state.client);
        const { projects } = useSelector(state => state.project);
        const { companiesList } = useSelector(state => state.company);
        const { isLoading } = useSelector((state) => state.invoice);

        const dispatch = useDispatch();

        const state = {
                search: '',
                page: 1,
                pagesize: 10
        };

        const { register, formState: { errors }, handleSubmit, control, getValues, setValue } = useForm({
                defaultValues: { cart: [{ title: '', description: '', unitPrice: 0, quantity: 0, subTotal: 0 }] },
                resolver: yupResolver(schema),
        });


        const amountCalculation = (index, unitPrice, quantity) => {
                const subTotal = unitPrice * quantity || 0;

                setValue(`cart[${index}].subTotal`, subTotal);
                let newOverallTotal = 0;
                fields.forEach((field, i) => {
                        newOverallTotal += parseFloat(getValues(`cart[${i}].subTotal`)) || 0;
                });

                const taxAmount = (newOverallTotal * taxPercentage) / 100;
                setOverallTotal(newOverallTotal);
                setGrandTotal(newOverallTotal + taxAmount);
                setTotal(newOverallTotal + taxAmount);
        };

        const applyDiscount = (value) => {
                const discountValue = value !== null && value !== '' ? parseInt(value, 10) : 0;

                const discountedAmount = (total * discountValue) / 100;
                setGrandTotal(total - discountedAmount);
        };
        useEffect(() => {
                dispatch(getAllTaxTypes());
        }, [])

        useEffect(() => {
                dispatch(getAllCompanies(state.search, state.page, state.pagesize))
        }, [dispatch])

        useEffect(() => {
                dispatch(getAllTaxTypes())
        }, [dispatch]);

        useEffect(() => {
                if (selectedCompanyId) {
                        dispatch(getAllProjects(state.search, state.page, state.pagesize, true, selectedCompanyId));
                }
        }, [selectedCompanyId, dispatch, state.search, state.page, state.pagesize]);

        useEffect(() => {
                dispatch(getAllClientsforCompany(selectedCompanyId, state.search, state.page, state.pagesize));
        }, [state.search, state.page, state.pagesize, true, selectedCompanyId])


        const { fields, append, remove } = useFieldArray({ name: 'cart', control });

        const clientOptions = companyClients.map(client => ({
                value: client.clientIdentifier,
                label: client.clientName
        }));

        const companyOptions = companiesList.map(company => ({
                value: company.companyIdentifier,
                label: company.name
        }));

        const taxOptions = taxTypes.map(type => ({
                value: type.id,
                label: type.taxName
        }));

        const projectOptions = projects.map(project => ({
                value: project.projectIdentifier,
                label: project.projectName
        }));

        const onSubmit = (data) => {
                data.grandTotal = grandTotal;
                data.overallTotal = overallTotal;
                const formData = new FormData();
                formData.append("title", data.title);
                formData.append("clientId", data.clientId);
                formData.append("projectIdentifier", data.projectIdentifier);
                formData.append("email", data.email);
                formData.append("invoiceDate", data.invoiceDate);
                formData.append("dueDate", data.dueDate);
                formData.append("tax", data.tax);
                formData.append("billingAddress", data.billingAddress);
                formData.append("clientAddress", data.clientAddress);
                formData.append("cart", data.cart);
                formData.append("otherInfo", data.otherInfo);
                formData.append("discount", data.discount);
                formData.append("overAllTotal", data.overallTotal);
                formData.append("grandTotal", data.grandTotal);
                formData.append("companyIdentifier", data?.companyIdentifier);
                dispatch(addInvoice(data));
        };

        return (
                <>
                        <div className="content container-fluid">
                                {/* Page Header */}
                                <div className="page-header">
                                        <div className="row">
                                                <div className="col-sm-12">
                                                        <h3 className="page-title">Edit Invoice</h3>
                                                        <ul className="breadcrumb">
                                                                <li className="breadcrumb-item"><Link to="/app/main/dashboard">Dashboard</Link></li>
                                                                <li className="breadcrumb-item active">EDit Invoice</li>
                                                        </ul>
                                                </div>
                                        </div>
                                </div>
                                {/* /Page Header */}
                                <div className="row">
                                        <div className="col-sm-12">
                                                <form>
                                                        <div className="row">
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Title</label>
                                                                                <Controller
                                                                                        name="title"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        className={`form-control mb-0
                                                        `}
                                                                                                        type="text"
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                        defaultValue=""
                                                                                />
                                                                                <small>{errors?.title?.message}</small>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Company</label>
                                                                                <Controller
                                                                                        name="companyIdentifier"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={companyOptions}
                                                                                                        value={companyOptions.find(option => option.value === value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                        onChange={(selected) => {
                                                                                                                const selectedValue = selected ? selected.value : "";
                                                                                                                onChange(selectedValue); // Update react-hook-form state
                                                                                                                setSelectedCompanyId(selectedValue); // Update local state
                                                                                                        }}
                                                                                                />
                                                                                        )}
                                                                                        defaultValue=""
                                                                                />
                                                                                <small>{errors?.title?.message}</small>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Client <span className="text-danger">*</span></label>
                                                                                <Controller
                                                                                        name="clientId"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={clientOptions}
                                                                                                        value={clientOptions.find(option => option.value === value)}
                                                                                                        onChange={selected => onChange(selected?.value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                                <small>{errors?.clientId?.message}</small>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Project <span className="text-danger">*</span></label>
                                                                                <Controller
                                                                                        name="projectIdentifier"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={projectOptions}
                                                                                                        value={projectOptions.find(option => option.value === value)}
                                                                                                        onChange={selected => onChange(selected?.value)}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                />
                                                                                        )}
                                                                                />
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Email</label>
                                                                                <Controller
                                                                                        name="email"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <input
                                                                                                        className={`form-control mb-3 
                                                        `}
                                                                                                        type="email"
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                        defaultValue=""
                                                                                />
                                                                                <small>{errors?.email?.message}</small>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Tax</label>
                                                                                <Controller
                                                                                        name="tax"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <ReactSelect
                                                                                                        options={taxOptions}
                                                                                                        value={taxOptions.find(option => option.value === value)}
                                                                                                        onChange={selected => {
                                                                                                                const selectedTax = taxTypes.find(type => type?.id === selected?.value);
                                                                                                                setTaxPercentage(selectedTax?.taxRate || 0);
                                                                                                                onChange(selected?.value);
                                                                                                        }}
                                                                                                        isClearable
                                                                                                        isSearchable
                                                                                                        className="company-box"
                                                                                                />
                                                                                        )}
                                                                                        defaultValue=""
                                                                                />
                                                                                <small>{errors?.tax?.message}</small>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Client Address</label>
                                                                                <Controller
                                                                                        name="clientAddress"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <textarea
                                                                                                        className={`form-control mb-0 
                                                        `}
                                                                                                        type="text"
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                        defaultValue=""
                                                                                />
                                                                                <small>{errors?.clientAddress?.message}</small>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Billing Address</label>
                                                                                <Controller
                                                                                        name="billingAddress"
                                                                                        control={control}
                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                <textarea
                                                                                                        className={`form-control mb-0
                                                        `}
                                                                                                        type="text"
                                                                                                        value={value}
                                                                                                        onChange={onChange}
                                                                                                        autoComplete="false"
                                                                                                />
                                                                                        )}
                                                                                        defaultValue=""
                                                                                />
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Invoice date <span className="text-danger">*</span></label>
                                                                                <div>
                                                                                        <Controller
                                                                                                name="invoiceDate"
                                                                                                control={control}
                                                                                                render={({ field: { value, onChange } }) => (
                                                                                                        <input
                                                                                                                className={`form-control mb-0 
                                                            `}
                                                                                                                type="date"
                                                                                                                value={value}
                                                                                                                onChange={onChange}
                                                                                                                autoComplete="false"
                                                                                                        />
                                                                                                )}
                                                                                                defaultValue=""
                                                                                        />
                                                                                        <small>{errors?.invoiceDate?.message}</small>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group">
                                                                                <label>Due Date <span className="text-danger">*</span></label>
                                                                                <div>
                                                                                        <Controller
                                                                                                name="dueDate"
                                                                                                control={control}
                                                                                                render={({ field: { value, onChange } }) => (
                                                                                                        <input
                                                                                                                className={`form-control mb-0 
                                                            `}
                                                                                                                type="date"
                                                                                                                value={value}
                                                                                                                onChange={onChange}
                                                                                                                autoComplete="false"
                                                                                                        />
                                                                                                )}
                                                                                                defaultValue=""
                                                                                        />
                                                                                        <small>{errors?.dueDate?.message}</small>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="row">
                                                                <div className="col-md-12 col-sm-12">
                                                                        <div className="table-responsive">
                                                                                <table className="table table-hover table-white">
                                                                                        <thead>
                                                                                                <tr>
                                                                                                        <th style={{ width: '20px' }}>#</th>
                                                                                                        <th className="col-sm-2">Item</th>
                                                                                                        <th className="col-md-6">Description</th>
                                                                                                        <th style={{ width: '100px' }}>Unit Cost</th>
                                                                                                        <th style={{ width: '80px' }}>Qty</th>
                                                                                                        <th>Amount</th>
                                                                                                        <th> </th>
                                                                                                </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                                {fields.map((field, index) => {
                                                                                                        return (
                                                                                                                <tr key={field.id}>
                                                                                                                        <td>{index + 1}</td>
                                                                                                                        <td>
                                                                                                                                <input
                                                                                                                                        name={`title-${index}`}
                                                                                                                                        className="form-control"
                                                                                                                                        type="text"
                                                                                                                                        style={{ minWidth: '150px' }}
                                                                                                                                        {...register(`cart[${index}].title`)}
                                                                                                                                        required
                                                                                                                                />
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                                <input
                                                                                                                                        className="form-control"
                                                                                                                                        type="text"
                                                                                                                                        style={{ minWidth: '150px' }}
                                                                                                                                        {...register(`cart[${index}].description`)}
                                                                                                                                        required
                                                                                                                                />
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                                <input
                                                                                                                                        className="form-control"
                                                                                                                                        type="number"
                                                                                                                                        style={{ width: '100px' }}
                                                                                                                                        {...register(`cart[${index}].unitPrice`)}
                                                                                                                                        onChange={(e) => {
                                                                                                                                                const unitPrice = parseFloat(e.target.value);
                                                                                                                                                const quantity = parseFloat(getValues(`cart[${index}].quantity`));
                                                                                                                                                amountCalculation(index, unitPrice, quantity);
                                                                                                                                        }}
                                                                                                                                        required
                                                                                                                                />
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                                <input
                                                                                                                                        className="form-control"
                                                                                                                                        type="number"
                                                                                                                                        style={{ width: '80px' }}
                                                                                                                                        {...register(`cart[${index}].quantity`)}
                                                                                                                                        onChange={(e) => {
                                                                                                                                                const quantity = parseFloat(e.target.value);
                                                                                                                                                const unitPrice = parseFloat(getValues(`cart[${index}].unitPrice`));
                                                                                                                                                amountCalculation(index, unitPrice, quantity);
                                                                                                                                        }}
                                                                                                                                        required
                                                                                                                                />
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                                <input
                                                                                                                                        className="form-control"
                                                                                                                                        type="number"
                                                                                                                                        readOnly
                                                                                                                                        style={{ width: '120px' }}
                                                                                                                                        {...register(`cart[${index}].subTotal`)}
                                                                                                                                />
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                                <button
                                                                                                                                        type="button"
                                                                                                                                        className="btn btn-link text-danger font-18"
                                                                                                                                        title="Remove"
                                                                                                                                        onClick={() => remove(index)}
                                                                                                                                >
                                                                                                                                        <i className="fa fa-trash-o" />
                                                                                                                                </button>
                                                                                                                        </td>
                                                                                                                </tr>
                                                                                                        );
                                                                                                })}
                                                                                        </tbody>
                                                                                </table>
                                                                                <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-primary"
                                                                                        onClick={() => {
                                                                                                append({
                                                                                                        name: "",
                                                                                                        description: "",
                                                                                                        unitPrice: 0,
                                                                                                        quantity: 0,
                                                                                                        subTotal: 0
                                                                                                });
                                                                                        }}
                                                                                >
                                                                                        Add
                                                                                </button>
                                                                        </div>
                                                                        <div className="table-responsive">
                                                                                <table className="table table-hover table-white">
                                                                                        <tbody>
                                                                                                <tr>
                                                                                                        <td />
                                                                                                        <td />
                                                                                                        <td />
                                                                                                        <td />
                                                                                                        <td className="text-end">Total</td>
                                                                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{overallTotal.toFixed(2)}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                        <td colSpan={5} className="text-end">Tax</td>
                                                                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{taxPercentage.toFixed(2)} %</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                        <td colSpan={5} className="text-end">
                                                                                                                Discount %
                                                                                                        </td>
                                                                                                        <td style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>
                                                                                                                <Controller
                                                                                                                        name="discount"
                                                                                                                        control={control}
                                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                                <input
                                                                                                                                        className={`form-control text-end`}
                                                                                                                                        type="number"
                                                                                                                                        value={value}
                                                                                                                                        onChange={(e) => {
                                                                                                                                                onChange(e);
                                                                                                                                                applyDiscount(e.target.value);
                                                                                                                                        }}
                                                                                                                                        autoComplete="false"
                                                                                                                                />
                                                                                                                        )}
                                                                                                                        defaultValue=""
                                                                                                                />
                                                                                                        </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                        <td colSpan={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                                                                Grand Total
                                                                                                        </td>
                                                                                                        <td style={{ textAlign: 'right', paddingRight: '30px', fontWeight: 'bold', fontSize: '16px', width: '230px' }}>
                                                                                                                $ {grandTotal}
                                                                                                        </td>
                                                                                                </tr>
                                                                                        </tbody>
                                                                                </table>
                                                                        </div>
                                                                        <div className="row">
                                                                                <div className="col-md-12">
                                                                                        <div className="form-group">
                                                                                                <label>Other Information</label>
                                                                                                <Controller
                                                                                                        name="otherInfo"
                                                                                                        control={control}
                                                                                                        render={({ field: { value, onChange } }) => (
                                                                                                                <textarea
                                                                                                                        className={`form-control 
                                                                    `}
                                                                                                                        rows={4}
                                                                                                                        type="date"
                                                                                                                        value={value}
                                                                                                                        onChange={onChange}
                                                                                                                        autoComplete="false"
                                                                                                                />
                                                                                                        )}
                                                                                                        defaultValue=""
                                                                                                />
                                                                                                <small>{errors?.otherInfo?.message}</small>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="submit-section">
                                                                <button className="btn btn-primary submit-btn m-r-10">Save &amp; Send</button>
                                                                <button
                                                                        type="submit"
                                                                        className="btn btn-primary submit-btn m-r-10"
                                                                        onClick={handleSubmit(onSubmit)}>Save</button>
                                                        </div>
                                                </form>
                                        </div>
                                </div>
                        </div>
                </>
        )
}

export default EditInvoice;