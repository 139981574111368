import React from 'react'
import { integration_2_1, integration_2_2 } from '../../../assets/assets2/images'

function IntegrationArea() {
    return (
        <>
            <section
                id="integration"
                className="integration-area integration-3 pt-120 pb-90"
            >
                <div className="container-fluid p-0">
                    <div className="row align-items-center gx-xl-5">
                        <div className="col-lg-3 right-content">
                            <div className="section-title">
                                <h2 className="title">All kind of business tools integration.</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever
                                    since 1500s, when an unknown printer took a galley of type and
                                    scrambled.
                                </p>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-sm-6">
                                    <div className="item-single mb-30">
                                        <div className="icon">
                                            <i className="far fa-check"></i>
                                        </div>
                                        <div className="content">
                                            <p>
                                                There are many variations of passages of Lorem Ipsum, but
                                                the majority have suffered alteration in some form, by
                                                injected.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-6">
                                    <div className="item-single mb-30">
                                        <div className="icon">
                                            <i className="far fa-check"></i>
                                        </div>
                                        <div className="content">
                                            <p>
                                                There are many variations of passages of Lorem Ipsum, but
                                                the majority have suffered alteration in some form, by
                                                injected.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-6">
                                    <div className="item-single mb-30">
                                        <div className="icon">
                                            <i className="far fa-check"></i>
                                        </div>
                                        <div className="content">
                                            <p>
                                                There are many variations of passages of Lorem Ipsum, but
                                                the majority have suffered alteration in some form, by
                                                injected.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 left-content order-lg-first">
                            <div className="image mb-30">
                                <img
                                    src={integration_2_1}
                                    className="image-1"
                                    alt="Integration"
                                />
                                <img
                                    src={integration_2_2}
                                    className="image-2"
                                    alt="Integration"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntegrationArea