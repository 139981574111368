import { createSlice } from "@reduxjs/toolkit";
import httpHandler from '../utils/httpHandler';
import toast from "react-hot-toast";

const invoiceSlice = createSlice({
        name: 'invoice',
        initialState: {
                allInvoice: [],
                invoice: null,
                isLoading: false
        },
        addInvoiceSuccess: (state, action) => {
                state.isLoading = false;
        },
        addInvoiceFailure: (state, action) => {
                state.isLoading = false;
        },
        getAllInvoiceSucccess: (state, action) => {
                state.isLoading = false;
                state.allInvoice = action.payload
        },
        getAllInvoiceFailure: (state, action) => {
                state.isLoading = false;
                state.allInvoice = [];
        },
        setLoading: (state) => {
                state.setLoading = true;
        }
});

export const addInvoice = (data) => async (dispatch) => {
        try {
                const response = await httpHandler.post(`/api/Invoice`, data);
                if (response.data.success) {
                        toast.success(response.data.message);
                        dispatch(addInvoiceSuccess(response.data));
                }
                else {
                        toast.error(response.data.message);
                        dispatch(addInvoiceFailure());
                }
        }
        catch (error) {
                toast.error(error.message);
                dispatch(addInvoiceFailure());
        }
}

export const getAllInvoice = (search, page, pagesize) => async (dispatch) => {
        try {
                dispatch(setLoading());
                const response = await httpHandler.get(`/api/Invoice?search=${search}&page=${page}&pagesize=${pagesize}`);
                if (response.data.success) {
                        toast.success(response.data.message)
                        dispatch(getAllInvoiceSucccess(response));
                } else {
                        dispatch(getAllInvoiceFailure());
                }
        } catch (error) {
                dispatch(getAllInvoiceFailure());
                toast.error(error.message);
        }
}

export const { getAllInvoiceSucccess, getAllInvoiceFailure, addInvoiceSuccess, addInvoiceFailure, getAllTaxTypesSuccess, getAllTaxTypesFailure, setLoading } = invoiceSlice.actions
export default invoiceSlice.reducer