import React from 'react'

function FunfactArea() {
    return (
        <section id="funFact" className="fun-fact-area fun-fact-2">
            <div className="container-sm">
                <div className="row text-center justify-content-center">
                    <div className="col-sm-4">
                        <div className="item-single mb-30">
                            <h3><span className="counter">2</span>M+</h3>
                            <span>Total Downloads</span>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="item-single mb-30">
                            <h3><span className="counter">43</span>K+</h3>
                            <span>Positive Reviews</span>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="item-single mb-30">
                            <h3><span className="counter">13</span>K+</h3>
                            <span>Happy Users</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FunfactArea