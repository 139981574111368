import React, { useState } from 'react'
import NavMenu from '../NavBars/NavMenu';
import Sidebar from '../SideBar/Sidebar';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployee, updateEmployee } from '../../features/employeeSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import * as yup from "yup";
import { emailrgx } from '../../constant';
import Loader from '../loader/Loader';
import { useEffect } from 'react';
const schema = yup
    .object({
        name: yup.string().required("Name is required").trim(),
        email: yup.string().required("Email is required").matches(emailrgx, "Invalid Email").trim(),
        joiningDate: yup.date().required("Joining Date is required"),
        mobile: yup.string().required("Phone Number is required").max(12),
        designation: yup.string().required("Designation is required"),
        address: yup.string().required("Address is required"),
        country: yup.string().required("Country is required"),
    })
    .required();

function UpdateEmployeeForm() {
    const { employeeId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => setMenu(!menu);
    const { isLoading, employee } = useSelector(state => state.employee);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getEmployee(employeeId));
        };
        fetchData();
    }, [dispatch, employeeId]);

    const onSubmit = async (data) => {
        dispatch(updateEmployee(employeeId, data, navigate));
    };

    const formatDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };

    return (
        <>
            {isLoading ? <Loader /> :
                <>
                    <Helmet>
                        <title>Edit Employee</title>
                        <meta name="description" content="Login page" />
                    </Helmet>
                    <div className="account-content">
                        <div className="container">
                            <div>
                                <div className="account-wrapper">
                                    <h3 className="account-title">Edit Employee</h3>
                                    <div className="modal-body">
                                        {
                                            employee ? (

                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Name</label>
                                                                <Controller
                                                                    name="name"
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <input
                                                                            className={`form-control mb-0${errors?.name ? "error-input mb-0" : ""
                                                                                }`}
                                                                            type="text"
                                                                            value={value || ''}
                                                                            onChange={onChange}
                                                                            autoComplete="false"
                                                                        />
                                                                    )}
                                                                    defaultValue={employee?.name}
                                                                />
                                                                <small>{errors?.name?.message}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Designation</label>
                                                                <Controller
                                                                    name="designation"
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <select
                                                                            className={`form-control mb-0${errors?.designation ? "error-input mb-0" : ""
                                                                                } select`}
                                                                            value={value || ''}
                                                                            onChange={onChange}
                                                                        >
                                                                            <option value="">Select Designation</option>
                                                                            <option value="Web Designer">
                                                                                Web Designer
                                                                            </option>
                                                                            <option value="Web Developer">
                                                                                Web Developer
                                                                            </option>
                                                                            <option value="HR Management">
                                                                                HR Management
                                                                            </option>
                                                                            <option value="Digital Marketer">
                                                                                Digital Marketer
                                                                            </option>
                                                                        </select>
                                                                    )}
                                                                    defaultValue={employee?.designation}
                                                                />
                                                                <small>{errors?.designation?.message}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Email</label>
                                                                <Controller
                                                                    name="email"
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <input
                                                                            className={`form-control mb-0${errors?.email ? "error-input mb-0" : ""
                                                                                }`}
                                                                            type="text"
                                                                            value={value || ""}
                                                                            onChange={onChange}
                                                                            autoComplete="false"
                                                                            readOnly
                                                                        />
                                                                    )}
                                                                    defaultValue={employee?.email}
                                                                />
                                                                <small>{errors?.email?.message}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Joining Date</label>
                                                                <Controller
                                                                    name="joiningDate"
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <input
                                                                            className={`form-control mb-0${errors?.joiningDate ? "error-input mb-0" : ""
                                                                                }`}
                                                                            type="date"
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            autoComplete="false"
                                                                            readOnly
                                                                        />
                                                                    )}
                                                                    defaultValue={employee?.joiningDate ? formatDate(employee?.joiningDate) : ''}
                                                                />
                                                                <small>{errors?.joiningDate?.message}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Phone Number</label>
                                                                <Controller
                                                                    name="mobile"
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <input
                                                                            className={`form-control mb-0${errors?.mobile ? "error-input mb-0" : ""
                                                                                }`}
                                                                            type="text"
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            autoComplete="false"
                                                                        />
                                                                    )}
                                                                    defaultValue={employee?.mobile}
                                                                />
                                                                <small>{errors?.mobile?.message}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Address</label>
                                                                <Controller
                                                                    name="address"
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <input
                                                                            className={`form-control mb-0${errors?.address ? "error-input mb-0" : ""
                                                                                }`}
                                                                            type="text"
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            autoComplete="false"
                                                                        />
                                                                    )}
                                                                    defaultValue={employee?.address}
                                                                />
                                                                <small>{errors?.address?.message}</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Country</label>
                                                                <Controller
                                                                    name="country"
                                                                    control={control}
                                                                    render={({ field: { value, onChange } }) => (
                                                                        <input
                                                                            className={`form-control mb-0${errors?.country ? "error-input mb-0" : ""
                                                                                }`}
                                                                            type="text"
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            autoComplete="false"
                                                                        />
                                                                    )}
                                                                    defaultValue={employee?.country}
                                                                />
                                                                <small>{errors?.country?.message}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group text-center">
                                                        <Link
                                                            to={`/company/company-employees/${employee.companyIdentifier}`}
                                                            className="btn btn-info text-white mr-3 back-list-btn"
                                                            type="button"
                                                        >
                                                            Back To List
                                                        </Link>
                                                        <button
                                                            className="btn btn-primary account-btn"
                                                            type="submit"
                                                        >
                                                            Update
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (
                                                <p>No employee data found.</p>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default UpdateEmployeeForm;