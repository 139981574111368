import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import { deleteEstimate, getAllEstimates } from '../../../features/estimateSlice';
import Loader from '../../../components/loader/Loader';

function Estimates() {
    const dispatch = useDispatch();
    const { allEstimates, isLoading } = useSelector(state => state.estimates);
    const [state, setState] = useState({
        search: '',
        page: 1,
        pagesize: 10,
        fromDate: '',
        toDate: '',
        status: '',
    });

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    useEffect(() => {
        dispatch(getAllEstimates(state.search, state.page, state.pagesize, state.fromDate, state.toDate, state.status, '2be1932c-2345-43'));
    }, [dispatch, state.search, state.page, state.pagesize]);

    // Handle delete confirmation
    const handleDeleteClick = id => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id),
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    // Handle actual delete action
    const handleDelete = id => {
        dispatch(deleteEstimate(id));
    };

    // Handle filter input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    // Filter estimate
    const filterEstimate = () => {
        dispatch(getAllEstimates(state.search, state.page, state.pagesize, state.fromDate, state.toDate, state.status, '2be1932c-2345-43'));
    };


    // Handle pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    // Custom pagination item renderer
    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };

    // Table columns
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            render: (text, record) => <Link to="/admin/estimate-veiw">{text}</Link>,
        },
        {
            title: 'Estimate Date',
            dataIndex: 'createDate',
            render: (text) => formatDate(text),
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expirationDate',
            render: (text) => formatDate(text),

        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (text, record) => <span>$ {text}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
                <span className={text === 'Accepted' ? 'badge bg-inverse-success' : 'badge bg-inverse-info'}>
                    {text}
                </span>
            ),
        },
        {
            title: 'Action',
            render: (text, record) => (
                <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
                    <Link className="dropdown-item px-2 text-success" to={`/admin/edit-estimates/${record.estimateIdentifier}`}>
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => handleDeleteClick(record.estimateIdentifier)}>
                        <i className="fa fa-trash-o m-r-5" />
                    </Link>
                </div>
            ),
        },
    ];

    // Initialize select2 on mount
    useEffect(() => {
        if ($('.select').length > 0) {
            $('.select').select2({
                minimumResultsForSearch: -1,
                width: '100%',
            });
        }
    }, []);

    // JSX rendering
    return (
        <>
            <Helmet>
                <title>Estimates</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Estimates</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/app/main/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Estimates</li>
                            </ul>
                        </div>
                        <div className="col-auto float-end ml-auto">
                            <Link to="/admin/create-estimates" className="btn add-btn"><i className="fa fa-plus" /> Create Estimate</Link>
                        </div>
                    </div>
                </div>
                <div className="row filter-row">
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus">
                            <div>
                                <input className="form-control floating datetimepicker" type="date" name="fromDate" value={state.fromDate} onChange={handleInputChange} />
                            </div>
                            <label className="focus-label">From</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus">
                            <div>
                                <input className="form-control floating datetimepicker" name="toDate" type="date" value={state.toDate} onChange={handleInputChange} />
                            </div>
                            <label className="focus-label">To</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="form-group form-focus select-focus" name="status" value={state.status} onChange={handleInputChange}>
                            <select className="select floating">
                                <option>Select Status</option>
                                <option>Accepted</option>
                                <option>Declined</option>
                                <option>Expired</option>
                            </select>
                            <label className="focus-label">Status</label>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <Link to="#" className="btn btn-success btn-block w-100" onClick={filterEstimate}> Search </Link>
                    </div>
                </div>
                {isLoading ? <Loader /> :
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <Table
                                    className="table-striped"
                                    pagination={{
                                        current: state.page,
                                        pageSize: state.pagesize,
                                        showTotal: (total, range) =>
                                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                        showSizeChanger: true,
                                        onShowSizeChange: onShowSizeChange,
                                        itemRender: itemRender,
                                        onChange: (page, pageSize) =>
                                            setState({ ...state, page, pagesize: pageSize }),
                                        total: allEstimates.totalRecords,
                                    }}
                                    style={{ overflowX: 'auto' }}
                                    columns={columns}
                                    dataSource={allEstimates?.data}
                                    rowKey={record => record.estimateIdentifier}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal custom-modal fade" id="delete_estimate" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header">
                                <h3>Delete Estimate</h3>
                                <p>Are you sure want to delete?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="col-6">
                                        <Link to="#" className="btn btn-primary continue-btn">Delete</Link>
                                    </div>
                                    <div className="col-6">
                                        <Link to="#" data-bs-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Estimates